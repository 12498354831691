import httpService from './http.service';

const USER_BOARDS_URL = '/user/boards';

function getUserBoard() {
  return httpService.get(`${USER_BOARDS_URL}/current`);
}

function getSpecialPosition() {
  return httpService.get(`${USER_BOARDS_URL}/current/position`);
}

export default {
  getUserBoard,
  getSpecialPosition
};
