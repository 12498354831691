import languages from '../enums/languages';

const BG_LANG = 'bg-BG';

function getNavigatorLang() {
  if (!window.navigator) {
    return;
  }

  return window.navigator.language || window.navigator.userLanguage;
}

function getLanguage() {
  let result = localStorage.getItem('lang');

  if (!result) {
    const browserLang = getNavigatorLang();

    if (browserLang) {
      result = browserLang === BG_LANG ? languages.bg : languages.en;
    }
  }

  return result || languages.en;
}

function isLanguageSaved() {
  return !!localStorage.getItem('lang');
}

function saveLanguage(lang) {
  localStorage.setItem('lang', lang || languages.en);
}

export default {
  getLanguage,
  isLanguageSaved,
  saveLanguage
};
