<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ baseNotification.title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="close" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-5">
      <div class="text-center">
        <user-avatar
          :user="baseNotification"
          :size="80"
          textSizeClass="subheading"
        ></user-avatar>
      </div>
      <div class="text-center mt-3">
        <p class="subtitle" v-html="baseNotification.text"></p>
      </div>
      <slot name="modal-text"></slot>
    </v-card-text>
    <v-card-actions>
      <slot name="modal-actions"></slot>
    </v-card-actions>
  </v-card>
</template>

<script>
import UserAvatar from '../../shared/UserAvatar';
import { MARK_AS_SEEN } from '../../../store/notifications/types';

export default {
  name: 'base-notification-modal',
  components: {
    UserAvatar
  },
  props: {
    baseNotification: {
      type: Object,
      required: true
    },
    scope: {
      type: Object
    },
    markAsSeen: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    close() {
      if (this.markAsSeen && !this.baseNotification.seen) {
        this.$store.dispatch(MARK_AS_SEEN, this.baseNotification.id);
      }

      if (this.scope) {
        return this.scope.$emit('close');
      }

      this.$emit('close');
    }
  }
};
</script>
