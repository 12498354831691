<template>
  <div class="moto mt-6 mb-12">
    <v-container class="pa-6">
      <v-row align="center" justify="center" class="mb-1">
        <v-col sm12>
          <h1 class="text-h3 font-weight-bold text-center pb-2 gam-name">
            Gamifinnopoly
          </h1>
          <h1
            class="grey--text text--darken-1 text-h5 font-weight-bold text-center"
          >
            {{ $t('about.moto') }}
          </h1>
          <h3 class="font-weight-bold mt-3 text-center">
            {{ $t('about.poweredByAI') }}
          </h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            color="app-blue"
            dark
            class="subtitle get-started-btn"
            to="/sign-up"
          >
            {{ $t('common.getStarted') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12" class="justify-center d-flex pl-6">
          <a
            target="_blank"
            :href="googlePlayUrl"
            alt="Gamifinnopoly on Google Play"
          >
            <v-img
              max-height="100"
              max-width="210"
              :src="images.googlePlayBadge"
            ></v-img
          ></a>
        </v-col>
      </v-row>
      <v-row class="mb-2">
        <v-col cols="12" class="justify-center d-flex pl-6">
          <v-img
            contain
            max-height="150"
            max-width="150"
            :src="images.innovationExcellence2023"
          ></v-img>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm12>
          <v-img
            max-height="500"
            contain
            class="border-radius-10"
            :src="images.gamifinnatorGamifinna"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'moto-small-size',
  data() {
    return {
      images: {
        gamifinnatorGamifinna: require('../../assets/images/gamifinnator-gamifinna.png'),
        googlePlayBadge: require('../../assets/images/app-badges/google-play-badge.png'),
        innovationExcellence2023: require('../../assets/images/company-awards/Innovation-Excellence-2023-EN.png')
      },
      googlePlayUrl: process.env.VUE_APP_GOOGLE_PLAY_APP_URL
    };
  }
};
</script>

<style lang="scss" scoped>
.v-application .text-h3.gam-name {
  font-size: 2.8rem !important;
}
</style>
