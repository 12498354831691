<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <circle cx="256" cy="256" r="256" fill="#ffcd52" />
    <circle cx="256" cy="256" r="194.664" fill="#fff" opacity=".3" />
    <path
      fill="#fff"
      d="M222.968 123.696h66.064v264.64h-66.064z"
      opacity=".7"
    />
  </svg>
</template>

<script>
export default {
  name: 'gold-coin-icon'
};
</script>
