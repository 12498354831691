export default {
  auth: {
    signUp: 'Регистрация',
    signUpForFree: 'Регистрирай се безплатно',
    successfulRegistrationTitle: 'Успешна регистрация!',
    successfulRegistrationText:
      'Изпратихме Ви имейл на адрес {email}. Моля, отворете линкът в имейла, за да активирате вашият потребител.',
    resendEmailForActivationText: `Ако не сте получили имейл за активиране, моля натиснета бутонът отдолу и ние ще Ви изпратим нов имейл за активиране на Вашия потребител.`,
    resendEmailForActivationBtn: 'Изпрати ми нов имейл',
    newActivationEmailSent: 'Изпратихме Ви нов имейл за активиране!',
    accountSuccessfullyActivated: 'Успешно активирахте вашият потребител!',
    createAccount: 'Създай профил',
    login: 'Вход',
    signOut: 'Изход',
    google: 'Google',
    login_google: 'Вход с Google',
    signup_google: 'Регистрация с Google',
    dontHaveAnAccount: 'Нямате профил?',
    youHaveAnAccount: 'Имате вече профил?',
    gamLogin: 'Вход с имейл',
    gamRegister: 'Регистрация с имейл',
    forgotPassword: 'Забравена парола',
    passwordRecovery: 'Възстановяване на паролата',
    passwordRecoveryText:
      'Забрави ли сте паролата си и имате проблем с вписването в Gamifinnopoly? Въведете вашият имейл и ние ще ви изпратим линк за възстановяване на паролата.',
    recoveryEmailSent:
      'Изпратихме ви линка за възстановяване на паролата на посочения адрес.',
    sendRecoveryEmail: 'Изпрати ми имейл за възстановяване',
    expiredRecoveryLink: 'Линкът за възстановяване на паролата е изтекъл!',
    requestNewRecoveryLink: 'Заяви нов',
    resetPassword: 'Промени паролата',
    passwordResetSuccessfully: 'Паролата е променена успешно!',
    completeGroupRegisterText: `За да се присъедините в групата трябва да създадете свой Gamifinnopoly потребител. 
      Моля, попълнете следните полета и започнете да се забавлявате и постигате повече като един истински Гамифинатор!`
  }
};
