export default {
  notifications: {
    notifications: 'Notifications',
    markAsSeen: 'Mark as seen',
    noNotifications: 'No unread notifications!',
    seeOldNotifications: 'See old notifications',
    notificationsLast30days: 'Notifications for last 30 days',
    noNotificationsFound: 'No notifications found',
    notificationDeletedSuccessfully: 'Notification deleted successfully!',
    deleteNotificationConfirm:
      'Are you sure you want to delete this notification?',
    groupInvitation: {
      title: 'Group invitation',
      text: '<strong>{senderName}</strong> ask you to join <strong>{itemName}</strong>'
    },
    acceptedGroupInvitaion: {
      title: 'Accepted invitation',
      text: '<strong>{senderName}</strong> accepted your group invitation'
    },
    newTaskAssigned: {
      title: 'New task assigned',
      text: '<strong>{senderName}</strong> assigned you new task <strong>{itemName}</strong>'
    },
    askTaskApprove: {
      title: 'Ask for task approval',
      text: '<strong>{senderName}</strong> ask you to approve <strong>{itemName}</strong>'
    },
    taskApprove: {
      approved: {
        title: 'Task approved',
        text: '<strong>{senderName}</strong> approved <strong>{taskTitle}</strong>'
      },
      disApproved: {
        title: 'Task disapproved',
        text: '<strong>{senderName}</strong> refused to approve <strong>{taskTitle}</strong>'
      }
    },
    newRewardAdded: {
      title: 'New reward added',
      text: '<strong>{senderName}</strong> added you new reward <strong>{itemName}</strong>'
    },
    newMissionAssigned: {
      title: 'New mission assigned',
      text: '<strong>{senderName}</strong> assigned you new mission <strong>{itemName}</strong>'
    },
    askMissionApprove: {
      title: 'Ask for mission approval',
      text: '<strong>{senderName}</strong> ask you to approve <strong>{itemName}</strong> mission'
    },
    missionApprove: {
      approved: {
        title: 'Mission approved',
        text: '<strong>{senderName}</strong> approved <strong>{missionName}</strong>'
      },
      disApproved: {
        title: 'Mission disapproved',
        text: '<strong>{senderName}</strong> refused to approve <strong>{missionName}</strong>'
      },
      disapprovedExpiredMission: {
        text: '<strong>{senderName}</strong> refused to approve <strong>{missionName}</strong>. As this mission has already expired it will be marked as failed! Because of that you lost <strong>{ruby} ruby</strong>!'
      }
    },
    removeMember: {
      title: 'Removed from group',
      text: '<strong>{senderName}</strong> removed you from <strong>{itemName}</strong> group'
    },
    promoteToLeader: {
      title: 'Promote to {role}',
      text: '<strong>{senderName}</strong> promote you to <strong>{role}</strong> in <strong>{itemName}</strong> group'
    },
    lowerToMember: {
      title: 'Lower to {role}',
      text: '<strong>{senderName}</strong> lower you to <strong>{role}</strong> in <strong>{itemName}</strong> group'
    },
    requestRewardApproval: {
      title: 'Request for reward approval',
      text: '<strong>{senderName}</strong> request you to approve <strong>{itemName}</strong> reward'
    },
    rewardApprove: {
      approved: {
        title: 'Reward approved',
        text: '<strong>{senderName}</strong> approved <strong>{rewardName}</strong>. You were redeemed with <strong>{ruby}</strong> ruby!'
      },
      disApproved: {
        title: 'Reward disapproved',
        text: '<strong>{senderName}</strong> refused to approve <strong>{rewardName}</strong>'
      }
    },
    sendGems: {
      title: 'Gems received',
      text: '<strong>{senderName}</strong> sent you <strong>{gems}</strong> Gems'
    }
  }
};
