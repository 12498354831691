import { GET_TAGS, SET_TAGS } from './types';
import tagsService from '../../services/tags.service';

export default {
  async [GET_TAGS]({ commit }) {
    const { result } = await tagsService.index();

    commit(SET_TAGS, result);
  }
};
