import httpService from '../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, missionId, taskId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/missions/${missionId}/tasks`;

  if (taskId) {
    url = `${url}/${taskId}`;
  }

  return url;
}

function getCurrentUserUrl(groupId, missionId, taskId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/missions/${missionId}/tasks`;

  if (taskId) {
    url = `${url}/${taskId}`;
  }

  return url;
}

function create(groupId, missionId, payload) {
  return httpService.post(getUrl(groupId, missionId), payload);
}

function update(groupId, missionId, taskId, payload) {
  return httpService.put(getUrl(groupId, missionId, taskId), payload);
}

function complete(groupId, missionId, taskId) {
  return httpService.post(
    `${getCurrentUserUrl(groupId, missionId, taskId)}/complete`
  );
}

function reopen(groupId, missionId, taskId) {
  return httpService.post(
    `${getCurrentUserUrl(groupId, missionId, taskId)}/reopen`
  );
}

function archive(groupId, userId, missionId, taskId, payload) {
  return httpService.patch(
    `${getUrl(groupId, userId, missionId, taskId)}/archive`,
    payload
  );
}

function copy(groupId, userId, missionId, taskId, payload) {
  return httpService.patch(
    `${getUrl(groupId, userId, missionId, taskId)}/copy`,
    payload
  );
}

function del(groupId, missionId, taskId) {
  return httpService.del(getUrl(groupId, missionId, taskId));
}

export default {
  create,
  update,
  complete,
  reopen,
  archive,
  copy,
  del
};
