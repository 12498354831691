<template>
  <v-card class="text-center avatar-item" tile>
    <v-avatar size="125" class="my-4">
      <v-img contain :src="avatarImg"></v-img>
    </v-avatar>
    <v-card-actions class="mt-2 justify-center">
      <v-btn
        :disabled="avatar.isCurrent"
        text
        dark
        block
        color="app-blue"
        @click="changeProfileAvatar()"
      >
        <span class="grey--text text--lighten-1" v-if="avatar.isCurrent">
          {{ $t('common.current') }}
        </span>
        <span v-else>{{ $t('avatars.useAvatar') }}</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    avatar: {
      type: Object,
      require: true
    }
  },
  computed: {
    avatarImg() {
      return require(`@/assets/images/avatars/${this.avatar.imagePath}`);
    }
  },
  methods: {
    async changeProfileAvatar() {
      this.$emit('changeProfileAvatar', this.avatar);
    }
  }
};
</script>
