import {
  GET_MISSION_ARCHIVED_TASKS,
  SET_MISSION_ARCHIVED_TASKS,
  ADD_MISSION_TASK,
  GENERATE_MISSION_TASKS,
  ADD_MISSION_MULTIPLE_TASKS,
  UPDATE_MISSION_TASK,
  COMPLETE_MISSION_TASK,
  REOPEN_MISSION_TASK,
  ARCHIVE_MISSION_TASK,
  COPY_MISSION_TASK,
  UPDATE_MISSION_TASKS_TYPES_FILTER,
  RESET_MISSION_TASKS_TYPES_FILTER,
  DELETE_MISSION_TASK
} from '../types';
import { UPDATE_PROFILE } from '../../../users/types';
import missionsTasksHttpService from '../../../../services/missions.tasks.http.service';
import tasksMapper from '../../../../mappers/tasks.mapper';
import tasksHelper from '../../../../common/tasks.helper';

function getSelectedFilterTypes(filter) {
  return filter.filter(f => f.selected).map(f => f.type);
}

function isFilterTypeNotSelected(selectedFilters, type) {
  return selectedFilters.length > 0 && selectedFilters.indexOf(type) === -1;
}

export default {
  async [GET_MISSION_ARCHIVED_TASKS]({ commit }, missionId) {
    const { result } = await missionsTasksHttpService.index(missionId, {
      archived: true
    });

    commit(SET_MISSION_ARCHIVED_TASKS, result);
  },
  async [ADD_MISSION_TASK]({ commit, state, rootState }, payload) {
    const { missionId, ...task } = payload;
    const { result } = await missionsTasksHttpService.create(
      missionId,
      tasksMapper.transformToMissionTaskReqBody(task)
    );

    task.tags = tasksHelper.mapTaskTags(rootState.tags.tags, task.tags);

    commit(ADD_MISSION_TASK, result);

    const selectedFilterTypes = getSelectedFilterTypes(
      state.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, result.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, result.type);
    }
  },
  async [GENERATE_MISSION_TASKS]({ commit }, payload) {
    const { missionId, ...data } = payload;
    const { result } = await missionsTasksHttpService.generateTasks(
      missionId,
      data
    );

    commit(UPDATE_PROFILE, { stats: result.stats });

    return tasksMapper.transformToGeneratedMissionTasks(result.tasks);
  },
  async [ADD_MISSION_MULTIPLE_TASKS]({ commit }, payload) {
    const { missionId, tasks } = payload;
    const { result } = await missionsTasksHttpService.createMultiple(
      missionId,
      { tasks: tasksMapper.transformToMultipeMissionTaskReqBody(tasks) }
    );

    commit(ADD_MISSION_MULTIPLE_TASKS, result);
  },
  async [UPDATE_MISSION_TASK]({ commit, state, rootState }, payload) {
    const { missionId, id, ...task } = payload;

    await missionsTasksHttpService.update(
      missionId,
      id,
      tasksMapper.transformToMissionTaskReqBody(task)
    );

    task.tags = tasksHelper.mapTaskTags(rootState.tags.tags, task.tags);

    const selectedFilterTypes = getSelectedFilterTypes(
      state.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, task.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, task.type);
    }

    commit(UPDATE_MISSION_TASK, { id, ...task });
  },
  async [COMPLETE_MISSION_TASK]({ commit }, payload) {
    const { missionId, task } = payload;

    const { result } = await missionsTasksHttpService.completeTask(
      missionId,
      task.id
    );

    commit(UPDATE_MISSION_TASK, {
      id: task.id,
      completed: true
    });
    commit(UPDATE_PROFILE, { stats: result.stats, board: result.board });

    return result;
  },
  async [REOPEN_MISSION_TASK]({ commit }, payload) {
    const { missionId, taskId } = payload;

    const { result } = await missionsTasksHttpService.reopenTask(
      missionId,
      taskId
    );
    commit(UPDATE_MISSION_TASK, { id: taskId, completed: false });
    commit(UPDATE_PROFILE, { stats: result.stats });

    return result;
  },
  async [ARCHIVE_MISSION_TASK]({ commit }, payload) {
    const { missionId, taskId } = payload;

    await missionsTasksHttpService.archiveTask(missionId, taskId);
    commit(UPDATE_MISSION_TASK, { id: taskId, archived: true });
  },
  async [COPY_MISSION_TASK]({ commit, state }, payload) {
    const { missionId, taskId } = payload;

    const { result } = await missionsTasksHttpService.copyTask(
      missionId,
      taskId
    );

    commit(ADD_MISSION_TASK, result);

    const selectedFilterTypes = getSelectedFilterTypes(
      state.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, result.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, result.type);
    }

    return result;
  },
  [UPDATE_MISSION_TASKS_TYPES_FILTER]({ commit }, filterType) {
    commit(UPDATE_MISSION_TASKS_TYPES_FILTER, filterType);
  },
  [RESET_MISSION_TASKS_TYPES_FILTER]({ commit }) {
    commit(RESET_MISSION_TASKS_TYPES_FILTER);
  },
  async [DELETE_MISSION_TASK]({ commit }, payload) {
    await missionsTasksHttpService.del(payload.missionId, payload.taskId);

    commit(DELETE_MISSION_TASK, payload.taskId);
  }
};
