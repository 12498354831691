export default {
  boards: {
    boards: 'Бордове',
    areYouSureToBuyThisBoard: 'Сигурни ли сте, че искате да купите този борд?',
    notEnoughGemsToBuyBoard: `Нямате достатъчно Gems, за да купите този борд!`,
    boardBoughtSuccessfully: 'Успешно закупихте този борд!',
    noBoardsFound: 'Няма намерени бордове!',
    doYouWantToChangeYourCurrentBoardWithNewOne:
      'Искате ли да промените своя текущ борд с новия, който закупихте?',
    myBoards: 'Моите бордове',
    buyMoreBoards: 'Купи още бордове',
    successBoardChange: 'Успешно променихте борда си!',
    useBoard: 'Използвай'
  }
};
