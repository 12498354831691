import {
  GET_MISSIONS,
  SET_MISSIONS,
  GET_CURRENT_MISSION,
  SET_CURRENT_MISSION,
  UPDATE_CURRENT_MISSION,
  ADD_MISSION,
  ADD_MISSION_DASHBOARD,
  COPY_MISSION,
  UPDATE_MISSION,
  COMPLETE_MISSION,
  SET_MISSION_AS_MAIN,
  DELETE_MISSION
} from '../types';
import { UPDATE_PROFILE } from '../../users/types';
import tasksActions from '../tasks/actions';
import missionsHttpService from '../../../services/missions.http.service';
import missionStatuses from '../../../enums/mission.statuses';
import dateTimeManager from '../../../common/date.time.manager';

export default {
  async [GET_MISSIONS]({ commit }, params) {
    const { result } = await missionsHttpService.index(params);

    commit(SET_MISSIONS, result);
  },
  async [GET_CURRENT_MISSION]({ commit }, id) {
    const { result } = await missionsHttpService.get(id);

    commit(SET_CURRENT_MISSION, result);
  },
  [SET_CURRENT_MISSION]({ commit }, mission) {
    commit(SET_CURRENT_MISSION, mission);
  },
  async [ADD_MISSION]({ commit }, payload) {
    const { result } = await missionsHttpService.create(payload);

    commit(ADD_MISSION, result);
  },
  async [ADD_MISSION_DASHBOARD]({ commit }, payload) {
    const { result } = await missionsHttpService.create(payload);

    if (result.status === missionStatuses.active) {
      commit(ADD_MISSION, result);
    }

    return result;
  },
  async [COPY_MISSION]({ commit }, payload) {
    const { id, ...mission } = payload;
    const { result } = await missionsHttpService.copy(id, mission);

    commit(ADD_MISSION, result);
  },
  async [UPDATE_MISSION]({ commit }, payload) {
    const { id, ...mission } = payload;

    await missionsHttpService.update(id, mission);

    if (dateTimeManager.isSame(new Date(mission.from), new Date())) {
      payload.status = missionStatuses.active;
    }

    commit(UPDATE_MISSION, payload);
  },
  async [UPDATE_CURRENT_MISSION]({ commit }, payload) {
    const { id, ...mission } = payload;

    await missionsHttpService.update(id, mission);

    commit(UPDATE_CURRENT_MISSION, payload);
  },
  async [SET_MISSION_AS_MAIN]({ commit }, payload) {
    const { mission, sendRequest } = payload;

    if (sendRequest) {
      const { result } = await missionsHttpService.setAsMain(mission.id);
      commit(SET_MISSION_AS_MAIN, result);
    } else {
      commit(SET_MISSION_AS_MAIN, mission);
    }
  },
  async [COMPLETE_MISSION]({ commit }, missionId) {
    const { result } = await missionsHttpService.complete(missionId);

    commit(UPDATE_CURRENT_MISSION, { status: missionStatuses.completed });
    commit(UPDATE_PROFILE, { stats: result.stats, board: result.board });

    return result;
  },
  async [DELETE_MISSION]({ commit }, payload) {
    await missionsHttpService.del(payload);

    commit(DELETE_MISSION, payload);
  },
  ...tasksActions
};
