import httpService from '../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, missionId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/missions`;

  if (missionId) {
    url = `${url}/${missionId}`;
  }

  return url;
}

function index(groupId, params = {}) {
  return httpService.get(getUrl(groupId), params);
}

function get(groupId, memberId) {
  return httpService.get(getUrl(groupId, memberId));
}

function getApprovalMissions(groupId, groupMissionId) {
  return httpService.get(`${getUrl(groupId)}/${groupMissionId}/approvals`);
}

function create(groupId, payload) {
  return httpService.post(getUrl(groupId), payload);
}

function update(groupId, missionId, payload) {
  return httpService.put(getUrl(groupId, missionId), payload);
}
function archive(groupId, missionId) {
  return httpService.patch(`${getUrl(groupId, missionId)}/archive`);
}

function copy(groupId, missionId, payload) {
  return httpService.patch(`${getUrl(groupId, missionId)}/copy`, payload);
}

function approveAllMissions(groupId, groupMissionId, payload) {
  return httpService.post(
    `${getUrl(groupId)}/${groupMissionId}/approve`,
    payload
  );
}

function del(groupId, missionId) {
  return httpService.del(getUrl(groupId, missionId));
}

export default {
  index,
  get,
  getApprovalMissions,
  create,
  update,
  archive,
  copy,
  approveAllMissions,
  del
};
