import {
  GET_MEMBERS,
  SET_MEMBERS,
  ACCEPT_GROUP_INVITATION,
  ACCEPT_GROUP_EMAIL_INVITATION,
  PROMOTE_TO_LEADER,
  LOWER_TO_MEMBER,
  UPDATE_GROUP_MEMBER,
  UPDATE_CURRENT_GROUP_MEMBER_STATS,
  SEND_GEMS,
  DELETE_GROUP_MEMBER
} from './types';
import { DELETE_NOTIFICATION } from '../notifications/types';
import { UPDATE_USER_STATS } from '../users/types';
import groupMembersHttpService from '../../services/group.members.http.service';
import groupRoles from '../../enums/group.roles';

export default {
  async [GET_MEMBERS]({ commit }, { id, params }) {
    const { result } = await groupMembersHttpService.index(id, params);

    commit(SET_MEMBERS, result);
  },
  async [ACCEPT_GROUP_INVITATION]({ commit }, id) {
    await groupMembersHttpService.acceptInvitation(id);

    commit(DELETE_NOTIFICATION, id);
  },
  async [ACCEPT_GROUP_EMAIL_INVITATION](actions, payload) {
    await groupMembersHttpService.acceptGroupEmailInvitation(payload);
  },
  [UPDATE_CURRENT_GROUP_MEMBER_STATS]({ commit }, payload) {
    commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, payload);
  },
  async [PROMOTE_TO_LEADER]({ commit, rootState }, payload) {
    const { groupId, memberId } = payload;

    await groupMembersHttpService.promoteToLeader(groupId, memberId);

    commit(UPDATE_GROUP_MEMBER, {
      memberId,
      groupRole: groupRoles.leader,
      displayRole:
        rootState.groups.currentGroup.leaderRoleDisplayName || groupRoles.leader
    });
  },
  async [LOWER_TO_MEMBER]({ commit, rootState }, payload) {
    const { groupId, memberId } = payload;

    await groupMembersHttpService.lowerToMember(groupId, memberId);

    commit(UPDATE_GROUP_MEMBER, {
      memberId,
      groupRole: groupRoles.member,
      displayRole:
        rootState.groups.currentGroup.memberRoleDisplayName || groupRoles.member
    });
  },
  async [SEND_GEMS]({ commit, rootState }, payload) {
    const { groupId, userId, gems } = payload;

    await groupMembersHttpService.sendGems(groupId, userId, { gems });

    commit(UPDATE_USER_STATS, {
      gems: rootState.users.currentUser.stats.gems - gems
    });
  },
  async [DELETE_GROUP_MEMBER]({ commit }, payload) {
    const { groupId, userId } = payload;

    await groupMembersHttpService.del(groupId, userId);

    commit(DELETE_GROUP_MEMBER, userId);
  }
};
