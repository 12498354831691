<template>
  <div>
    <v-avatar :size="size" v-if="user.imageUrl" class="bordered">
      <v-img :src="imageUrl" contain></v-img>
    </v-avatar>
    <v-avatar :size="size" v-else :color="color">
      <span class="white--text" v-bind:class="textSizeClass">
        {{ initials }}
      </span>
    </v-avatar>
  </div>
</template>

<script>
import avatarGenerator from '../../common/avatar.generator';

export default {
  name: 'user-avatar',
  props: {
    user: {
      type: Object,
      required: true,
      default: () => {}
    },
    size: {
      type: Number,
      default: 30
    },
    textSizeClass: {
      type: String,
      default: 'caption'
    }
  },
  computed: {
    imageUrl() {
      // TODO: Remove this if when we add S3
      if (
        this.user.generatedAvatarUrl &&
        process.env.VUE_APP_IS_AVATAR_GENERATION_ENABLED
      ) {
        return this.user.generatedAvatarUrl;
      }

      return require(`../../assets/images/avatars/${this.user.imageUrl}`);
    },
    initials() {
      return this.user.name ? avatarGenerator.getInitials(this.user.name) : '';
    },
    color() {
      return this.user.name
        ? avatarGenerator.getRandomColorByName(this.user.name)
        : '';
    }
  }
};
</script>
