function transformUpdateUserReqBody(user) {
  return {
    name: user.name,
    avatar: user.avatar ? user.avatar.id : null,
    lang: user.lang,
    generatedAvatar: user.generatedAvatar ? user.generatedAvatar.id : null
  };
}

function transformAssignedUsers(allUsers, selectedUsers) {
  const result = [];

  for (const userId of selectedUsers) {
    result.push(allUsers.find(u => u.userId === userId));
  }

  return result;
}

export default {
  transformUpdateUserReqBody,
  transformAssignedUsers
};
