import httpService from './http.service';

const BASE_GROUPS_URL = '/groups';

function index(params = {}) {
  return httpService.get(BASE_GROUPS_URL, params);
}

function getCurrentUserGroupsStats() {
  return httpService.get(`${BASE_GROUPS_URL}/current-user-stats`);
}

function get(id, params = {}) {
  return httpService.get(`${BASE_GROUPS_URL}/${id}`, params);
}

function create(payload) {
  return httpService.post(BASE_GROUPS_URL, payload);
}

function update(id, payload) {
  return httpService.put(`${BASE_GROUPS_URL}/${id}`, payload);
}

function del(id) {
  return httpService.del(`${BASE_GROUPS_URL}/${id}`);
}

export default {
  index,
  getCurrentUserGroupsStats,
  get,
  create,
  update,
  del
};
