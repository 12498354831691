<template>
  <v-navigation-drawer
    v-if="hasSideNav"
    fixed
    clipped
    class="white"
    app
    width="250"
    floating
  >
    <user-profile-card
      v-if="$vuetify.breakpoint.mdAndUp"
      class="mt-3"
    ></user-profile-card>
    <div class="text-center mt-2 mb-3">
      <support-us></support-us>
    </div>
    <div class="text-center">
      <feedback></feedback>
    </div>
  </v-navigation-drawer>
</template>

<script>
import UserProfileCard from '../users/UserProfileCard';
import SupportUs from './SupportUs';
import Feedback from './Feedback';
import { mapGetters } from 'vuex';

export default {
  name: 'side-nav',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback
  },
  computed: {
    ...mapGetters(['hasSideNav'])
  }
};
</script>
