import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  hasSideNav: true,
  bottomNavItemsVisibility: [true, false, false]
};

export default {
  state,
  actions,
  mutations,
  getters
};
