<template>
  <div class="medium">
    <moto-medium-size class="my-5"></moto-medium-size>
    <short-description-medium-size></short-description-medium-size>
    <how-does-it-work-medium-size></how-does-it-work-medium-size>
    <partners-medium-size></partners-medium-size>
  </div>
</template>

<script>
import MotoMediumSize from '../../components/home/MotoMediumSize';
import ShortDescriptionMediumSize from '../../components/home/ShortDescriptionMediumSize';
import HowDoesItWorkMediumSize from '../../components/home/HowDoesItWorkMediumSize';
import PartnersMediumSize from '../../components/home/PartnersMediumSize';

export default {
  name: 'home-medium-screen-size',
  components: {
    MotoMediumSize,
    ShortDescriptionMediumSize,
    HowDoesItWorkMediumSize,
    PartnersMediumSize
  }
};
</script>
