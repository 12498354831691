import httpService from '../http.service';

const BASE_ROUTE = '/gems-plans';

function index(params = {}) {
  return httpService.get(BASE_ROUTE, params);
}

export default {
  index
};
