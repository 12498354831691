import Vue from 'vue';
import Router from 'vue-router';
import authGuard from './guards/auth.guard';
import groupGuard from './guards/group.guard';
import Home from './views/home/Home.vue';
import groupsResolver from './resolvers/groups.resolver';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: {
        public: true
      },
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        public: true
      },
      component: () => import('./views/About.vue')
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      meta: {
        public: true
      },
      component: () => import('./views/static/TermsAndConditions.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      meta: {
        public: true
      },
      component: () => import('./views/static/PrivacyPolicy.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        public: true
      },
      component: () => import('./views/Login.vue')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      meta: {
        public: true
      },
      component: () => import('./views/SignUp.vue')
    },
    {
      path: '/group-sign-up',
      name: 'group-sign-up',
      meta: {
        public: true
      },
      component: () => import('./views/GroupSignUp.vue')
    },
    {
      path: '/verify-group-email-invitation',
      name: 'verify-group-email-invitation',
      meta: {},
      component: () => import('./views/groups/VerifyGroupEmailInvitation.vue')
    },
    {
      path: '/password-recovery',
      name: 'password-recovery',
      meta: {
        public: true
      },
      component: () => import('./views/PasswordRecovery.vue')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: {
        public: true
      },
      component: () => import('./views/ResetPassword.vue')
    },
    {
      path: '/successfully-registration',
      name: 'successfully-registration',
      meta: {
        public: true
      },
      component: () => import('./views/SuccessfulSignUp.vue')
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: {
        auth: true
      },
      component: () => import('./views/Dashboard.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      meta: {
        auth: true
      },
      component: () => import('./views/Settings.vue')
    },
    {
      path: '/missions',
      name: 'missions',
      meta: {
        auth: true
      },
      component: () => import('./views/Missions.vue')
    },
    {
      path: '/missions/:id',
      name: 'missionsDetail',
      meta: {
        auth: true
      },
      component: () => import('./views/MissionDetail.vue')
    },
    {
      path: '/groups',
      name: 'groups',
      meta: {
        auth: true
      },
      component: () => import('./views/groups/Groups.vue')
    },
    {
      path: '/groups/:id',
      meta: {
        auth: true
      },
      component: () => import('./views/groups/GroupDetailPage.vue'),
      beforeEnter: groupsResolver.groupDetailsResolver,
      children: [
        {
          path: '',
          name: 'groupDetail',
          component: () => import('./views/groups/GroupDashboard.vue')
        },
        {
          path: 'dashboard',
          name: 'groupDashboard',
          component: () => import('./views/groups/GroupDashboard.vue')
        },
        {
          path: 'members',
          name: 'groupMembers',
          component: () => import('./views/groups/GroupMembersPage.vue')
        },
        {
          path: 'archive',
          name: 'groupArchive',
          beforeEnter: groupGuard,
          component: () => import('./views/groups/GroupArchivePage.vue')
        }
      ]
    },
    {
      path: '/notifications',
      name: 'notifications',
      meta: {
        auth: true
      },
      component: () => import('./views/Notifications.vue')
    },
    {
      path: '/store',
      meta: {
        auth: true
      },
      component: () => import('./views/store/Store.vue'),
      children: [
        {
          path: '',
          name: 'store',
          component: () => import('./views/store/Avatars.vue')
        },
        {
          path: 'avatars',
          name: 'avatars-store',
          component: () => import('./views/store/Avatars.vue')
        },
        {
          path: 'boards',
          name: 'boards-store',
          component: () => import('./views/store/Boards.vue')
        },
        {
          path: 'purchases',
          name: 'purchases',
          component: () => import('./views/store/Purchases.vue')
        }
      ]
    },
    {
      path: '/stripe-payment',
      name: 'stripe-payment',
      meta: {
        auth: true
      },
      component: () => import('./views/payments/StripePayment.vue')
    },
    {
      path: '/success-gems-purchase',
      name: 'success-gems-purchase',
      meta: {
        auth: true
      },
      component: () => import('./views/payments/SuccessGemsPurchase.vue')
    }
  ]
});

router.beforeEach(authGuard);

export default router;
