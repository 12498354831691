import notificationTypes from '../enums/notification.types';
import i18n from '../translations';

const mappers = {
  [notificationTypes.groupInvitation]: toGroupInvitation,
  [notificationTypes.newTaskAssigned]: toNewAssignedTask,
  [notificationTypes.askTaskApprove]: toAskTaskApprove,
  [notificationTypes.taskApprove]: toApproveTask,
  [notificationTypes.newRewardAdded]: toNewRewardAdded,
  [notificationTypes.newMissionAssigned]: toNewAssignedMission,
  [notificationTypes.askMissionApprove]: toAskMissionApprove,
  [notificationTypes.missionApprove]: toApproveMission,
  [notificationTypes.disapprovedExpiredMission]: toDisapprovedExpiredMission,
  [notificationTypes.removeMember]: toRemoveMember,
  [notificationTypes.promoteToLeader]: toPromoteToLeader,
  [notificationTypes.lowerToMember]: toLowerToMember,
  [notificationTypes.requestRewardApproval]: toRequestRewardApproval,
  [notificationTypes.rewardApprove]: toApproveReward,
  [notificationTypes.sendGems]: toSendGems
};

function baseNotificationData(notification) {
  return {
    id: notification.id,
    type: notification.type,
    seen: notification.seen,
    name: notification.data.sender.name,
    imageUrl: notification.data.sender.imageUrl
  };
}

function toNotificationWithItemAndSender(type, senderName, itemName) {
  return {
    title: i18n.t(`notifications.${type}.title`),
    text: i18n.t(`notifications.${type}.text`, {
      senderName,
      itemName
    })
  };
}

function toBaseNotification(notification) {
  return {
    ...baseNotificationData(notification),
    title: i18n.t(`notifications.${notification.type}.title`),
    text: i18n.t(`notifications.${notification.type}.text`, {
      senderName: notification.data.sender.name
    })
  };
}

function toGroupInvitation(notification) {
  return {
    ...baseNotificationData(notification),
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.group.name
    )
  };
}

function toNewAssignedTask(notification) {
  return {
    ...baseNotificationData(notification),
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.task.title
    )
  };
}

function toAskTaskApprove(notification) {
  return {
    ...baseNotificationData(notification),
    groupId: notification.data.groupId,
    userId: notification.data.sender.id,
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.task.title
    )
  };
}

function toApproveTask(notification) {
  const { task, sender } = notification.data;

  const title = task.approved
    ? i18n.t(`notifications.${notification.type}.approved.title`)
    : i18n.t(`notifications.${notification.type}.disApproved.title`);

  const text = task.approved
    ? i18n.t(`notifications.${notification.type}.approved.text`, {
        senderName: sender.name,
        taskTitle: task.title
      })
    : i18n.t(`notifications.${notification.type}.disApproved.text`, {
        senderName: sender.name,
        taskTitle: task.title
      });

  return {
    ...baseNotificationData(notification),
    title,
    text
  };
}

function toNewRewardAdded(notification) {
  return {
    ...baseNotificationData(notification),
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.reward.name
    )
  };
}

function toNewAssignedMission(notification) {
  return {
    ...baseNotificationData(notification),
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.mission.name
    )
  };
}

function toAskMissionApprove(notification) {
  return {
    ...baseNotificationData(notification),
    groupId: notification.data.groupId,
    userId: notification.data.sender.id,
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.mission.name
    )
  };
}

function toApproveMission(notification) {
  const { mission, sender } = notification.data;

  const title = mission.approved
    ? i18n.t(`notifications.${notification.type}.approved.title`)
    : i18n.t(`notifications.${notification.type}.disApproved.title`);

  const text = mission.approved
    ? i18n.t(`notifications.${notification.type}.approved.text`, {
        senderName: sender.name,
        missionName: mission.name
      })
    : i18n.t(`notifications.${notification.type}.disApproved.text`, {
        senderName: sender.name,
        missionName: mission.name
      });

  return {
    ...baseNotificationData(notification),
    title,
    text
  };
}

function toDisapprovedExpiredMission(notification) {
  const { sender, mission, memberStats } = notification.data;
  const title = i18n.t('notifications.missionApprove.disApproved.title');
  const text = i18n.t(
    'notifications.missionApprove.disapprovedExpiredMission.text',
    {
      senderName: sender.name,
      missionName: mission.name,
      ruby: memberStats.diff.ruby
    }
  );

  return {
    ...baseNotificationData(notification),
    title,
    text
  };
}

function toRemoveMember(notification) {
  return {
    ...baseNotificationData(notification),
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.group.name
    )
  };
}

function toPromoteToLeader(notification) {
  const { data } = notification;
  return {
    ...baseNotificationData(notification),
    title: i18n.t(`notifications.${notification.type}.title`, {
      role: data.role
    }),
    text: i18n.t(`notifications.${notification.type}.text`, {
      senderName: data.sender.name,
      role: data.role,
      itemName: data.group.name
    })
  };
}

function toLowerToMember(notification) {
  const { data } = notification;
  return {
    ...baseNotificationData(notification),
    title: i18n.t(`notifications.${notification.type}.title`, {
      role: data.role
    }),
    text: i18n.t(`notifications.${notification.type}.text`, {
      senderName: data.sender.name,
      role: data.role,
      itemName: data.group.name
    })
  };
}

function toRequestRewardApproval(notification) {
  return {
    ...baseNotificationData(notification),
    groupId: notification.data.groupId,
    userId: notification.data.sender.id,
    ...toNotificationWithItemAndSender(
      notification.type,
      notification.data.sender.name,
      notification.data.reward.name
    )
  };
}

function toApproveReward(notification) {
  const { reward, sender } = notification.data;

  const title = reward.approved
    ? i18n.t(`notifications.${notification.type}.approved.title`)
    : i18n.t(`notifications.${notification.type}.disApproved.title`);

  const text = reward.approved
    ? i18n.t(`notifications.${notification.type}.approved.text`, {
        senderName: sender.name,
        rewardName: reward.name,
        ruby: reward.ruby
      })
    : i18n.t(`notifications.${notification.type}.disApproved.text`, {
        senderName: sender.name,
        rewardName: reward.name
      });

  return {
    ...baseNotificationData(notification),
    title,
    text
  };
}

function toSendGems(notification) {
  const { data } = notification;

  return {
    ...baseNotificationData(notification),
    title: i18n.t(`notifications.${notification.type}.title`, {
      role: data.role
    }),
    text: i18n.t(`notifications.${notification.type}.text`, {
      senderName: data.sender.name,
      gems: data.gems
    })
  };
}

function transformToNotificationDisplayData(notification) {
  if (mappers[notification.type]) {
    return mappers[notification.type](notification);
  } else {
    return toBaseNotification(notification);
  }
}

function transformToMultipleNotificationsDisplayData(notifications) {
  return notifications.map(n => transformToNotificationDisplayData(n));
}

export default {
  transformToNotificationDisplayData,
  transformToMultipleNotificationsDisplayData
};
