import {
  SET_TASKS,
  SET_ARCHIVED_TASKS,
  SET_APPROVAL_TASKS,
  ADD_TASK,
  ADD_ARCHIVED_TASK,
  REOPEN_CRON_TASKS,
  UPDATE_TASK,
  APPROVE_GROUP_TASK,
  DELETE_TASK,
  DELETE_ARCHIVED_TASK,
  UPDATE_TASKS_TYPES_FILTER,
  RESET_TASKS_TYPES_FILTER
} from './types';
import listsHelper from '../../common/lists.helper';
import taskTypes from '../../enums/task.types';

function updateTask(state, task) {
  const index = state.tasks.findIndex(t => t.id === task.id);

  if (index !== -1) {
    state.tasks.splice(index, 1, {
      ...state.tasks[index],
      ...task
    });
  }
}

function sortTasks(tasks) {
  const byGold = listsHelper.sortByGold(tasks.filter(t => t.gold > 0));
  const byRuby = listsHelper.sortByRuby(tasks.filter(t => t.ruby > 0));

  return [...byGold, ...byRuby];
}

export default {
  [SET_TASKS](state, tasks) {
    state.tasks = sortTasks(tasks);
  },
  [SET_ARCHIVED_TASKS](state, tasks) {
    state.archivedTasks = sortTasks(tasks);
  },
  [SET_APPROVAL_TASKS](state, tasks) {
    state.approvalTasks = tasks;
  },
  [ADD_TASK](state, task) {
    state.tasks.push(task);
    state.tasks = sortTasks(state.tasks);
  },
  [ADD_ARCHIVED_TASK](state, task) {
    state.archivedTasks.push(task);
    state.archivedTasks = sortTasks(state.archivedTasks);
  },
  [REOPEN_CRON_TASKS](state, tasks) {
    for (const task of tasks) {
      updateTask(state, task);
    }
  },
  [UPDATE_TASK](state, task) {
    updateTask(state, task);

    state.tasks = sortTasks(state.tasks);
  },
  [APPROVE_GROUP_TASK](state, { taskId, groupTaskId }) {
    const approvalIndex = state.approvalTasks.findIndex(
      task => task.id === taskId
    );
    const task = state.tasks.find(gt => gt.id === groupTaskId);

    if (approvalIndex !== -1) {
      state.approvalTasks.splice(approvalIndex, 1);
    }

    if (task) {
      task.waitingApproval = task.waitingApproval - 1;
    }
  },
  [UPDATE_TASKS_TYPES_FILTER](state, filter) {
    const index = state.tasksTypesFilter.findIndex(f => f.type === filter);

    state.tasksTypesFilter.splice(index, 1, {
      type: filter,
      selected: !state.tasksTypesFilter[index].selected
    });
  },
  [RESET_TASKS_TYPES_FILTER](state) {
    state.tasksTypesFilter = [
      { type: taskTypes.todo, selected: false },
      { type: taskTypes.daily, selected: false },
      { type: taskTypes.weekly, selected: false }
    ];
  },
  [DELETE_TASK](state, id) {
    const index = state.tasks.findIndex(task => task.id === id);

    if (index !== -1) {
      state.tasks.splice(index, 1);
    }
  },
  [DELETE_ARCHIVED_TASK](state, id) {
    const index = state.archivedTasks.findIndex(task => task.id === id);

    if (index !== -1) {
      state.archivedTasks.splice(index, 1);
    }
  }
};
