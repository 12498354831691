export default {
  groups: {
    groups: 'Groups',
    info: 'Group info',
    myGroups: 'My groups',
    noGroupsFound: 'No groups found!',
    createGroup: 'Create group',
    editGroup: 'Edit group',
    leaderRoleDisplayName: 'Leader role display name',
    memberRoleDisplayName: 'Member role display name',
    defaultLeaderRoleDisplayName: 'Default is Leader',
    defaultMemberRoleDisplayName: 'Default is Member',
    groupCreatedSuccessfully: 'Group created successfully!',
    groupUpdatedSuccessfully: 'Group updated successfully!',
    groupDeletedSuccessfully: 'Group deleted successfully!',
    deleteGroupConfirm: 'Are you sure you want to delete this group?',
    backToGroups: 'Back to groups',
    myGroupsStats: 'My groups stats',
    cannotCreateMoreThan2Groups: `For the BETA version of Gamifinnopoly you won't be able to create more than 2 groups!`,
    youCanCreateGroups: 'You can create {count} more group/s!',
    cannotCreateMoreGroups: 'Cannot create more groups!',
    groupDashboard: `Group's dashboard`,
    assignedUsers: 'Assigned users'
  }
};
