import httpService from './http.service';

const BASE_DASHBOARD_URL = '/dashboard';

function getCurrentUserDashboard() {
  return httpService.get(BASE_DASHBOARD_URL);
}

function getGroupDashboard(groupId) {
  return httpService.get(`/groups/${groupId}/dashboard`);
}

function getDashboardForGroupMember(groupId, userId) {
  return httpService.get(
    `${BASE_DASHBOARD_URL}/groups/${groupId}/users/${userId}`
  );
}

export default {
  getCurrentUserDashboard,
  getGroupDashboard,
  getDashboardForGroupMember
};
