<template>
  <base-notification :baseNotification="notification">
    <template slot="right-column">
      <v-btn
        dark
        small
        color="app-blue"
        class="mx-0"
        @click.stop="acceptInvitation"
        :loading="loading"
      >
        {{ $t('members.acceptInvitation') }}
      </v-btn>
    </template>
  </base-notification>
</template>

<script>
import BaseNotification from './BaseNotification';
import { ACCEPT_GROUP_INVITATION } from '../../../store/group-members/types';

export default {
  name: 'group-invitation-notification',
  components: {
    BaseNotification
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async acceptInvitation() {
      this.loading = true;

      await this.$store.dispatch(ACCEPT_GROUP_INVITATION, this.notification.id);

      this.loading = false;

      this.$notify({
        type: 'success',
        text: this.$root.$t('members.successfullyJoinedGroup')
      });
    }
  }
};
</script>
