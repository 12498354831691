export default {
  defaultServerError: {
    type: 'BadRequest',
    status: '400',
    message: 'somethingWentWrong'
  },
  authenticationFailed: 'authenticationFailed',
  yourAccountIsLocked: 'yourAccountIsLocked',
  passwordRecoveryLinkExpired: 'passwordRecoveryLinkExpired'
};
