export const GET_PROFILE = 'GET_PROFILE';
export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_USER_STATS = 'UPDATE_USER_STATS';
export const UPDATE_USER_AVATAR = 'UPDATE_USER_AVATAR';
export const UPDATE_USER_BOARD = 'UPDATE_USER_BOARD';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_HOLIDAY_MODE = 'CHANGE_HOLIDAY_MODE';
export const GENERATE_AVATAR = 'GENERATE_AVATAR';
export const SET_GENERATED_AVATAR = 'SET_GENERATED_AVATAR';
