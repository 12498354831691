import httpService from '../http.service';

const BASE_ROUTE = '/create-stripe-session';

function createCheckoutSession(body = {}) {
  return httpService.post(BASE_ROUTE, body);
}

export default {
  createCheckoutSession
};
