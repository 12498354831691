import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import GoldCoinIcon from '../components/icons/GoldCoinIcon';
import RubyIcon from '../components/icons/RubyIcon';
import GemIcon from '../components/icons/GemIcon';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
    values: {
      gold: {
        component: GoldCoinIcon
      },
      ruby: {
        component: RubyIcon
      },
      gem: {
        component: GemIcon
      }
    }
  },
  theme: {
    themes: {
      light: {
        'app-bg': '#EEF6FC',
        'app-blue': '#0094e3',
        'app-red': '#e53935',
        'app-green': '#40C785',
        'app-orange': '#F9A825',
        'app-yellow': '#ffd686',
        'app-dark-gray': '#6C7293',
        'app-light-gray': '#B8C2CC',
        'headline-color': '#3D474D',
        'app-light-blue': '#52baf8',
        'app-grey': '#8492A6',
        primary: '#0094e3',
        success: '#40C785',
        error: '#e53935',
        warning: '#F9A825',
        info: '#0094e3'
      }
    }
  }
});
