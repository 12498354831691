import Vue from 'vue';
import VueI18n from 'vue-i18n';
import bg from './bg';
import en from './en';
import langHelper from '../common/lang.helper';

Vue.use(VueI18n);

export default new VueI18n({
  locale: langHelper.getLanguage(),
  messages: {
    bg,
    en
  }
});
