import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  groups: [],
  currentGroup: {},
  currentMember: {}
};

export default {
  state,
  actions,
  mutations,
  getters
};
