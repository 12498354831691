export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
export const MARK_ALL_NOTIFICATIONS_AS_SEEN = 'MARK_ALL_NOTIFICATIONS_AS_SEEN';
export const MARK_AS_SEEN = 'MARK_AS_SEEN';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';

export const GET_OLD_NOTIFICATIONS = 'GET_OLD_NOTIFICATIONS';
export const SET_OLD_NOTIFICATIONS = 'SET_OLD_NOTIFICATIONS';
export const SET_OLD_NOTIFICATIONS_COUNT = 'SET_OLD_NOTIFICATIONS_COUNT';
export const DELETE_OLD_NOTIFICATION = 'DELETE_OLD_NOTIFICATION';
