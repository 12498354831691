import {
  SET_REWARDS,
  GET_GROUP_REWARDS,
  GET_APPROVAL_REWARDS,
  SET_APPROVAL_REWARDS,
  ADD_GROUP_REWARD,
  ADD_REWARD,
  UPDATE_GROUP_REWARD,
  UPDATE_REWARD,
  ARCHIVE_GROUP_REWARD,
  COPY_GROUP_REWARD,
  REDEEM_GROUP_REWARD,
  APPROVE_GROUP_REWARD,
  DELETE_GROUP_REWARD,
  DELETE_REWARD,
  APPROVE_ALL_REWARDS
} from '../types';
import { UPDATE_CURRENT_GROUP_MEMBER_STATS } from '../../group-members/types';
import groupRewardsService from '../../../services/groups/group.rewards.service';
import memberRewardsService from '../../../services/group.rewards.http.service';
import rewardTypes from '../../../enums/reward.types';
import rewardsMapper from '../../../mappers/rewards.mapper';
import usersMapper from '../../../mappers/users.mapper';

export default {
  async [GET_GROUP_REWARDS]({ commit }, payload) {
    const { groupId, params } = payload;
    const { result } = await groupRewardsService.index(groupId, params);

    commit(SET_REWARDS, result);
  },
  async [GET_APPROVAL_REWARDS]({ commit }, payload) {
    const { groupId, groupRewardId } = payload;
    const { result } = await groupRewardsService.getApprovalRewards(
      groupId,
      groupRewardId
    );

    commit(SET_APPROVAL_REWARDS, result);
  },
  async [ADD_GROUP_REWARD]({ commit }, payload) {
    const { groupId, reward, members } = payload;
    const { result } = await groupRewardsService.create(groupId, reward);

    if (members) {
      reward.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        reward.assignedUsers
      );
    }

    commit(ADD_REWARD, { id: result, ...reward });
  },
  async [UPDATE_GROUP_REWARD]({ commit }, payload) {
    const { groupId, reward, members } = payload;
    const { id, ...rewardData } = reward;

    await groupRewardsService.update(
      groupId,
      id,
      rewardsMapper.transformToGroupRewardUpdateReqBody(rewardData)
    );

    if (members) {
      reward.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        reward.assignedUsers
      );
    }

    commit(UPDATE_REWARD, reward);
  },
  async [COPY_GROUP_REWARD]({ commit }, payload) {
    const { groupId, reward } = payload;
    const { id, ...rewardData } = reward;

    await groupRewardsService.copy(
      groupId,
      id,
      rewardsMapper.transformToGroupRewardUpdateReqBody(rewardData)
    );

    commit(DELETE_REWARD, id);
  },
  async [ARCHIVE_GROUP_REWARD]({ commit }, payload) {
    const { groupId, rewardId } = payload;

    await groupRewardsService.archive(groupId, rewardId);

    commit(DELETE_REWARD, rewardId);
  },
  async [REDEEM_GROUP_REWARD]({ commit }, payload) {
    const { groupId, reward } = payload;

    const { result } = await memberRewardsService.redeem(groupId, reward.id);

    if (reward.requireApprove) {
      commit(UPDATE_REWARD, { id: reward.id, approveRequested: true });
    } else {
      if (reward.type === rewardTypes.times) {
        const timeToUse = reward.timesToUse - 1;

        if (timeToUse === 0) {
          commit(DELETE_REWARD, reward.id);
        } else {
          commit(UPDATE_REWARD, { id: reward.id, timeToUse });
        }
      } else if (reward.type === rewardTypes.once) {
        commit(DELETE_REWARD, reward.id);
      }
    }

    if (result.stats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.stats);
    }

    return result;
  },
  async [APPROVE_GROUP_REWARD]({ commit }, payload) {
    const { groupId, userId, rewardId, groupRewardId, approved } = payload;

    await memberRewardsService.approve(groupId, userId, rewardId, { approved });

    if (groupRewardId) {
      commit(APPROVE_GROUP_REWARD, {
        rewardId,
        groupRewardId
      });
    } else {
      commit(UPDATE_REWARD, {
        id: rewardId,
        approveRequested: false,
        approved,
        completed: approved
      });
    }
  },
  async [APPROVE_ALL_REWARDS]({ commit }, payload) {
    const { groupId, groupRewardId, approved } = payload;
    await groupRewardsService.approveAllRewards(groupId, groupRewardId, {
      approved
    });

    commit(UPDATE_REWARD, { id: groupRewardId, waitingApproval: 0 });
  },
  async [DELETE_GROUP_REWARD]({ commit }, payload) {
    const { groupId, rewardId } = payload;
    await groupRewardsService.del(groupId, rewardId);

    commit(DELETE_REWARD, rewardId);
  }
};
