export default {
  errors: {
    somethingWentWrong:
      'Имаме техниечески проблеми! Нашият екип работи по отстраняването им. Благодарим Ви за търпението!',
    wrongCredentials: 'Грешен имейл адрес / парола!',
    invalidData: 'Невалидни данни!',
    emailAlreadyUsed: 'Имейл адресът е зает!',
    passwordsMismatch: 'Паролите не съвпадат!',
    notSupportedSocialNetwork: 'Тази социална мрежа не се поддържа!',
    userNotFound: 'Потребителят не беше намерен!',
    incorrectOldPassword: 'Старата парола е грешна!',
    taskAlreadyCompleted: 'Задачата е вече завършена!',
    taskNotCompleted: 'Задачата не е завършена!',
    cannotUpdateCompletedTask: 'Не може да променяте завършена задача!',
    cannotReopenToDoTask:
      'Не може да активирате вече завършена задача от тип "Еднократна"!',
    invalidTimezone: 'Невалидна часова зона!',
    invalidQueryParams: 'Невалидни параметри при заявката!',
    authenticationFailed: 'Неуспешна идентификация!',
    notAuthorized: 'Отказан достъп!',
    missionNotFound: 'Мисията не беше намерена!',
    missionAlreadyCompletedOrFailed: 'Мисията е вече завършена или провалена!',
    missionIsNotActive: 'Мисията не е активна!',
    missionTasksIncompleted: 'Задачите на мисията не са завършени!',
    cannotCompleteMissionWithoutTasks:
      'Не може да завършите мисия, която няма задачи!',
    taskNotFound: 'Задачата не беше намерена!',
    cannotCompleteArchivedTask: 'Не може да приключите архивирана задача!',
    cannotReopenArchivedTask: 'Не може да активирате архивирана задача!',
    cannotArchiveIncompletedTask: 'Не може да архивирате незавършена задача!',
    taskAlreadyArchived: 'Не може да архивирате вече архивирана задача!',
    cannotCloneTask: 'Не може да копирате задачата!',
    rewardNotFound: 'Наградата не беше намерена!',
    notEnoughGoldToRedeemReward:
      'Нямате достатъчно злато, за да вземете наградата!',
    passwordRecoveryLinkExpired:
      'Линкът за възстановяване на паролата е изтекъл!',
    cannotCloneActivePendingMission:
      'Не може да копирате активна или в процес на стартиране мисия!',
    yourAccountIsLocked:
      'Вашият потребител е заключен! Моля свържете се с човек от нашият екип за повече информация.',
    boardNotFound: 'Бордът не беше намерен!',
    notSpecialBoardPosition: 'Позицията не е специална!',
    positionAlreadyUsed: 'Позицията е вече използвана!',
    alreadyApproved: 'Задачата е вече одобрена!',
    quoteNotFound: 'Цитатът не беше намерен!',
    factNotFound: 'Фактът не беше намерен!',
    questionNotFound: 'Въпросът не беше намерен!',
    groupNotFound: 'Групата не е намерена!',
    notAllowedToUpdateGroup: 'Нямате права да редактирате тази група!',
    notAllowedToManageGroup:
      'Не можете да управлявата тази група, груповите задачи, награди или мисии!',
    notPartOfThisGroup: 'Не е участник в тази група!',
    youAreNotPartOfTheGroup: 'Не сте част от групата!',
    notLeaderInThisGroup: 'Не сте създател или лидер в тази група!',
    notLeaderOrMemberRole: 'Не сте лидер или участник в тази група!',
    notCreatorOfThisGroup: 'Не сте създател на групата!',
    cannotManageCreator: 'Не може да управлявате създателя на групата!',
    alreadyLeaderToGroup: 'Вече сте лидер в групата!',
    alreadyMemberRole: 'Вече сте с роля участник в групата!',
    userAlreadyInGroup: 'Вече сте участник в тази група!',
    inviteAlreadyAccepted: 'Поканата ви вече беше приета!',
    thisIsNotYourInvitation: 'Това не е вашата покана!',
    notMemberRole: 'Не сте с роля участник!',
    cannotAddTaskToYourself: 'Не може да добавяте задачи на самия себе си!!',
    notGroupTask: 'Не е групова задача!',
    taskNotInThisGroup: 'Задачата не в тази група!',
    memberNotFound: 'Участникът не е намерен!',
    groupTaskApproveNotRequired: 'Одобрението на задачата не е задължително!',
    groupTaskApproveAlreadyRequested: 'Задачата вече е изпратена за одобрение!',
    groupTaskApproveNotRequested: 'Задачата не е изпратена за одобрение!',
    cannotAskForApproveNotCompletedGroupTask:
      'Не може да искате одобрение на задача, когато тя все още не е завършена!',
    cannotApproveNotCompletedGroupTask:
      'Не можете да одобрявате незавършена задача!',
    notGroupReward: 'Не е групова награда!',
    cannotAddMissionToYourself: 'Не може да добавяте мисия на самия себе си!',
    notGroupMission: 'Не е групова мисия!',
    groupMissionAlreadyCompleted: 'Мисията вече е завършена!',
    groupMissionApproveNotRequired: 'Одобрението на мисията не е задължително!',
    groupMissionApproveNotRequested: 'Мисията не е изпратена за одобрение!',
    groupMissionApproveAlreadyRequested:
      'Мисията вече е изпратена за одобрение!',
    groupMissionWaitingForApprove: 'Изчаква се одобрение на мисията!',
    cannotApproveNotRequestedForApproveMission:
      'Не може да одобрите мисия, когато тя не е изпратена за одобрение!',
    groupInviteNotificationNotFound:
      'Известието за покана за влизане в група не е намерено!',
    notEnoughRubyToRedeemReward:
      'Нямате достатъчно руби, за да вземете наградата!',
    groupRewardApprovalAlreadyRequested:
      'Наградата вече е изпратена за одобрение!',
    groupRewardApprovalNotRequired:
      'Одобрението на наградата не е задължително!',
    groupRewardApprovalNotRequested: 'Наградате не е изпратена за одобрение!',
    tokenDoesNotExist: 'Токена е изтекъл или не съществува!',
    groupInvitationLinkExpired:
      'Линкът за покана за присъединяване към група е изтекъл или не съществува!',
    cannotCreateGroup:
      'За текущата БЕТА версия на Gamifinnopoly, няма да можете да създавате повече от 2 групи!',
    cannotInviteMembers:
      'За текущата БЕТА версия на Gamifinnopoly, няма да можете да добавяте повече от 5 потребителя в група!',
    emailNotificationNotFound: 'Нотификацията по имейл не съществува!',
    notificationNotFound: 'Нотификацията не е намерена!',
    expiredToken: 'Токенът е изтекъл',
    accountAlreadyActivated: 'Потребителят е вече активиран!',
    accountActivationLinkExpired: 'Линкът за активиране на потребил е изтекъл!',
    yourAccountIsNotActivated: 'Потребителят не е активиран!',
    notEnoughGemsToBuyAvatar: `Нямате достатъчно Gems, за да купите този аватар!`,
    notEnoughGemsToBuyBoard: `Нямате достатъчно Gems, за да купите този борд!`,
    avatarNotFound: 'Аватарът не беше намерен',
    notEnoughGoldToBuyAvatar:
      'Нямате достатъчно злато, за да купите този аватар!',
    notEnoughGoldToBuyBoard: 'Нямате достатъчно злато, за да купите този борд!',
    userDoesNotHaveThisAvatar: 'Нямате този аватар!',
    userDoesNotHaveThisBoard: 'Нямате този борд!',
    avatarAlreadyBought: 'Аватарът е вече закупен!',
    boardAlreadyBought: 'Бордът е вече закупен!',
    userDoesNotHaveEnoughGems: `Нямате достатъчно Gems!`,
    cannotAddResult: 'Не може да добавите резултат към задачата!',
    resultOfTheTaskRequired: 'Резултата от задачата е задължителен!',
    noTasksForApproval: 'Няма задачи за одобрение!',
    waitingApprovalTasksExist:
      'Тази задача изисква одобрение от потребител/ите! Моля, първо ги одобрете преди да я промените!',
    waitingApprovalRewardsExist:
      'Тази награда изисква одобрение от потребител/ите! Моля, първо ги одобрете преди да го промените!',
    cannotAddRewardToYourself: 'Не може да добавяте награда на самия себе си!',
    holidayModeEnabledError:
      'Ако искате да изпълните това действие трябва да изключите режима на почивка!',
    cannotGenerateTasks: 'Не успяхме да генерираме задачите!',
    notEnoughGemsToGenerateTasks:
      'Нямате достатъчно Gems, за да генерирате задачи!',
    cannotGenerateRewards: 'Не успяхме да генерираме наградите!',
    notEnoughGemsToGenerateRewards:
      'Нямате достатъчно Gems, за да генерирате награди!',
    cannotGenerateAvatar: 'Не успяхме да генерираме профилна снимка!',
    notEnoughGemsToGenerateAvatar:
      'Нямате достатъчно Gems, за да генерирате профилна снимка!'
  }
};
