import missionStatuses from '../enums/mission.statuses';

function getMissionUpdateReqBody(mission) {
  const result = {
    id: mission.id,
    name: mission.name,
    notes: mission.notes,
    requireApprove: mission.requireApprove,
    assignedUsers: mission.assignedUsers
  };

  if (mission.status === missionStatuses.pending) {
    result.from = new Date(mission.from);
    result.to = new Date(mission.to);
  }

  return result;
}

function getGroupMissionReqBody(mission) {
  return {
    name: mission.name,
    notes: mission.notes,
    from: mission.from,
    to: mission.to,
    requireApprove: mission.requireApprove,
    assignedUsers: mission.assignedUsers
  };
}

function getGroupMissionUpdateReqBody(mission) {
  const result = {
    name: mission.name,
    notes: mission.notes,
    requireApprove: mission.requireApprove,
    assignedUsers: mission.assignedUsers
  };

  if (mission.status === missionStatuses.pending) {
    result.from = new Date(mission.from);
    result.to = new Date(mission.to);
  }

  return result;
}

export default {
  getMissionUpdateReqBody,
  getGroupMissionReqBody,
  getGroupMissionUpdateReqBody
};
