import httpService from './http.service';

const BASE_URL = '/questions';

function answerQuestion(id, payload) {
  return httpService.post(`${BASE_URL}/${id}/answer`, payload);
}

export default {
  answerQuestion
};
