import moment from 'moment';

function formatDate(date, format = 'DD.MM.YYYY') {
  return moment(date).format(format);
}

function localDateTimeFormat(date, format = 'DD.MM.YYYY') {
  return moment(date).local().format(format);
}

function getTimezoneOffset() {
  return moment().utcOffset();
}

function getRefreshDateTimeout(hours, minutes, seconds) {
  const now = new Date();
  const then = new Date();

  if (
    now.getHours() > hours ||
    (now.getHours() == hours && now.getMinutes() > minutes) ||
    (now.getHours() == hours &&
      now.getMinutes() == minutes &&
      now.getSeconds() >= seconds)
  ) {
    then.setDate(now.getDate() + 1);
  }
  then.setHours(hours);
  then.setMinutes(minutes);
  then.setSeconds(seconds);

  const timeout = then.getTime() - now.getTime();

  return timeout;
}

function toICODateString(date) {
  return moment(date ? date : new Date())
    .toISOString(true)
    .substr(0, 10);
}

function diffInDays(start, end, includeEnd = false) {
  let result = moment(end).diff(moment(start), 'days');

  if (includeEnd) {
    result += 1;
  }

  return result;
}

function isBefore(first, second) {
  return moment(first).isBefore(moment(second));
}

function isAfter(first, second) {
  return moment(first).isAfter(moment(second));
}

function isSameOrBefore(first, second) {
  return moment(first).isSameOrBefore(moment(second));
}

function isSameOrAfter(first, second) {
  return moment(first).isSameOrAfter(moment(second));
}

function isSame(first, second, granularity = 'day') {
  return moment(first).isSame(moment(second), granularity);
}

export default {
  formatDate,
  localDateTimeFormat,
  getTimezoneOffset,
  getRefreshDateTimeout,
  toICODateString,
  diffInDays,
  isBefore,
  isAfter,
  isSameOrBefore,
  isSameOrAfter,
  isSame
};
