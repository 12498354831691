export const GET_GROUPS = 'GET_GROUPS';
export const SET_GROUPS = 'SET_GROUPS';
export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';

export const GET_CURRENT_GROUP = 'GET_CURRENT_GROUP';
export const SET_CURRENT_GROUP = 'SET_CURRENT_GROUP';
export const UPDATE_CURRENT_GROUP = 'UPDATE_CURRENT_GROUP';

export const GET_CURRENT_USER_GROUPS_STATS = 'GET_CURRENT_USER_GROUPS_STATS';
