function transformToTaskReqBody(task) {
  return {
    title: task.title,
    notes: task.notes,
    gold: task.gold,
    type: task.type,
    tags: task.tags,
    requireResult: task.requireResult
  };
}

function transformToMissionTaskReqBody(task) {
  return {
    title: task.title,
    notes: task.notes,
    gold: task.gold,
    type: task.type,
    tags: task.tags
  };
}

function transformToMultipeMissionTaskReqBody(tasks) {
  return tasks.map(task => transformToMissionTaskReqBody(task));
}

function transformToGroupTaskReqBody(task) {
  const result = {
    title: task.title,
    notes: task.notes,
    ruby: task.ruby,
    type: task.type,
    tags: task.tags,
    requireApprove: task.requireApprove,
    requireResult: task.requireResult
  };

  if (task.assignedUsers) {
    result.assignedUsers = task.assignedUsers;
  }

  return result;
}

function transformToGroupMissionTaskReqBody(task) {
  return {
    title: task.title,
    notes: task.notes,
    ruby: task.ruby,
    type: task.type,
    tags: task.tags
  };
}

function transformToGeneratedMissionTasks(tasks) {
  return tasks.map(task => ({
    ...task,
    selected: true
  }));
}

export default {
  transformToTaskReqBody,
  transformToMissionTaskReqBody,
  transformToMultipeMissionTaskReqBody,
  transformToGroupTaskReqBody,
  transformToGroupMissionTaskReqBody,
  transformToGeneratedMissionTasks
};
