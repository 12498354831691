<template>
  <fragment>
    <v-icon class="mr-2 height-20"> $vuetify.icons.gem </v-icon>
    <span
      class="font-weight-medium text-subtitle-1 text-no-wrap"
      :class="textColor"
    >
      {{ gems | numberFormat }}
    </span>
  </fragment>
</template>

<script>
export default {
  name: 'gems-label',
  props: {
    gems: {
      required: true
    },
    textColor: {
      type: String,
      default: 'app-green--text'
    }
  }
};
</script>
