import httpService from '../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, rewardId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/rewards`;

  if (rewardId) {
    url = `${url}/${rewardId}`;
  }

  return url;
}

function index(groupId, params = {}) {
  return httpService.get(getUrl(groupId), params);
}

function getApprovalRewards(groupId, groupRewardId) {
  return httpService.get(`${getUrl(groupId)}/${groupRewardId}/approvals`);
}

function create(groupId, payload) {
  return httpService.post(getUrl(groupId), payload);
}

function update(groupId, rewardId, payload) {
  return httpService.put(getUrl(groupId, rewardId), payload);
}

function archive(groupId, rewardId) {
  return httpService.patch(`${getUrl(groupId, rewardId)}/archive`);
}

function copy(groupId, rewardId, payload) {
  return httpService.patch(`${getUrl(groupId, rewardId)}/copy`, payload);
}

function approveAllRewards(groupId, groupRewardId, payload) {
  return httpService.post(
    `${getUrl(groupId)}/${groupRewardId}/approve`,
    payload
  );
}

function del(groupId, rewardId) {
  return httpService.del(getUrl(groupId, rewardId));
}

export default {
  index,
  getApprovalRewards,
  create,
  update,
  archive,
  copy,
  approveAllRewards,
  del
};
