import httpService from './http.service';

function hasAccount(params) {
  return httpService.get('/auth/has-account', params);
}

function register(payload) {
  return httpService.post('/auth/register', payload);
}

function groupRegister(payload) {
  return httpService.post('/auth/group-register', payload);
}

function resendNewActivationEmail(payload) {
  return httpService.post('/auth/resend-activation', payload);
}

function activateAccount(payload) {
  return httpService.post('/auth/activate-account', payload);
}

function login(payload) {
  return httpService.post('/auth/login', payload);
}

function socialLogin(payload) {
  return httpService.post('/auth/social', payload);
}

function sendRecoveryEmail(payload) {
  return httpService.post('/auth/account-recovery', payload);
}

function verifyRecoveryLink(payload) {
  return httpService.post('/auth/verify-link', payload);
}

function resetPassword(payload) {
  return httpService.post('/auth/reset-password', payload);
}

function logout() {
  return httpService.post('/auth/logout');
}

export default {
  hasAccount,
  register,
  groupRegister,
  resendNewActivationEmail,
  activateAccount,
  login,
  socialLogin,
  sendRecoveryEmail,
  verifyRecoveryLink,
  resetPassword,
  logout
};
