export default {
  avatars: {
    avatars: 'Avatars',
    areYouSureToBuyThisAvatar: 'Are you sure you want to buy this avatar?',
    notEnoughGemsToBuyAvatar: `You don't have enough gems to buy this avatar!`,
    notEnoughGoldToBuyAvatar: `You don't have enough gold to buy this avatar!`,
    avatarBoughtSuccessfully: 'You successfully bought this avatar!',
    noAvatarsFound: 'No avatars found!',
    doYouWantToSetAvatarAsProfile:
      'Do you want to set your new avatar as profile image?',
    useAvatar: 'Use avatar',
    successAvatarChange: 'You successfully changed your avatar!',
    myAvatars: 'My avatars',
    buyMoreAvatars: 'Buy more avatars'
  }
};
