<template>
  <div class="moto my-12">
    <v-container class="mt-4 pa-6">
      <v-row align="center" justify="center">
        <v-col md="6" sm="12">
          <v-row class="mb-2">
            <v-col cols="12" class="px-3">
              <h1 class="text-h2 font-weight-bold">Gamifinnopoly</h1>
              <h1 class="grey--text text--darken-1 text-h4 font-weight-bold">
                {{ $t('about.moto') }}
              </h1>
              <h2 class="font-weight-bold mt-3">
                {{ $t('about.poweredByAI') }}
              </h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="px-3">
              <v-btn
                color="app-blue"
                dark
                class="text-h6 get-started-btn mx-0"
                to="/sign-up"
              >
                {{ $t('common.getStarted') }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="6">
              <a
                target="_blank"
                :href="googlePlayUrl"
                alt="Gamifinnopoly on Google Play"
              >
                <v-img
                  max-height="100"
                  max-width="210"
                  :src="images.googlePlayBadge"
                ></v-img
              ></a>
            </v-col>
          </v-row>
          <v-row justify="start">
            <v-col cols="6">
              <v-img
                contain
                max-height="150"
                max-width="150"
                :src="images.innovationExcellence2023"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="6" sm="12" cols="12" class="pl-4">
          <v-img
            max-height="500"
            contain
            class="border-radius-10"
            :src="images.gamifinnatorGamifinna"
          ></v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'moto-medium-size',
  data() {
    return {
      images: {
        gamifinnatorGamifinna: require('../../assets/images/gamifinnator-gamifinna.png'),
        googlePlayBadge: require('../../assets/images/app-badges/google-play-badge.png'),
        innovationExcellence2023: require('../../assets/images/company-awards/Innovation-Excellence-2023-EN.png')
      },
      googlePlayUrl: process.env.VUE_APP_GOOGLE_PLAY_APP_URL
    };
  }
};
</script>
