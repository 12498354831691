export default {
  missions(state) {
    return state.missions;
  },
  approvalMissions(state) {
    return state.approvalMissions;
  },
  currentMission(state) {
    return state.currentMission;
  },
  missionTasksTypesFilter(state) {
    return state.missionTasksTypesFilter;
  }
};
