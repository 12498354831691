<template>
  <v-app-bar dark color="app-blue" app clipped-left class="app-nav">
    <v-app-bar-nav-icon
      large
      class="hidden-sm-and-down ml-0"
      @click="toggleSideNav()"
    ></v-app-bar-nav-icon>
    <v-app-bar-title class="pl-3">
      <div class="inline-b hidden-xs-only">
        <v-btn text to="/dashboard" color="app-blue" class="logo mr-1">
          <v-img contain :src="whiteLogo"></v-img>
        </v-btn>
        <span class="beta-label white--text">BETA</span>
      </div>
      <div class="inline-b hidden-sm-and-up">
        <v-btn text to="/dashboard" color="app-blue" class="small-logo pr-1">
          <v-img contain :src="smallLogo"></v-img>
        </v-btn>
        <span class="beta-label white--text">BETA</span>
      </div>
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn text to="/dashboard">
        <v-icon left>mdi-view-dashboard</v-icon>
        {{ $t('common.dashboard') }}
      </v-btn>
      <v-btn text to="/missions">
        <v-icon left>mdi-calendar</v-icon>
        {{ $t('missions.missions') }}
      </v-btn>
      <v-btn text to="/groups">
        <v-icon left>mdi-account-multiple</v-icon>
        {{ $t('groups.groups') }}
      </v-btn>
      <v-menu offset-y transition="slide-x-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark text>
            <v-icon left>mdi-store</v-icon>
            {{ $t('store.store') }}
            <v-icon right small class="ml-1">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="app-dark-gray--text" @click="buyGems()">
            <v-icon left>$vuetify.icons.gem</v-icon>
            {{ $t('store.buyGems') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/avatars">
            <v-icon left>mdi-account-box</v-icon>
            {{ $t('avatars.avatars') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/boards">
            <v-icon left>mdi-image</v-icon>
            {{ $t('boards.boards') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/purchases">
            <v-icon left>mdi-receipt</v-icon>
            {{ $t('purchases.myPurchases') }}
          </v-list-item>
        </v-list>
      </v-menu>
      <notifications-nav></notifications-nav>
      <v-menu offset-y transition="slide-x-transition">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark text class="px-0">
            <v-icon>mdi-account</v-icon>
            <v-icon right small class="ml-0">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="app-dark-gray--text" router to="/settings">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('common.settings') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router @click="logout()">
            <v-icon left>mdi-exit-to-app</v-icon>
            {{ $t('auth.signOut') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-up">
      <notifications-nav></notifications-nav>
      <v-menu offset-y transition="slide-x-transition" tile min-width="280">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark text icon class="px-0">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list class="pt-0">
          <v-divider></v-divider>
          <v-list-item class="app-dark-gray--text" router to="/dashboard">
            <v-icon left>mdi-view-dashboard</v-icon>
            {{ $t('common.dashboard') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/missions">
            <v-icon left>mdi-calendar</v-icon>
            {{ $t('missions.missions') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/groups">
            <v-icon left>mdi-account-multiple</v-icon>
            {{ $t('groups.groups') }}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <span class="font-weight-bold">{{ $t('store.store') }}</span>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="app-dark-gray--text" @click="buyGems()">
            <v-icon left>$vuetify.icons.gem</v-icon>
            {{ $t('store.buyGems') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/avatars">
            <v-icon left>mdi-account-box</v-icon>
            {{ $t('avatars.avatars') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/boards">
            <v-icon left>mdi-image</v-icon>
            {{ $t('boards.boards') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/store/purchases">
            <v-icon left>mdi-receipt</v-icon>
            {{ $t('purchases.myPurchases') }}
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <span class="font-weight-bold">{{ $t('common.account') }}</span>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item class="app-dark-gray--text" router to="/settings">
            <v-icon left>mdi-cog</v-icon>
            {{ $t('common.settings') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router @click="logout()">
            <v-icon left>mdi-exit-to-app</v-icon>
            {{ $t('auth.signOut') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import { TOGGLE_SIDE_NAV } from '../../store/shared/types';
import { LOGOUT } from '../../store/auth/types';
import NotificationsNav from '../notifications/NotificationsNav';
import BuyGemsModal from '../store/BuyGemsModal';

export default {
  name: 'app-nav',
  components: {
    NotificationsNav
  },
  data() {
    return {
      whiteLogo: require('../../assets/images/logos/gamifinnopoly_white.png'),
      smallLogo: require('../../assets/images/logos/gamifinnopoly_small.png')
    };
  },
  methods: {
    buyGems() {
      this.$root.$modal.show(BuyGemsModal);
    },
    toggleSideNav() {
      this.$store.dispatch(TOGGLE_SIDE_NAV);
    },
    logout() {
      this.$store.dispatch(LOGOUT);
    }
  }
};
</script>

<style lang="scss" scoped>
.inline-b {
  display: inline-block;
}
.beta-label {
  font-size: 10px;
  vertical-align: text-top;
}
</style>
