import {
  HAS_ACCOUNT,
  REGISTER,
  GROUP_REGISTER,
  RESEND_NEW_ACTIVATION_EMAIL,
  ACTIVATE_ACCOUNT,
  LOGIN,
  LOGOUT,
  SOCIAL_AUTH,
  AUTHENTICATE,
  SEND_RECOVERY_PASSWORD,
  VERIFY_RECOVERY_PASSWORD_LINK,
  RESET_PASSWORD
} from './types';
import { SET_PROFILE } from '../users/types';
import authService from '../../services/auth.service';
import authSession from '../../services/auth.session.service';
import languages from '../../enums/languages';
import router from '../../router';

function addToLocalStorage(data) {
  authSession.setToken(data.token);
  authSession.setLang(data.user.lang || languages.en);
}

export default {
  async [HAS_ACCOUNT](actions, payload) {
    const { result } = await authService.hasAccount(payload);

    return result;
  },
  async [REGISTER](actions, payload) {
    const { result } = await authService.register(payload);

    return result;
  },
  async [GROUP_REGISTER](actions, payload) {
    const { result } = await authService.groupRegister(payload);

    return result;
  },
  async [RESEND_NEW_ACTIVATION_EMAIL](actions, payload) {
    await authService.resendNewActivationEmail(payload);
  },
  async [ACTIVATE_ACCOUNT](actions, payload) {
    await authService.activateAccount(payload);
  },
  async [LOGIN]({ commit }, payload) {
    const { result } = await authService.login(payload);

    addToLocalStorage(result);
    commit(AUTHENTICATE);
    commit(SET_PROFILE, result.user);

    return result;
  },
  async [SOCIAL_AUTH]({ commit }, payload) {
    const { result } = await authService.socialLogin(payload);

    addToLocalStorage(result);
    commit(AUTHENTICATE);
    commit(SET_PROFILE, result.user);

    return result;
  },
  [SEND_RECOVERY_PASSWORD](actions, payload) {
    return authService.sendRecoveryEmail(payload);
  },
  [VERIFY_RECOVERY_PASSWORD_LINK](actions, payload) {
    return authService.verifyRecoveryLink(payload);
  },
  [RESET_PASSWORD](actions, payload) {
    return authService.resetPassword(payload);
  },
  async [LOGOUT]({ commit }, serverLogout = true) {
    if (serverLogout) {
      await authService.logout();
    }

    authSession.removeToken();
    // window.location.href = '/login';
    commit(LOGOUT);
    router.push('/login');
  }
};
