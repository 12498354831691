<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular pr-3">
        {{ $t('store.buyGems') }}
      </span>
      <v-icon>$vuetify.icons.gem</v-icon>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="pa-3 pb-0">
      <v-card-text>
        <h2 class="text-center app-blue--text font-weight-regular">
          {{ $t('store.whatCanBuy') }}
        </h2>
        <v-row class="mt-5">
          <v-col md="6" sm="6" cols="12" class="mb-2">
            <v-row justify="center" align="center">
              <v-col cols="2">
                <v-icon large color="app-orange">mdi-emoticon-outline</v-icon>
              </v-col>
              <v-col cols="10">
                <span>{{ $t('store.avatarsDesc') }}</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6" sm="6" cols="12">
            <v-row justify="center" align="center">
              <v-col cols="2">
                <v-icon large color="app-orange">mdi-emoticon-outline</v-icon>
              </v-col>
              <v-col cols="10">
                <span>
                  {{ $t('store.boardsDesc') }}
                </span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-row>
          <v-col
            md="4"
            sm="4"
            cols="12"
            v-for="plan of plans"
            :key="plan.gems"
            class="pa-1"
          >
            <v-card
              tile
              :ripple="false"
              class="text-center pt-3 cursor"
              @click="selectPlan(plan)"
            >
              <div
                class="app-blue--text mb-2 d-flex justify-center align-center"
              >
                <v-icon class="pr-2">$vuetify.icons.gem</v-icon>
                <span class="text-h5 app-blue--text">
                  {{ plan.gems | numberFormat }}
                </span>
              </div>
              <span class="subtitle">{{ $t('common.gems') }}</span>
              <v-card-actions class="justify-center mt-2">
                <v-btn
                  v-if="plan.selected"
                  color="app-green"
                  dark
                  depressed
                  rounded
                >
                  <v-icon small class="fas fa-check-circle pr-1"></v-icon>
                  €{{ plan.price | amount }}
                </v-btn>
                <v-btn v-else depressed rounded>
                  <v-icon small class="far fa-circle pr-1"></v-icon>
                  €{{ plan.price | amount }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text class="text-center pb-3">
        <div>
          <span class="text-subtitle-1 pr-3">{{ $t('common.quantity') }}:</span>
          <v-text-field
            class="inline-block"
            type="number"
            :rules="[rules.required, rules.isInteger, rules.min]"
            min="1"
            solo
            height="20"
            v-model="quantity"
            :label="$t('common.quantity')"
            ref="quantityInput"
            :disabled="!hasSelectedPlan"
            @keyup="onQuantityChange"
            @change="onQuantityChange"
          ></v-text-field>
        </div>
        <div v-if="finalGems" class="mb-2 d-flex justify-center align-center">
          <span class="text-h6 pr-2 font-weight-regular">
            {{ $t('common.finalGems') }}:
          </span>
          <v-icon class="pr-1">$vuetify.icons.gem</v-icon>
          <span class="text-h6 font-weight-bold app-blue--text">
            {{ finalGems | numberFormat }}
          </span>
        </div>
        <div v-if="finalPrice">
          <span class="text-h6 pr-1 font-weight-regular">
            {{ $t('common.finalPrice') }}:
          </span>
          <span class="text-h6 font-weight-bold"
            >€{{ finalPrice | amount }}</span
          >
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text class="text-center">
        <h3 class="text-h6 app-blue--text font-weight-regular">
          {{ $t('store.payWith') }}
        </h3>
        <v-row justify="center" class="my-2">
          <v-btn
            color="app-blue"
            :dark="canPay"
            :disabled="!canPay"
            :loading="loading"
            @click="pay()"
          >
            <v-icon left>mdi-credit-card-outline</v-icon>
            {{ $t('store.creditCard') }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import validators from '@/common/validators';
import httpService from '../../services/store/gems.plans.service';
import paymentsService from '../../services/payments/payments.service';

const QUANTITY_MIN_VALUE = 1;

export default {
  name: 'buy-gems-modal',
  computed: {
    hasSelectedPlan() {
      return this.plans.some(p => p.selected);
    },
    canPay() {
      return this.hasSelectedPlan && this.$refs.quantityInput.valid;
    }
  },
  data() {
    return {
      loading: false,
      plans: [],
      quantity: 1,
      finalPrice: null,
      finalGems: null,
      selectedPlan: null,
      rules: {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isInteger: validators.isInteger(this.$i18n.t('common.mustBeInteger')),
        min: validators.minValue(
          QUANTITY_MIN_VALUE,
          this.$i18n.t('common.minValue', { value: QUANTITY_MIN_VALUE })
        )
      }
    };
  },
  async created() {
    const { result } = await httpService.index();

    this.plans = result.map(p => ({ ...p, selected: false }));
  },
  methods: {
    selectPlan(current) {
      if (current.selected) {
        current.selected = false;
        this.reset();
      } else {
        for (const plan of this.plans) {
          plan.selected = false;
        }

        current.selected = true;
        this.selectedPlan = current;

        this.calculateFinalGems();
        this.calculateFinalPrice();
      }
    },
    reset() {
      this.selectedPlan = null;
      this.finalGems = null;
      this.finalPrice = null;
      this.quantity = 1;
    },
    onQuantityChange() {
      if (!this.$refs.quantityInput.valid || !this.selectedPlan) return;

      this.calculateFinalGems();
      this.calculateFinalPrice();
    },
    calculateFinalGems() {
      this.finalGems = this.quantity * this.selectedPlan.gems;
    },
    calculateFinalPrice() {
      const price = this.selectedPlan.promoPrice || this.selectedPlan.price;
      this.finalPrice = this.quantity * price;
    },
    async pay() {
      this.loading = true;

      const { result } = await paymentsService.createCheckoutSession({
        planId: this.selectedPlan.id,
        quantity: this.quantity
      });

      if (!result) {
        this.loading = false;
        return;
      }

      const route = this.$router.resolve({
        name: 'stripe-payment',
        query: { sessionId: result }
      });

      this.$emit('close');

      window.open(route.href, '_blank');
    }
  }
};
</script>
