export default {
  rewards: {
    rewards: 'Rewards',
    reward: 'Reward',
    redeem: 'Redeem',
    createReward: 'Create reward',
    editReward: 'Edit reward',
    deleteRewardConfirm: 'Are you sure you want to delete this reward?',
    usabilityTitle: 'Usability',
    constant: 'Constantly',
    once: 'Once',
    times: 'Several times',
    howManyTimes: 'How many times?',
    redeemRewardConfirm: 'Are you sure you want to redeem this reward?',
    noRewardsFound: 'No rewards found!',
    redeemedReward: 'You successfully redeemed your reward for {gold} gold!',
    redeemedGroupReward:
      'You successfully redeemed your reward for {ruby} ruby!',
    groupRewardWasSentForApproval: 'Reward was sent for approval!',
    waitingApproval: 'Waiting approval',
    approvalRewards: 'Approval rewards',
    noRewardsWaitingApprovalFound: 'No rewards waiting for approval found!',
    аpproveAllRewards: 'All rewards were approved!',
    refuseToApproveRewards: 'All rewards were refused!',
    rewardApproved: 'You approved the reward!',
    rewardRefused: 'You did not approve the reward!',
    reopenReward: 'Reopen reward',
    generateRewards: 'Generate rewards',
    generateRewardsCostText:
      'Just give us a topic and number of rewards and we will generate them for you. Each new rewards generation will cost you ',
    topic: 'Topic (e.g. Holiday)',
    rewardsCountQuestion: 'How many rewards to generate?',
    addRewards: 'Add rewards'
  }
};
