function sortBy(items, prop) {
  return [...items].sort((a, b) => b[prop] - a[prop]);
}

function sortByGold(items) {
  return sortBy(items, 'gold');
}

function sortByRuby(items) {
  return sortBy(items, 'ruby');
}

export default {
  sortBy,
  sortByGold,
  sortByRuby
};
