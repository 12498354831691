import {
  GET_CURRENT_BOARD,
  SET_CURRENT_BOARD,
  GET_SPECIAL_BOARD_POSITION
} from './types';
import { UPDATE_PROFILE } from '../users/types';
import userBoardsHttpService from '../../services/user.boards.http.service';
import boardPositionTypes from '../../enums/board.position.types';

export default {
  async [GET_CURRENT_BOARD]({ commit }) {
    const { result } = await userBoardsHttpService.getUserBoard();

    commit(SET_CURRENT_BOARD, result);
  },
  async [GET_SPECIAL_BOARD_POSITION]({ commit }, type) {
    const { result } = await userBoardsHttpService.getSpecialPosition();

    if (type === boardPositionTypes.bonus) {
      commit(UPDATE_PROFILE, result.cardData);
    }

    return result;
  }
};
