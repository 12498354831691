export default {
  members: {
    members: 'Members',
    roles: {
      creator: 'Creator',
      leader: 'Leader',
      member: 'Member'
    },
    emailAlreadyAdded: 'Email already added!',
    memberAlreadyInGroup: 'Member already in group',
    inviteToGroup: 'Invite to group',
    inviteGroupDescription:
      'If you invite members who do not have a Gamifinnopoly account we will send them invitation via email, otherwise we will send them notification in Gamifinnopoly.',
    addMember: 'Add member',
    invitationSentSuccessfully: 'Invitation/s sent successfully!',
    acceptInvitation: 'Accept invitation',
    successfullyJoinedGroup: 'You have joined the group successfully!',
    stats: {
      failure: {
        ruby: 'You lost {ruby} ruby in "{groupName}"'
      }
    },
    deleteMemberConfirm:
      'Are you sure you want to delete this member from this group?',
    promoteToLeader: 'Promote to Leader',
    promoteToLeaderConfirm: 'Are you sure you want to promote this member?',
    lowerToMember: 'Lower to Member',
    lowerToMemberConfirm: 'Are you sure you want to lower this member?',
    verifyGroupEmailInvitation:
      'We verify your email invitation! Please wait...',
    cannotAddMoreThan5MembersToGroup: `For the BETA version of Gamifinnopoly you won't be able to add more than 5 members in a group!`,
    youCanAddMembers: 'You can add {count} more member/s to the group!',
    cannotAddMoreMembers: 'Cannot add more members to the group!',
    sendGems: 'Send Gems',
    youCanSend: 'You can send maximum',
    successfullySentGems: 'You sent Gems successfully!',
    viewDashboard: 'View dashboard',
    inviteMembers: 'Invite members'
  }
};
