<template>
  <v-dialog v-model="isVisible" v-bind="modalProps">
    <component
      :is="component"
      v-bind="componentProps"
      @close="closeModal"
    ></component>
  </v-dialog>
</template>

<script>
export default {
  name: 'app-modal',
  props: {
    id: {
      type: String,
      required: true
    },
    component: {
      type: Object,
      required: true
    },
    componentProps: {
      type: Object,
      default: () => {}
    },
    modalProps: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.isVisible = this.visible;
    },
    closeModal(data) {
      this.close(this.id, data);
    }
  }
};
</script>
