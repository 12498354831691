<template>
  <div>
    <app-modal
      v-for="(modal, index) of modals"
      :key="index"
      :id="modal.id"
      :component="modal.component"
      :componentProps="modal.componentProps"
      :modalProps="modal.modalProps"
      :visible="modal.visible"
      :close="modal.close"
    ></app-modal>
  </div>
</template>

<script>
import AppModal from './AppModal';

const DEFAULT_MODAL_PROPS = {
  width: '600px',
  persistent: true
};

export default {
  name: 'app-modals-container',
  components: {
    AppModal
  },
  data() {
    return {
      modals: []
    };
  },
  methods: {
    getModalId() {
      return `_${Math.random().toString(36).substr(2, 9)}`;
    },
    show(component, componentProps = {}, modalProps = {}) {
      const self = this;

      const promise = new Promise(resolve => {
        const modal = {
          id: this.getModalId(),
          component,
          componentProps,
          modalProps: { ...DEFAULT_MODAL_PROPS, ...modalProps },
          visible: self.modals.length === 0,
          close(id, data) {
            resolve(data);
            self.removeModal(id);
          }
        };

        self.modals.push(modal);
      });

      return promise;
    },
    removeModal(id) {
      const index = this.modals.findIndex(m => m.id === id);

      if (index !== -1) {
        this.modals.splice(index, 1);
      }

      if (this.modals.length > 0) {
        this.modals[0].visible = true;
      }
    }
  }
};
</script>
