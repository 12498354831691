function getRandomColorByName(name, saturation = 50, lightness = 75) {
  let hash = 0;

  for (var i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  const h = hash % 360;

  return 'hsl(' + h + ', ' + saturation + '%, ' + lightness + '%)';
}

function getInitials(name) {
  const names = name.split(' ');

  if (names.length > 1) {
    const [first, last] = names;

    return `${first[0].toUpperCase()}${last[0].toUpperCase()}`;
  }

  return names[0].toUpperCase();
}

function generate(name) {
  return {
    initials: getInitials(name),
    color: getRandomColorByName(name)
  };
}

export default {
  getRandomColorByName,
  getInitials,
  generate
};
