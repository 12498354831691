import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import users from './users';
import tasks from './tasks';
import rewards from './rewards';
import tags from './tags';
import missions from './missions';
import boards from './boards';
import questions from './questions';
import groups from './groups';
import notifications from './notifications';
import groupMembers from './group-members';
import dashboard from './dashboard';
import cron from './cron';
import shared from './shared';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    auth,
    users,
    tasks,
    rewards,
    tags,
    missions,
    boards,
    questions,
    groups,
    notifications,
    groupMembers,
    dashboard,
    cron,
    shared
  },
  strict: debug
});
