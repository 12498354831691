import missionsActions from './actions/index';
import tasksActions from './tasks/actions/index';
import mutations from './mutations';
import getters from './getters';
import taskTypes from '../../enums/task.types';

const state = {
  missions: [],
  approvalMissions: [],
  currentMission: {
    tasks: []
  },
  missionTasksTypesFilter: [
    { type: taskTypes.todo, selected: false },
    { type: taskTypes.daily, selected: false },
    { type: taskTypes.weekly, selected: false }
  ]
};

export default {
  state,
  actions: {
    ...missionsActions,
    ...tasksActions
  },
  mutations,
  getters
};
