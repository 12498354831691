import {
  SET_MISSION_ARCHIVED_TASKS,
  ADD_MISSION_TASK,
  ADD_MISSION_MULTIPLE_TASKS,
  UPDATE_MISSION_TASK,
  UPDATE_MISSION_TASKS_TYPES_FILTER,
  RESET_MISSION_TASKS_TYPES_FILTER,
  DELETE_MISSION_TASK
} from './types';
import taskTypes from '../../../enums/task.types';
import listsHelper from '../../../common/lists.helper';
import numbersHelper from '../../../common/numbers.helper';

function updateCurrentMision(currentMission) {
  if (currentMission.group || currentMission.assignedUsers) {
    currentMission.tasks = listsHelper.sortByRuby(currentMission.tasks);

    currentMission.ruby = numbersHelper.sumNumbersInArr(
      currentMission.tasks.map(task => parseInt(task.ruby))
    );
  } else {
    currentMission.tasks = listsHelper.sortByGold(currentMission.tasks);

    currentMission.gold = numbersHelper.sumNumbersInArr(
      currentMission.tasks.map(task => parseInt(task.gold))
    );
  }
}

export default {
  [SET_MISSION_ARCHIVED_TASKS](state, tasks) {
    state.currentMission.tasks = [
      ...state.currentMission.tasks.filter(t => !t.archived),
      ...tasks
    ];

    updateCurrentMision(state.currentMission);
  },
  [ADD_MISSION_TASK](state, task) {
    state.currentMission.tasks.push(task);

    updateCurrentMision(state.currentMission);
  },
  [ADD_MISSION_MULTIPLE_TASKS](state, tasks) {
    state.currentMission.tasks = [...state.currentMission.tasks, ...tasks];

    updateCurrentMision(state.currentMission);
  },
  [UPDATE_MISSION_TASK](state, task) {
    const index = state.currentMission.tasks.findIndex(t => t.id === task.id);

    if (index !== -1) {
      state.currentMission.tasks.splice(index, 1, {
        ...state.currentMission.tasks[index],
        ...task
      });
    }

    updateCurrentMision(state.currentMission);
  },
  [UPDATE_MISSION_TASKS_TYPES_FILTER](state, filter) {
    const index = state.missionTasksTypesFilter.findIndex(
      f => f.type === filter
    );

    state.missionTasksTypesFilter.splice(index, 1, {
      type: filter,
      selected: !state.missionTasksTypesFilter[index].selected
    });
  },
  [RESET_MISSION_TASKS_TYPES_FILTER](state) {
    state.missionTasksTypesFilter = [
      { type: taskTypes.todo, selected: false },
      { type: taskTypes.daily, selected: false },
      { type: taskTypes.weekly, selected: false }
    ];
  },
  [DELETE_MISSION_TASK](state, id) {
    const index = state.currentMission.tasks.findIndex(task => task.id === id);

    if (index !== -1) {
      state.currentMission.tasks.splice(index, 1);
    }

    updateCurrentMision(state.currentMission);
  }
};
