import taskTypes from '../../enums/task.types';

export default {
  tasks(state) {
    return state.tasks;
  },
  todos(state) {
    return state.tasks.filter(task => task.type === taskTypes.todo);
  },
  dailies(state) {
    return state.tasks.filter(task => task.type === taskTypes.daily);
  },
  weeklies(state) {
    return state.tasks.filter(task => task.type === taskTypes.weekly);
  },
  archivedTasks(state) {
    return state.archivedTasks;
  },
  approvalTasks(state) {
    return state.approvalTasks;
  },
  tasksTypesFilter(state) {
    return state.tasksTypesFilter;
  }
};
