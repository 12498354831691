<template>
  <v-menu
    offset-y
    transition="slide-x-transition"
    min-width="360"
    v-model="toggled"
    tile
    max-height="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" dark text class="no-min-width">
        <v-badge overlap color="app-red" v-model="hasNotifications">
          <template v-slot:badge>
            {{ notificationsCount }}
          </template>
          <v-icon dark> mdi-bell </v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-toolbar color="white">
      <h3>{{ $t('notifications.notifications') }}</h3>
      <v-spacer></v-spacer>
      <a
        v-if="notificationsCount > 0"
        href="javascript:;"
        class="router-link"
        @click="markAsSeen"
      >
        {{ $t('notifications.markAsSeen') }}
      </a>
    </v-toolbar>
    <div v-if="loading">
      <v-card class="text-center py-3">
        <v-progress-circular
          indeterminate
          color="app-blue"
        ></v-progress-circular>
      </v-card>
    </div>
    <div v-else>
      <div v-if="notificationsCount > 0">
        <v-card
          class="cursor"
          width="360"
          v-for="notification in mappedNotifications"
          :key="notification.id"
          @click="onNotificationClick(notification)"
          tile
        >
          <v-container class="pa-2">
            <group-invitation-notification
              v-if="notification.type === notificationTypes.groupInvitation"
              :notification="notification"
            ></group-invitation-notification>
            <base-notification
              v-else
              :baseNotification="notification"
            ></base-notification>
          </v-container>
        </v-card>
      </div>
      <div v-else>
        <v-card tile>
          <v-card-text class="text-center">
            <v-avatar>
              <v-img contain :src="notificationImg" />
            </v-avatar>
            <h3 class="pt-2 font-weight-regular">
              {{ $t('notifications.noNotifications') }}
            </h3>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-card class="text-center py-2" tile>
      <router-link
        :to="'/notifications'"
        class="text-subtitle-1 app-blue--text router-link"
      >
        {{ $t('notifications.seeOldNotifications') }}
      </router-link>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from 'vuex';
import notificationTypes from '../../enums/notification.types';
import GroupInvitationNotification from './types/GroupInvitationNotification';
import BaseNotification from './types/BaseNotification';
import {
  GET_NOTIFICATIONS,
  MARK_ALL_NOTIFICATIONS_AS_SEEN
} from '../../store/notifications/types';
import notificationsMapper from '../../mappers/notifications.mapper';
import notificationsModalsMixin from '../../mixins/notifications.modals.mixin';

export default {
  name: 'notifications-nav',
  components: {
    GroupInvitationNotification,
    BaseNotification
  },
  computed: {
    ...mapGetters(['notifications', 'notificationsCount']),
    notSeenNotifications() {
      return this.notifications.filter(n => !n.seen);
    },
    notificationTypes() {
      return notificationTypes;
    },
    notificationImg() {
      return require('../../assets/images/notification.svg');
    },
    mappedNotifications() {
      return this.notifications.map(n =>
        notificationsMapper.transformToNotificationDisplayData(n)
      );
    },
    hasNotifications() {
      return this.notificationsCount > 0;
    }
  },
  data() {
    return {
      toggled: false,
      loading: false
    };
  },
  mixins: [notificationsModalsMixin],
  watch: {
    toggled(newValue) {
      if (newValue) {
        this.loadNotifications();
      }
    }
  },
  methods: {
    async loadNotifications() {
      this.loading = true;
      await this.$store.dispatch(GET_NOTIFICATIONS, { seen: false });
      this.loading = false;
    },
    markAsSeen() {
      const notificationIds = this.notSeenNotifications.map(n => n.id);

      if (notificationIds.length === 0) return;

      this.$store.dispatch(MARK_ALL_NOTIFICATIONS_AS_SEEN, notificationIds);
    }
  }
};
</script>
