import rewardsTypes from '../enums/reward.types';

function transformToGroupRewardUpdateReqBody(reward) {
  const result = {
    name: reward.name,
    requireApprove: reward.requireApprove,
    ruby: reward.ruby,
    type: reward.type
  };

  if (reward.type === rewardsTypes.times) {
    result.timesToUse = reward.timesToUse;
  }

  if (reward.assignedUsers) {
    result.assignedUsers = reward.assignedUsers;
  }

  return result;
}

function transformToGeneratedRewards(rewards) {
  return rewards.map(reward => ({
    ...reward,
    selected: true
  }));
}

function transformToRewardReqBody(reward) {
  return {
    name: reward.name,
    gold: reward.gold,
    type: reward.type
  };
}

function transformToMultipeRewardsReqBody(rewards) {
  return rewards.map(reward => transformToRewardReqBody(reward));
}

export default {
  transformToGroupRewardUpdateReqBody,
  transformToGeneratedRewards,
  transformToMultipeRewardsReqBody
};
