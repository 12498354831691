import {
  GET_GROUPS,
  SET_GROUPS,
  GET_CURRENT_USER_GROUPS_STATS,
  ADD_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  GET_CURRENT_GROUP,
  SET_CURRENT_GROUP,
  UPDATE_CURRENT_GROUP
} from './types';
import { SET_CURRENT_MEMBER } from '../group-members/types';
import groupsHttpService from '../../services/groups.http.service';

export default {
  async [GET_GROUPS]({ commit }, params) {
    const { result } = await groupsHttpService.index(params);

    commit(SET_GROUPS, result);
  },
  async [GET_CURRENT_USER_GROUPS_STATS]() {
    const { result } = await groupsHttpService.getCurrentUserGroupsStats();

    return result;
  },
  async [GET_CURRENT_GROUP]({ commit }, id) {
    const { result } = await groupsHttpService.get(id);

    commit(SET_CURRENT_GROUP, result);
    commit(SET_CURRENT_MEMBER, result.currentMember);
  },
  async [ADD_GROUP]({ commit }, payload) {
    const { result } = await groupsHttpService.create(payload);

    commit(ADD_GROUP, result);
  },
  async [UPDATE_GROUP]({ commit }, payload) {
    const { id, ...mission } = payload;

    await groupsHttpService.update(id, mission);

    commit(UPDATE_GROUP, payload);
  },
  async [UPDATE_CURRENT_GROUP]({ commit }, payload) {
    const { id, ...group } = payload;

    await groupsHttpService.update(id, group);

    commit(UPDATE_CURRENT_GROUP, payload);
  },
  async [DELETE_GROUP]({ commit }, payload) {
    await groupsHttpService.del(payload);

    commit(DELETE_GROUP, payload);
  }
};
