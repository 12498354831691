import httpService from './http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, userId, rewardId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/users/${userId}/rewards`;

  if (rewardId) {
    url = `${url}/${rewardId}`;
  }

  return url;
}

function getCurrentUserUrl(groupId, rewardId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/rewards`;

  if (rewardId) {
    url = `${url}/${rewardId}`;
  }

  return url;
}

function index(groupId, userId, params = {}) {
  return httpService.get(getUrl(groupId, userId), params);
}

function create(groupId, userId, payload) {
  return httpService.post(getUrl(groupId, userId), payload);
}

function update(groupId, userId, rewardId, payload) {
  return httpService.put(getUrl(groupId, userId, rewardId), payload);
}

function redeem(groupId, rewardId) {
  return httpService.post(`${getCurrentUserUrl(groupId, rewardId)}/redeem`);
}

function approve(groupId, userId, rewardId, payload) {
  return httpService.post(
    `${getUrl(groupId, userId, rewardId)}/approve`,
    payload
  );
}

function del(groupId, userId, rewardId) {
  return httpService.del(getUrl(groupId, userId, rewardId));
}

export default {
  index,
  create,
  update,
  redeem,
  approve,
  del
};
