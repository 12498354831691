import actions from './actions/index';
import mutations from './mutations';
import getters from './getters';
import taskTypes from '../../enums/task.types';

const state = {
  tasks: [],
  archivedTasks: [],
  approvalTasks: [],
  tasksTypesFilter: [
    { type: taskTypes.todo, selected: false },
    { type: taskTypes.daily, selected: false },
    { type: taskTypes.weekly, selected: false }
  ]
};

export default {
  state,
  actions,
  mutations,
  getters
};
