import common from './bg/common';
import users from './bg/users';
import groups from './bg/groups';
import members from './bg/group.members';
import termsAndPrivacy from './bg/terms.and.privacy';
import avatars from './bg/avatars';
import boards from './bg/boards';
import store from './bg/store';
import notifications from './bg/notifications';
import purchases from './bg/purchases';
import missions from './bg/missions';
import tasks from './bg/tasks';
import rewards from './bg/rewards';
import auth from './bg/auth';
import errors from './bg/errors';

export default {
  ...common,
  about: {
    moto: 'Всяка завършена задача е стъпка напред',
    poweredByAI: 'Иновация с изкуствен интелект',
    shortDescription: {
      firstPart:
        'Забелязвате ли как в днешно време става все по-трудно да управлявате ежедневните си задачи, да постигате всичките си цели и да изграждате здравословни и полезни навици?',
      secondPart:
        'Искате ли да го промените и да станете част от нашия чисто нов свят, пълен със свръхчовеци, наречени Гамифинатори?',
      thirdPart:
        'Те са умни, креативни, съпричастни, вдъхновяващи, изследователи и иноватори, които обичат да се забавляват, докато постигат целите си.',
      fourthPart: `Регистрирайте се напълно безплатно на новия ни игровизиран софтуер за организиране на задачи, Gamifinnopoly, и се насладете на предимствата на това да бъдете Гамифинатор. 
        Нека всички заедно направим света едно по-вълнуващо и креативно място.`,
      alreadyPossible: 'Това вече е възможно!'
    },
    howDoesItWork: {
      title: 'Как работи?',
      intro: `Gamifinnopoly е един от нашите магически инструменти, които използваме за да превърнем страхотни хора като вас в свръхчовеци, наречени Гамифинатори. 
        Всичко това е възможно, благодарение на нашето тайно оръжие, наречено Игровизация.`,
      gamificationDesc:
        'Игровизацията (Gamification) използва елементи, методи и техники от игрите в неигрови ситуации, за да ги направи по-забавни и ангажиращи.',
      boardGameStyle: {
        title: 'В стил на настолна игра',
        text: `Gamifinnopoly е направен в стил на настолна игра, където се движите през
          безкраен „борд“. Когато стъпите на специалните полета, можете да получавате изненади като
          случайни награди, интересни факти или предизвикателства.`
      },
      missionsAndTasks: {
        title: 'Мисии и задачи',
        text: 'За да постигнете целите си, можете да създавате и изпълнявате различни интересни мисии и задачи.'
      },
      rewards: {
        title: 'Награди',
        text: 'За да направите всичко това още по-ангажиращо, можете да дефинирате и да получавате точки, които след това да разменяте за награди.'
      },
      groups: {
        title: 'Групи',
        text: `А искате ли и да ангажирате и вашите деца и приятели? Можете да създавате групи, в които вие
          задавате задачите и наградите, а вашите деца или приятели ги изпълняват. Всяка задача носи
          рубини, които след това могат да се използват, за да се обменят в награди, ако вие позволите,
          разбира се. По този начин например, можете по иновативен начин да научите детето си на някои
          основни организационни навици и да следите резултатите му, като едновременно с това се
          забавлявате и награждавате неговите постижения.
        `
      },
      achieveMore: {
        title: 'Искаш да постигнеш повече и да се забавляваш докато го правиш?',
        text: `Регистрирай се напълно безплатно и бъди един истински Гамифинатор!`
      }
    }
  },
  partners: {
    title: 'Партньори'
  },
  footer: {
    text: 'Gamifinnopoly е продукт и собственост на',
    companyName: 'Гамифино ООД'
  },
  languages: {
    en: 'English',
    bg: 'Български'
  },
  ...auth,
  ...users,
  rankings: {
    gamifinnator: 'Гамифиннатор',
    newbie: 'Новак',
    kid: 'Хлапе',
    junior: 'Младши',
    experienced: 'Опитен',
    senior: 'Старши',
    expert: 'Експерт',
    master: 'Мастър'
  },
  ...missions,
  ...tasks,
  ...rewards,
  tags: {
    tags: 'Тагове',
    work: 'Работа',
    health: 'Здраве',
    sport: 'Спорт',
    holiday: 'Почивка',
    school: 'Училище',
    fun: 'Забавление',
    travel: 'Пътуване'
  },
  supportUs: {
    text: 'Допринесете да растем заедно'
  },
  feedback: {
    title: 'Ние ценим вашето мнение!',
    btn: 'Остави мнение'
  },
  facts: {
    pickFact: 'Изтегли факт',
    didYouKnow: 'Знаете ли, че',
    learnMore: 'Научи повече'
  },
  specialBonuses: {
    pickSpecialBonus: 'Изтегли специален бонус',
    youWon: 'Вие спечелихте!',
    useItHere: 'Използвайте го тук'
  },
  gamTasks: {
    pickTask: 'Изтегли задача',
    letsDoIt: 'Да го направим',
    notInterested: 'Не се интересувам'
  },
  questions: {
    pickQuestion: 'Изтегли въпрос',
    submitAnswer: 'Отговори',
    correctAnswer: 'Верен отговор!',
    wrongAnswer: 'Грешен отговор!'
  },
  quotes: {
    pickQuote: 'Изтегли цитат',
    unknown: 'Неизвестен'
  },
  ...groups,
  ...members,
  ...notifications,
  ...avatars,
  ...boards,
  ...store,
  ...termsAndPrivacy,
  ...purchases,
  ...errors
};
