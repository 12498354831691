<template>
  <v-menu offset-y transition="slide-x-transition">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" dark text v-bind:class="{ 'pr-0': mobile }">
        <v-img
          max-width="16"
          min-width="16"
          :src="selectedLanguage.img"
          class="mr-2"
        ></v-img>
        <span class="app-dark-gray--text" v-if="!mobile">
          {{ $t(`languages.${selectedLanguage.name}`) }}
        </span>
        <v-icon right small class="ml-0 app-dark-gray--text">
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        class="app-dark-gray--text"
        v-for="language of languagesList"
        :key="language.name"
        @click="selectLanguage(language)"
      >
        <v-img
          max-width="16"
          min-width="16"
          :src="language.img"
          class="mr-2"
        ></v-img>
        {{ $t(`languages.${language.name}`) }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import languages from '../../enums/languages';
import langHelper from '../../common/lang.helper';

export default {
  name: 'languages-switch',
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languagesList: [
        {
          name: languages.bg,
          img: require('../../assets/images/languages/bulgaria.png')
        },
        {
          name: languages.en,
          img: require('../../assets/images/languages/united-states.png')
        }
      ],
      selectedLanguage: 'en'
    };
  },
  created() {
    const currentLang = langHelper.getLanguage();

    if (!langHelper.isLanguageSaved()) {
      langHelper.saveLanguage(currentLang);
    }

    const lang = this.languagesList.find(l => l.name === currentLang);
    this.selectedLanguage = lang;
  },
  methods: {
    selectLanguage(lang) {
      this.selectedLanguage = lang;
      this.$i18n.locale = lang.name;
      langHelper.saveLanguage(lang.name);
    }
  }
};
</script>
