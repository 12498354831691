import { ANSWER_QUESTION } from './types';
import { UPDATE_PROFILE } from '../users/types';
import questionsService from '../../services/questions.service';

export default {
  async [ANSWER_QUESTION]({ commit }, payload) {
    const { id, ...answer } = payload;
    const { result } = await questionsService.answerQuestion(id, answer);

    if (result.userStats) {
      commit(UPDATE_PROFILE, result.userStats);
    }

    return result;
  }
};
