<template>
  <div class="how-does-it-works">
    <div id="board-game-style" class="bg-white box-shadow">
      <v-container class="pa-6">
        <h1
          class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-4 mt-3"
        >
          {{ $t('about.howDoesItWork.title') }}
        </h1>
        <p class="subtitle text-center">
          {{ $t('about.howDoesItWork.intro') }}
        </p>
        <p class="subtitle text-center">
          {{ $t('about.howDoesItWork.gamificationDesc') }}
        </p>
      </v-container>
      <v-container fluid class="pa-6">
        <v-row>
          <v-col sm="12">
            <h1
              class="grey--text text--darken-3 text-h5 text-center font-weight-bold"
            >
              {{ $t('about.howDoesItWork.boardGameStyle.title') }}
            </h1>
            <p class="text-subtitle-1 text-center pa-3">
              {{ $t('about.howDoesItWork.boardGameStyle.text') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.board"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="missions-and-tasks">
      <v-container fluid class="pa-6">
        <v-row>
          <v-col sm="12">
            <h1
              class="grey--text text--darken-3 text-h5 text-center font-weight-bold mt-3"
            >
              {{ $t('about.howDoesItWork.missionsAndTasks.title') }}
            </h1>
            <p class="subtitle text-center pa-3">
              {{ $t('about.howDoesItWork.missionsAndTasks.text') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.missionsAndTasks"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="rewards" class="bg-white box-shadow">
      <v-container fluid class="pa-6">
        <v-row>
          <v-col sm="12">
            <h1
              class="grey--text text--darken-3 text-h5 text-center font-weight-bold mt-3"
            >
              {{ $t('about.howDoesItWork.rewards.title') }}
            </h1>
            <p class="subtitle text-center pa-3">
              {{ $t('about.howDoesItWork.rewards.text') }}
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="12" class="justify-center d-sm-flex">
            <v-img
              max-height="420"
              max-width="400"
              contain
              class="img-shadow border-radius-10"
              :src="images.rewards"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="groups">
      <v-container class="pa-6">
        <h1
          class="grey--text text--darken-3 text-h5 text-center font-weight-bold my-3"
        >
          {{ $t('about.howDoesItWork.groups.title') }}
        </h1>
        <p class="subtitle text-center mb-5">
          {{ $t('about.howDoesItWork.groups.text') }}
        </p>
        <v-row class="mb-3">
          <v-col sm="12">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.groupDetails"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="achieve-more" class="bg-white box-shadow mt-4 py-4">
      <v-row>
        <v-col sm="12">
          <h1
            class="grey--text text--darken-3 text-h5 text-center font-weight-bold mb-3 px-3"
          >
            {{ $t('about.howDoesItWork.achieveMore.title') }}
          </h1>
          <p class="subtitle text-center pa-3">
            {{ $t('about.howDoesItWork.achieveMore.text') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12" class="text-center">
          <v-btn
            color="app-blue"
            dark
            class="subtitle get-started-btn"
            to="/sign-up"
          >
            {{ $t('common.getStarted') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'how-does-it-work-small-size',
  data() {
    return {
      images: {
        board: require('../../assets/images/public/board.png'),
        missionsAndTasks: require('../../assets/images/public/missions-and-tasks.png'),
        rewards: require('../../assets/images/public/rewards.png'),
        groupDetails: require('../../assets/images/public/group-details.png')
      }
    };
  }
};
</script>
