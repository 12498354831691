import {
  SET_REWARDS,
  SET_APPROVAL_REWARDS,
  ADD_REWARD,
  ADD_MULTIPLE_REWARDS,
  UPDATE_REWARD,
  DELETE_REWARD,
  APPROVE_GROUP_REWARD
} from './types';

export default {
  [SET_REWARDS](state, rewards) {
    state.rewards = rewards;
  },
  [SET_APPROVAL_REWARDS](state, rewards) {
    state.approvalRewards = rewards;
  },
  [ADD_REWARD](state, reward) {
    state.rewards.push(reward);
  },
  [ADD_MULTIPLE_REWARDS](state, rewards) {
    state.rewards = [...state.rewards, ...rewards];
  },
  [UPDATE_REWARD](state, reward) {
    const index = state.rewards.findIndex(r => r.id === reward.id);

    if (index !== -1) {
      state.rewards.splice(index, 1, {
        ...state.rewards[index],
        ...reward
      });
    }
  },
  [APPROVE_GROUP_REWARD](state, { rewardId, groupRewardId }) {
    const approvalIndex = state.approvalRewards.findIndex(
      reward => reward.id === rewardId
    );
    const reward = state.rewards.find(gt => gt.id === groupRewardId);

    if (approvalIndex !== -1) {
      state.approvalRewards.splice(approvalIndex, 1);
    }

    if (reward) {
      reward.waitingApproval = reward.waitingApproval - 1;
    }
  },
  [DELETE_REWARD](state, id) {
    const index = state.rewards.findIndex(reward => reward.id === id);

    if (index !== -1) {
      state.rewards.splice(index, 1);
    }
  }
};
