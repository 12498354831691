export default {
  notifications: {
    notifications: 'Известия',
    markAsSeen: 'Отбележи като видяни',
    noNotifications: 'Няма непрочетени известия!',
    seeOldNotifications: 'Виж старите известия',
    notificationsLast30days: 'Известия за последните 30 дни',
    notificationDeletedSuccessfully: 'Известието е изтрито успешно!',
    deleteNotificationConfirm:
      'Сигурни ли сте, че искате да изтриете това известие?',
    noNotificationsFound: 'Няма намерени известия',
    groupInvitation: {
      title: 'Покана за група',
      text: '<strong>{senderName}</strong> ви кани да се присъедините към "<strong>{itemName}</strong>"'
    },
    acceptedGroupInvitaion: {
      title: 'Приета покана',
      text: '<strong>{senderName}</strong> прие вашата покана да се включи в групата'
    },
    newTaskAssigned: {
      title: 'Нова задача',
      text: '<strong>{senderName}</strong> ви добави нова задача <strong>{itemName}</strong>'
    },
    askTaskApprove: {
      title: 'Одобрение на задача',
      text: '<strong>{senderName}</strong> ви изпраща <strong>{itemName}</strong> за одобрение'
    },
    taskApprove: {
      approved: {
        title: 'Одобрена задача',
        text: '<strong>{senderName}</strong> одобри <strong>{taskTitle}</strong>'
      },
      disApproved: {
        title: 'Неодобрена задача',
        text: '<strong>{senderName}</strong> не одобрява <strong>{taskTitle}</strong>'
      }
    },
    newRewardAdded: {
      title: 'Нова награда',
      text: '<strong>{senderName}</strong> ви добави нова награда <strong>{itemName}</strong>'
    },
    newMissionAssigned: {
      title: 'Нова мисия',
      text: '<strong>{senderName}</strong> ви добави нова мисия <strong>{itemName}</strong>'
    },
    askMissionApprove: {
      title: 'Одобрение на мисия',
      text: '<strong>{senderName}</strong> ви изпраща <strong>{itemName}</strong> за одобрение'
    },
    missionApprove: {
      approved: {
        title: 'Одобрена мисия',
        text: '<strong>{senderName}</strong> одобри <strong>{missionName}</strong>'
      },
      disApproved: {
        title: 'Неодобрена мисия',
        text: '<strong>{senderName}</strong> не одобрява <strong>{missionName}</strong>'
      },
      disapprovedExpiredMission: {
        text: '<strong>{senderName}</strong> не одобрява <strong>{missionName}</strong>. Тъй като мисията е изтекла, тя ще бъде маркирана като провалена! Поради тази причина вие губите <strong>{ruby} рубита</strong>!'
      }
    },
    removeMember: {
      title: 'Премахнат от група',
      text: '<strong>{senderName}</strong> ви премахна от <strong>{itemName}</strong>'
    },
    promoteToLeader: {
      title: 'Повишен до {role}',
      text: '<strong>{senderName}</strong> ви повиши до <strong>{role}</strong> в <strong>{itemName}</strong>'
    },
    lowerToMember: {
      title: 'Понижен до {role}',
      text: '<strong>{senderName}</strong> ви понижи до <strong>{role}</strong> в <strong>{itemName}</strong>'
    },
    requestRewardApproval: {
      title: 'Одобрение на награда',
      text: '<strong>{senderName}</strong> ви изпраща <strong>{itemName}</strong> за одобрение'
    },
    rewardApprove: {
      approved: {
        title: 'Одобрена награда',
        text: '<strong>{senderName}</strong> одобри <strong>{rewardName}</strong>. Вие грабнахте наградата за <strong>{ruby}</strong> рубита!'
      },
      disApproved: {
        title: 'Неодобрена награда',
        text: '<strong>{senderName}</strong> отказа да одобри <strong>{rewardName}</strong>'
      }
    },
    sendGems: {
      title: 'Получихте Gems',
      text: '<strong>{senderName}</strong> ви изпрати <strong>{gems}</strong> Gems'
    }
  }
};
