export default {
  users: {
    editProfile: 'Edit profile',
    changePassword: 'Change password',
    changeAvatar: 'Change avatar',
    level: 'Level',
    stats: {
      success: {
        gold: 'You gained {value} gold!',
        exp: 'You gained {value} experience!',
        level: 'Level Up!',
        levelReached: 'You reached {value} level!',
        ranking: 'You reached new ranking!',
        missionBonusGold:
          'You gained {value} bonus gold for completing the mission!',
        missionBonusRuby:
          'You gained {value} bonus ruby for completing the mission!',
        ruby: 'You gained {value} ruby!'
      },
      failure: {
        gold: 'You lost {value} gold!',
        exp: 'You lost {value} experience!',
        level: 'Level down!',
        ranking: 'You lowered your ranking!',
        ruby: 'You lost {value} ruby!',
        tasksAndMissions: {
          gold: 'For not completing your Tasks and Missions you lost {value} gold!'
        }
      }
    },
    changeProfileImage: 'Change avatar',
    urlToProfileImage: 'Url to image',
    holidayMode: 'Holiday mode',
    holidayModeInfo: `When you enable the holiday mode you will not be able to complete your tasks and missions and lose gold or rubies if you haven't completed them.`,
    successfullyEnabledHolidayMode: 'Holiday mode enabled successfully!',
    successfullyDisabledHolidayMode: 'Holiday mode disabled successfully!',
    generateProfileImage: 'Generate avatar',
    generateProfileImageCostText:
      'Just tell us what kind of avatar you want and we will generate it for you. Each new generation will cost you ',
    generateProfileImagePrompt:
      'What kind of image you want? Example: A sweet baby puppy'
  }
};
