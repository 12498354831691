import {
  SET_MISSIONS,
  SET_APPROVAL_MISSIONS,
  ADD_MISSION,
  UPDATE_MISSION,
  DELETE_MISSION,
  SET_MISSION_AS_MAIN,
  SET_CURRENT_MISSION,
  APPROVE_GROUP_MISSION,
  UPDATE_CURRENT_MISSION
} from './types';
import tasksMutations from './tasks/mutations';
import listsHelper from '../../common/lists.helper';

function setCurrentMission(state, mission) {
  state.currentMission = mission;

  if (!mission) return;

  if (mission.assignedUsers) {
    state.currentMission.tasks = listsHelper.sortByRuby(
      state.currentMission.tasks || []
    );
  } else {
    state.currentMission.tasks = listsHelper.sortByGold(
      state.currentMission.tasks || []
    );
  }
}

export default {
  [SET_MISSIONS](state, missions) {
    state.missions = listsHelper.sortByGold(missions);
  },
  [SET_CURRENT_MISSION](state, mission) {
    setCurrentMission(state, mission);
  },
  [SET_APPROVAL_MISSIONS](state, missions) {
    state.approvalMissions = missions;
  },
  [ADD_MISSION](state, mission) {
    state.missions.push(mission);
    state.missions = listsHelper.sortByGold(state.missions);
  },
  [UPDATE_MISSION](state, mission) {
    const index = state.missions.findIndex(m => m.id === mission.id);

    if (index !== -1) {
      state.missions.splice(index, 1, {
        ...state.missions[index],
        ...mission
      });
    }

    state.missions = listsHelper.sortByGold(state.missions);
  },
  [APPROVE_GROUP_MISSION](state, { missionId, groupMissionId }) {
    const approvalIndex = state.approvalMissions.findIndex(
      mission => mission.id === missionId
    );
    const mission = state.missions.find(m => m.id === groupMissionId);

    if (approvalIndex !== -1) {
      state.approvalMissions.splice(approvalIndex, 1);
    }

    if (mission) {
      mission.waitingApproval = mission.waitingApproval - 1;
    }
  },
  [UPDATE_CURRENT_MISSION](state, mission) {
    state.currentMission = {
      ...state.currentMission,
      ...mission
    };
  },
  [SET_MISSION_AS_MAIN](state, mission) {
    for (const m of state.missions) {
      if (m.id !== mission.id) {
        m.isMain = false;
      } else {
        m.isMain = true;
      }
    }

    setCurrentMission(state, mission);
  },
  [DELETE_MISSION](state, id) {
    const index = state.missions.findIndex(mission => mission.id === id);

    if (index !== -1) {
      state.missions.splice(index, 1);
    }
  },
  ...tasksMutations
};
