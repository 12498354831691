export default {
  boards: {
    boards: 'Boards',
    areYouSureToBuyThisBoard: 'Are you sure you want to buy this board?',
    notEnoughGemsToBuyBoard: `You don't have enough gems to buy this board!`,
    boardBoughtSuccessfully: 'You successfully bought this board!',
    noBoardsFound: 'No boards found!',
    doYouWantToChangeYourCurrentBoardWithNewOne:
      'Do you want to change your current board with your new one?',
    myBoards: 'My boards',
    buyMoreBoards: 'Buy more boards',
    successBoardChange: 'You successfully changed your board!',
    useBoard: 'Use board'
  }
};
