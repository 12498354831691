import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './translations';
import vuetify from './plugins/vuetify';
import '@fortawesome/fontawesome-free/css/all.css';
import './plugins/progress.bar';
import './plugins/notifications';
import './plugins/fragment';
import './registerServiceWorker';
import './filters';

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
