import httpService from '../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, taskId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/tasks`;

  if (taskId) {
    url = `${url}/${taskId}`;
  }

  return url;
}

function index(groupId, params = {}) {
  return httpService.get(getUrl(groupId), params);
}

function getApprovalTasks(groupId, groupTaskId) {
  return httpService.get(`${getUrl(groupId)}/${groupTaskId}/approvals`);
}

function create(groupId, payload) {
  return httpService.post(getUrl(groupId), payload);
}

function update(groupId, taskId, payload) {
  return httpService.put(getUrl(groupId, taskId), payload);
}

function approveAllTasks(groupId, groupTaskId, payload) {
  return httpService.post(`${getUrl(groupId)}/${groupTaskId}/approve`, payload);
}

function archive(groupId, taskId) {
  return httpService.patch(`${getUrl(groupId, taskId)}/archive`);
}

function copy(groupId, taskId, payload) {
  return httpService.patch(`${getUrl(groupId, taskId)}/copy`, payload);
}

function del(groupId, taskId) {
  return httpService.del(getUrl(groupId, taskId));
}

export default {
  index,
  getApprovalTasks,
  create,
  update,
  approveAllTasks,
  archive,
  copy,
  del
};
