export const SET_MISSIONS = 'SET_MISSIONS';
export const GET_MISSIONS = 'GET_MISSIONS';
export const ADD_MISSION = 'ADD_MISSION';
export const ADD_MISSION_DASHBOARD = 'ADD_MISSION_DASHBOARD';
export const COPY_MISSION = 'COPY_MISSION';
export const UPDATE_MISSION = 'UPDATE_MISSION';
export const COMPLETE_MISSION = 'COMPLETE_MISSION';
export const DELETE_MISSION = 'DELETE_MISSION';

export const SET_CURRENT_MISSION = 'SET_CURRENT_MISSION';
export const GET_CURRENT_MISSION = 'GET_CURRENT_MISSION';
export const UPDATE_CURRENT_MISSION = 'UPDATE_CURRENT_MISSION';
export const SET_MISSION_AS_MAIN = 'SET_MISSION_AS_MAIN';

// Group missions types
export const GET_GROUP_MISSIONS = 'GET_GROUP_MISSIONS';
export const GET_APPROVAL_MISSIONS = 'GET_APPROVAL_MISSIONS';
export const SET_APPROVAL_MISSIONS = 'SET_APPROVAL_MISSIONS';
export const COMPLETE_GROUP_MISSION = 'COMPLETE_GROUP_MISSION';
export const APPROVE_ALL_MISSIONS = 'APPROVE_ALL_MISSIONS';
export const ADD_GROUP_MISSION = 'ADD_GROUP_MISSION';
export const COPY_GROUP_MISSION = 'COPY_GROUP_MISSION';
export const UPDATE_GROUP_MISSION = 'UPDATE_GROUP_MISSION';
export const APPROVE_GROUP_MISSION = 'APPROVE_GROUP_MISSION';
export const ARCHIVE_GROUP_MISSION = 'ARCHIVE_GROUP_MISSION';
export const REOPEN_GROUP_MISSION = 'REOPEN_GROUP_MISSION';
export const DELETE_GROUP_MISSION = 'DELETE_GROUP_MISSION';

export const GET_CURRENT_GROUP_MISSION = 'GET_CURRENT_GROUP_MISSION';
export const UPDATE_CURRENT_GROUP_MISSION = 'UPDATE_CURRENT_GROUP_MISSION';
