export const HAS_ACCOUNT = 'HAS_ACCOUNT';
export const REGISTER = 'REGISTER';
export const GROUP_REGISTER = 'GROUP_REGISTER';
export const RESEND_NEW_ACTIVATION_EMAIL = 'RESEND_NEW_ACTIVATION_EMAIL';
export const ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SOCIAL_AUTH = 'SOCIAL_AUTH';
export const AUTHENTICATE = 'AUTHENTICATE';
export const SEND_RECOVERY_PASSWORD = 'SEND_RECOVERY_PASSWORD';
export const VERIFY_RECOVERY_PASSWORD_LINK = 'VERIFY_RECOVERY_PASSWORD_LINK';
export const RESET_PASSWORD = 'RESET_PASSWORD';
