export default {
  auth: {
    signUp: 'Sign up',
    signUpForFree: 'Sign Up For Free',
    successfulRegistrationTitle: 'Successfully registration!',
    successfulRegistrationText:
      'We have sent you an email to {email}. Please, click the link in the message to activate your account.',
    resendEmailForActivationText: `If you haven't received an email for account activation, please click the button below and we will send you a new one.`,
    resendEmailForActivationBtn: 'Send me a new activation email',
    newActivationEmailSent: 'We have sent you new activation email!',
    accountSuccessfullyActivated:
      'You have successfully activated your account!',
    createAccount: 'Create account',
    login: 'Login',
    signOut: 'Sign out',
    google: 'Google',
    login_google: 'Login with Google',
    signup_google: 'Sign up with Google',
    dontHaveAnAccount: "Don't you have an account?",
    youHaveAnAccount: 'You have an account?',
    gamLogin: 'Login with email',
    gamRegister: 'Sign up with email',
    forgotPassword: 'Forgot password?',
    passwordRecovery: 'Password recovery',
    passwordRecoveryText:
      'Do not remember your account password and having trouble logging into Gamifinnopoly? Enter your email address and we will send you a password recovery link.',
    recoveryEmailSent:
      'You can check your email address for password recovery link!',
    sendRecoveryEmail: 'Send recovery email',
    expiredRecoveryLink: 'The password recovery link has expired!',
    requestNewRecoveryLink: 'Request new one',
    resetPassword: 'Reset password',
    passwordResetSuccessfully: 'Password reset successfully!',
    completeGroupRegisterText: `To join the group you must create Gamifinnopoly account. 
      Please, fill the fields below and begin to achieve more, have fun and enjoy the benefits of being a Gamifinnator!`
  }
};
