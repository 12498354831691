import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  currentUser: {
    stats: {},
    board: {}
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
