import { SET_PROFILE, UPDATE_PROFILE, UPDATE_USER_STATS } from './types';
import authSession from '../../services/auth.session.service';

export default {
  [SET_PROFILE](state, payload) {
    state.currentUser = payload;
    authSession.setLang(state.currentUser.lang);
  },
  [UPDATE_PROFILE](state, payload) {
    let updatedUser = {};
    const { stats, board, ...rest } = payload;

    if (rest) {
      updatedUser = {
        ...state.currentUser,
        ...rest
      };
    }

    if (stats) {
      updatedUser.stats = {
        ...state.currentUser.stats,
        ...stats
      };
    }

    if (board) {
      updatedUser.board = {
        ...state.currentUser.board,
        ...board
      };
    }

    authSession.setLang(updatedUser.lang);
    state.currentUser = updatedUser;
  },
  [UPDATE_USER_STATS](state, payload) {
    state.currentUser.stats = {
      ...state.currentUser.stats,
      ...payload
    };
  }
};
