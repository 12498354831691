export default {
  store: {
    store: 'Маркет',
    buyFor: 'Вземи за',
    buyForFree: 'Вземи безплатно',
    buyGems: 'Купи Gems',
    whatCanBuy: 'Какво може да закупите с Gems',
    avatarsDesc: 'Страхотни и модерни Аватари за вашия профил',
    boardsDesc: 'Невероятни бордове с интересни факти, цитати и др.',
    payWith: 'Плати с',
    buyNow: 'Купи сега',
    avatarsStore: 'Аватари',
    allAvatarCategories: 'Всички категории',
    boardsStore: 'Бордове',
    creditCard: 'Кредитна/Дебитна карта',
    redirectingToStripe: 'Пренасочване към Stripe...',
    errorWithStripe: 'Възникна грешка със Stripe',
    thankYouForSupport: 'Благодарим Ви, за подкрепата!',
    gemsPurchased: `Вие успешно закупихте <strong class="app-green--text">{value}</strong> Gems!`
  }
};
