import httpService from '../../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, userId, taskId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/users/${userId}/tasks`;

  if (taskId) {
    url = `${url}/${taskId}`;
  }

  return url;
}

function getCurrentUserUrl(groupId, taskId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/tasks`;

  if (taskId) {
    url = `${url}/${taskId}`;
  }

  return url;
}

function index(groupId, userId, params = {}) {
  return httpService.get(getUrl(groupId, userId), params);
}

function complete(groupId, taskId, payload) {
  return httpService.patch(
    `${getCurrentUserUrl(groupId, taskId)}/complete`,
    payload
  );
}

function reopen(groupId, taskId) {
  return httpService.patch(`${getCurrentUserUrl(groupId, taskId)}/reopen`);
}

function addResult(groupId, taskId, payload) {
  return httpService.post(
    `${getCurrentUserUrl(groupId, taskId)}/result`,
    payload
  );
}

function approve(groupId, userId, taskId, payload) {
  return httpService.post(
    `${getUrl(groupId, userId, taskId)}/approve`,
    payload
  );
}

function del(groupId, userId, taskId) {
  return httpService.del(getUrl(groupId, userId, taskId));
}

export default {
  index,
  complete,
  reopen,
  addResult,
  approve,
  del
};
