import {
  SET_TASKS,
  GET_TASKS,
  GET_ARCHIVED_TASKS,
  SET_ARCHIVED_TASKS,
  ADD_TASK,
  ADD_ARCHIVED_TASK,
  UPDATE_TASK,
  COMPLETE_TASK,
  REOPEN_TASK,
  REOPEN_CRON_TASKS,
  ADD_RESULT,
  COPY_TASK,
  ARCHIVE_TASK,
  DELETE_TASK,
  DELETE_ARCHIVED_TASK,
  CHANGE_TASKS_TYPES_FILTER,
  UPDATE_TASKS_TYPES_FILTER,
  RESET_TASKS_TYPES_FILTER
} from '../types';
import { UPDATE_PROFILE } from '../../users/types';
import tasksService from '../../../services/tasks.service';
import tasksMapper from '../../../mappers/tasks.mapper';
import tasksHelper from '../../../common/tasks.helper';

async function loadTasks(params) {
  const { result } = await tasksService.index(params);
  return result;
}

function getSelectedFilterTypes(filter) {
  return filter.filter(f => f.selected).map(f => f.type);
}

export default {
  async [GET_TASKS]({ commit }, params) {
    const result = await loadTasks(params);

    commit(SET_TASKS, result);
  },
  async [GET_ARCHIVED_TASKS]({ commit }) {
    const result = await loadTasks({ archived: true });

    commit(SET_ARCHIVED_TASKS, result);
  },
  async [ADD_TASK]({ commit, rootState }, payload) {
    const { result } = await tasksService.create(
      tasksMapper.transformToTaskReqBody(payload)
    );

    payload.tags = tasksHelper.mapTaskTags(rootState.tags.tags, payload.tags);

    commit(ADD_TASK, { id: result, ...payload });
  },
  async [UPDATE_TASK]({ commit, rootState }, task) {
    const { id, ...taskData } = task;

    await tasksService.update(id, tasksMapper.transformToTaskReqBody(taskData));

    commit(UPDATE_TASK, {
      ...task,
      tags: tasksHelper.mapTaskTags(rootState.tags.tags, task.tags)
    });
  },
  async [COMPLETE_TASK]({ commit }, task) {
    const payload = {};

    if (task.requireResult && task.result) {
      payload.result = task.result;
    }

    const { result } = await tasksService.completeTask(task.id, payload);

    commit(UPDATE_TASK, { ...task, completed: true });
    commit(UPDATE_PROFILE, { stats: result.stats, board: result.board });

    return result;
  },
  async [REOPEN_TASK]({ commit }, task) {
    const { result } = await tasksService.reopenTask(task.id);

    commit(UPDATE_TASK, { ...task, completed: false });
    commit(UPDATE_PROFILE, { stats: result.stats });

    return result;
  },
  [REOPEN_CRON_TASKS]({ commit }, tasks) {
    commit(REOPEN_CRON_TASKS, tasks);
  },
  async [ADD_RESULT]({ commit }, task) {
    await tasksService.addResult(task.id, { result: task.result });

    commit(UPDATE_TASK, { ...task });
  },
  async [ARCHIVE_TASK]({ commit }, task) {
    await tasksService.archiveTask(task.id);

    commit(ADD_ARCHIVED_TASK, { ...task, archived: true });
    commit(DELETE_TASK, task.id);
  },
  async [COPY_TASK]({ commit, state }, id) {
    const { result } = await tasksService.copyTask(id);
    const selectedTypes = getSelectedFilterTypes(state.tasksTypesFilter);

    if (
      selectedTypes.length === 0 ||
      selectedTypes.indexOf(result.type) !== -1
    ) {
      commit(ADD_TASK, result);
    } else {
      const tasks = await loadTasks({
        type: [...selectedTypes, result.type],
        archived: false
      });

      commit(UPDATE_TASKS_TYPES_FILTER, result.type);
      commit(SET_TASKS, tasks);
    }
  },
  async [CHANGE_TASKS_TYPES_FILTER]({ commit, state }, taskType) {
    const selectedTypes = getSelectedFilterTypes(state.tasksTypesFilter);

    if (selectedTypes.length > 0 && selectedTypes.indexOf(taskType) === -1) {
      const tasks = await loadTasks({
        type: [...selectedTypes, taskType],
        archived: false
      });

      commit(UPDATE_TASKS_TYPES_FILTER, taskType);
      commit(SET_TASKS, tasks);
    }
  },
  [UPDATE_TASKS_TYPES_FILTER]({ commit }, filterType) {
    commit(UPDATE_TASKS_TYPES_FILTER, filterType);
  },
  [RESET_TASKS_TYPES_FILTER]({ commit }) {
    commit(RESET_TASKS_TYPES_FILTER);
  },
  async [DELETE_TASK]({ commit }, task) {
    await tasksService.del(task.id);

    if (task.archived) {
      commit(DELETE_ARCHIVED_TASK, task.id);
    } else {
      commit(DELETE_TASK, task.id);
    }
  }
};
