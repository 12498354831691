<template>
  <v-app
    id="app"
    v-bind:class="{
      'app-container': isAuthenticated,
      'app-container-public': !isAuthenticated
    }"
  >
    <notifications classes="pa-3 app-notifications" />
    <app-modals-container
      id="modals-container"
      ref="modal"
    ></app-modals-container>
    <div v-if="isAuthenticated">
      <side-nav></side-nav>
      <app-nav></app-nav>
      <v-main>
        <router-view />
      </v-main>
    </div>
    <div v-else>
      <public-nav></public-nav>
      <v-main>
        <router-view />
      </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import SideNav from './components/shared/SideNav';
import AppNav from './components/shared/AppNav';
import PublicNav from './components/shared/PublicNav';
import AppModalsContainer from './components/shared/AppModalsContainer';
import NewVersionModal from './components/shared/NewVersionModal';

export default {
  components: {
    SideNav,
    AppNav,
    PublicNav,
    AppModalsContainer
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  },
  mounted() {
    this.$root.$modal = this.$refs.modal;

    document.addEventListener('swUpdated', this.showNewVersionModal, {
      once: true
    });
  },
  beforeDestroy() {
    document.removeEventListener('swUpdated');
  },
  methods: {
    showNewVersionModal(e) {
      if (!this.isAuthenticated) return;

      this.$root.$modal.show(
        NewVersionModal,
        {
          registration: e.detail
        },
        {
          width: 360,
          persistent: false
        }
      );
    }
  }
};
</script>

<style lang="scss">
@import './assets/styles/index.scss';
</style>
