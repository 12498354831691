<template>
  <fragment>
    <v-icon class="mr-2 height-20 gold-icon"> $vuetify.icons.gold </v-icon>
    <span
      class="text-subtitle-1 font-weight-medium text-no-wrap"
      :class="textColor"
    >
      {{ gold | numberFormat }}
    </span>
  </fragment>
</template>

<script>
export default {
  name: 'gold-label',
  props: {
    gold: {
      required: true
    },
    textColor: {
      type: String,
      default: 'app-green--text'
    }
  }
};
</script>
