import httpService from './http.service';

function index(missionId, params = {}) {
  return httpService.get(`/user/missions/${missionId}/tasks`, params);
}

function create(missionId, payload) {
  return httpService.post(`/user/missions/${missionId}/tasks`, payload);
}

function generateTasks(missionId, payload) {
  return httpService.post(
    `/user/missions/${missionId}/generate-tasks`,
    payload
  );
}

function createMultiple(missionId, payload) {
  return httpService.post(
    `/user/missions/${missionId}/multiple-tasks`,
    payload
  );
}

function update(missionId, taskId, payload) {
  return httpService.put(
    `/user/missions/${missionId}/tasks/${taskId}`,
    payload
  );
}

function completeTask(missionId, taskId) {
  return httpService.patch(
    `/user/missions/${missionId}/tasks/${taskId}/complete`
  );
}

function copyTask(missionId, taskId) {
  return httpService.post(`/user/missions/${missionId}/tasks/${taskId}/copy`);
}

function archiveTask(missionId, taskId) {
  return httpService.patch(
    `/user/missions/${missionId}/tasks/${taskId}/archive`
  );
}

function reopenTask(missionId, taskId) {
  return httpService.patch(
    `/user/missions/${missionId}/tasks/${taskId}/reopen`
  );
}

function del(missionId, taskId) {
  return httpService.del(`/user/missions/${missionId}/tasks/${taskId}`);
}

export default {
  index,
  create,
  generateTasks,
  createMultiple,
  update,
  completeTask,
  reopenTask,
  archiveTask,
  copyTask,
  del
};
