<template>
  <div class="small">
    <moto-small-size class="my-5"></moto-small-size>
    <short-description-small-size></short-description-small-size>
    <how-does-it-work-small-size></how-does-it-work-small-size>
    <partners-small-size></partners-small-size>
  </div>
</template>

<script>
import MotoSmallSize from '../../components/home/MotoSmallSize';
import ShortDescriptionSmallSize from '../../components/home/ShortDescriptionSmallSize';
import HowDoesItWorkSmallSize from '../../components/home/HowDoesItWorkSmallSize';
import PartnersSmallSize from '../../components/home/PartnersSmallSize';

export default {
  name: 'home-small-screen-size',
  components: {
    MotoSmallSize,
    ShortDescriptionSmallSize,
    HowDoesItWorkSmallSize,
    PartnersSmallSize
  }
};
</script>
