import httpService from '../../http.service';

const BASE_GROUPS_URL = '/groups';

function getUrl(groupId, userId, missionId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/users/${userId}/missions`;

  if (missionId) {
    url = `${url}/${missionId}`;
  }

  return url;
}

function getCurrentUserUrl(groupId, missionId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/missions`;

  if (missionId) {
    url = `${url}/${missionId}`;
  }

  return url;
}

function index(groupId, userId, params = {}) {
  return httpService.get(getUrl(groupId, userId), params);
}

function get(groupId, userId, missionId) {
  return httpService.get(getUrl(groupId, userId, missionId));
}

function complete(groupId, missionId) {
  return httpService.post(`${getCurrentUserUrl(groupId, missionId)}/complete`);
}

function approve(groupId, userId, missionId, payload) {
  return httpService.post(
    `${getUrl(groupId, userId, missionId)}/approve`,
    payload
  );
}

export default {
  index,
  get,
  complete,
  approve
};
