export default {
  tasks: {
    tasks: 'Tasks',
    createTask: 'Create task',
    editTask: 'Edit task',
    edit: {
      todo: 'Edit To Do',
      daily: 'Edit Daily',
      weekly: 'Edit Weekly'
    },
    reopen: {
      todo: 'Reopen To Do',
      daily: 'Reopen Daily',
      weekly: 'Reopen Weekly'
    },
    info: {
      title: 'Task info',
      todo: 'Info To Do',
      daily: 'Info Daily',
      weekly: 'Info Weekly'
    },
    deleteTaskConfirm: 'Are you sure you want to delete this task?',
    maxTagsLength: 'Maximum {number} tags!',
    type: 'Type',
    todo: 'To do',
    daily: 'Daily',
    weekly: 'Weekly',
    archived: 'Archived',
    noTasksFound: 'No tasks found!',
    taskArchived: 'The task is archived!',
    taskCloned: 'The task is cloned!',
    waitingForApprove: 'Waiting for approval',
    reopenApprovedGroupTaskConfirm:
      'Are you sure you want to reopen already approved task?',
    reopenApproveRequestedGroupTaskConfirm:
      'Are you sure you want to reopen already requested for approval task?',
    requireResultWhenComplete: 'Require result when complete',
    addResult: 'Result',
    resultOfTheTask: 'Result of the task',
    resultOfTheTaskSaved: 'You successfully saved the result of the task!',
    editResult: 'Edit result',
    approvalTasks: 'Approval tasks',
    noTasksWaitingApprovalFound: 'No tasks waiting for approval found!',
    аpproveAllTasks: 'All tasks were approved!',
    refuseToApproveTasks: 'All tasks were refused!',
    taskApproved: 'You approved the task!',
    taskRefused: 'You did not approve the task!',
    generateTasks: 'Generate tasks',
    generateTasksCostText:
      'Just give us a number of tasks and we will generate them for you. Each new tasks generation will cost you ',
    tasksCountQuestion: 'How many tasks to generate?',
    addTasksToMission: 'Add tasks to mission'
  }
};
