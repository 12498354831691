import {
  SET_NOTIFICATIONS,
  SET_OLD_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNT,
  SET_OLD_NOTIFICATIONS_COUNT,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
  DELETE_NOTIFICATION,
  DELETE_OLD_NOTIFICATION
} from './types';

export default {
  [SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },
  [SET_NOTIFICATIONS_COUNT](state, count) {
    state.notificationsCount = count;
  },
  [SET_OLD_NOTIFICATIONS](state, oldNotifications) {
    state.oldNotifications = oldNotifications;
  },
  [SET_OLD_NOTIFICATIONS_COUNT](state, count) {
    state.oldNotificationsCount = count;
  },
  [MARK_ALL_NOTIFICATIONS_AS_SEEN](state) {
    state.notifications = [];
  },
  [DELETE_NOTIFICATION](state, id) {
    const index = state.notifications.findIndex(
      notification => notification.id === id
    );

    if (index !== -1) {
      state.notifications.splice(index, 1);
    }
  },
  [DELETE_OLD_NOTIFICATION](state, id) {
    const index = state.oldNotifications.findIndex(
      notification => notification.id === id
    );

    if (index !== -1) {
      state.oldNotifications.splice(index, 1);
    }
  }
};
