<template>
  <v-app-bar color="grey lighten-5" class="box-shadow public-nav">
    <v-toolbar-title>
      <div class="hidden-xs-only">
        <v-btn text to="/" color="grey lighten-5" class="logo ml-2 mr-1">
          <v-img contain :src="blueLogo"></v-img>
        </v-btn>
        <span class="beta-label app-blue--text">BETA</span>
      </div>
      <div class="hidden-sm-and-up">
        <v-btn
          text
          to="/"
          color="grey lighten-5"
          class="small-logo mr-1"
          height="50"
        >
          <v-img contain :src="smallLogo"></v-img>
        </v-btn>
        <span class="beta-label app-blue--text">BETA</span>
      </div>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items class="hidden-sm-and-down">
      <languages-switch></languages-switch>
      <v-btn color="app-blue" text to="/">
        {{ $t('common.home') }}
      </v-btn>
      <v-btn color="app-blue" text to="/login">
        {{ $t('auth.login') }}
      </v-btn>
      <v-btn color="app-blue" text to="/sign-up">
        {{ $t('auth.signUp') }}
      </v-btn>
    </v-toolbar-items>
    <v-toolbar-items class="hidden-md-and-up">
      <languages-switch :mobile="true"></languages-switch>
      <v-menu offset-y transition="slide-x-transition" min-width="200">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" color="app-blue" text icon class="px-0">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item class="app-dark-gray--text" router to="/">
            {{ $t('common.home') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/login">
            {{ $t('auth.login') }}
          </v-list-item>
          <v-list-item class="app-dark-gray--text" router to="/sign-up">
            {{ $t('auth.signUp') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import LanguagesSwitch from './LanguagesSwitch';

export default {
  name: 'public-nav',
  components: {
    LanguagesSwitch
  },
  data() {
    return {
      blueLogo: require('../../assets/images/logos/gamifinnopoly_blue.png'),
      smallLogo: require('../../assets/images/logos/gamifinnopoly_small.png')
    };
  }
};
</script>

<style lang="scss" scoped>
.beta-label {
  font-size: 10px;
  vertical-align: text-top;
}
</style>
