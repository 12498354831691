<template>
  <base-notification-modal
    :baseNotification="notification"
    :scope="this"
    :markAsSeen="false"
  >
    <template v-slot:modal-actions>
      <v-btn
        color="app-blue"
        block
        text
        @click="acceptInvitation"
        :loading="loading"
      >
        {{ $t('members.acceptInvitation') }}
      </v-btn>
    </template>
  </base-notification-modal>
</template>

<script>
import BaseNotificationModal from './BaseNotificationModal';
import { ACCEPT_GROUP_INVITATION } from '../../../store/group-members/types';

export default {
  name: 'group-invitation-notificaiton-modal',
  components: {
    BaseNotificationModal
  },
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async acceptInvitation() {
      this.loading = true;

      await this.$store.dispatch(ACCEPT_GROUP_INVITATION, this.notification.id);

      this.loading = false;
      this.$emit('close');
      this.$notify({
        type: 'success',
        text: this.$i18n.t('members.successfullyJoinedGroup')
      });
    }
  }
};
</script>
