import store from '../store';
import { GET_CURRENT_GROUP } from '../store/groups/types';
import { GET_TAGS } from '../store/tags/types';

async function groupDetailsResolver(to, from, next) {
  await Promise.all([
    store.dispatch(GET_CURRENT_GROUP, to.params.id),
    store.dispatch(GET_TAGS)
  ]);
  next();
}

export default {
  groupDetailsResolver
};
