import {
  GET_CURRENT_USER_DASHBOARD,
  GET_GROUP_DASHBOARD,
  GET_GROUP_MEMBER_DASHBOARD
} from './types';
import { SET_TASKS } from '../tasks/types';
import { SET_REWARDS } from '../rewards/types';
import { SET_MISSIONS, SET_CURRENT_MISSION } from '../missions/types';
import { SET_TAGS } from '../tags/types';
import { SET_CURRENT_BOARD } from '../boards/types';
import dashboardService from '../../services/dashboard.service';

export default {
  async [GET_CURRENT_USER_DASHBOARD]({ commit }) {
    const { result } = await dashboardService.getCurrentUserDashboard();

    const currentMission = result.missions.find(m => m.isMain) || {};

    commit(SET_TASKS, result.tasks);
    commit(SET_REWARDS, result.rewards);
    commit(SET_MISSIONS, result.missions);
    commit(SET_TAGS, result.tags);
    commit(SET_CURRENT_BOARD, result.board);
    commit(SET_CURRENT_MISSION, currentMission);
  },
  async [GET_GROUP_DASHBOARD]({ commit }, id) {
    const { result } = await dashboardService.getGroupDashboard(id);

    const currentMission = result.missions[0];

    commit(SET_TASKS, result.tasks);
    commit(SET_REWARDS, result.rewards);
    commit(SET_MISSIONS, result.missions);
    commit(SET_CURRENT_MISSION, currentMission);
    // commit(SET_TAGS, result.tags);
  },
  async [GET_GROUP_MEMBER_DASHBOARD]({ commit }, { groupId, userId }) {
    const { result } = await dashboardService.getDashboardForGroupMember(
      groupId,
      userId
    );

    const currentMission = result.missions[0];

    commit(SET_TASKS, result.tasks);
    commit(SET_REWARDS, result.rewards);
    commit(SET_MISSIONS, result.missions);
    commit(SET_CURRENT_MISSION, currentMission);
    commit(SET_TAGS, result.tags);
  }
};
