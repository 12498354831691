<template>
  <div class="short-description mb-6">
    <div class="desc app-blue">
      <v-container class="text-center pa-6">
        <p class="text-h6 white--text">
          {{ $t('about.shortDescription.firstPart') }}
        </p>
        <p class="text-h6 white--text">
          {{ $t('about.shortDescription.secondPart') }}
        </p>
        <p class="text-h6 white--text">
          {{ $t('about.shortDescription.thirdPart') }}
        </p>
        <p class="text-h6 white--text">
          <strong>{{ $t('about.shortDescription.alreadyPossible') }}</strong>
          {{ $t('about.shortDescription.fourthPart') }}
        </p>
      </v-container>
    </div>
    <div class="dashboard-image">
      <v-container>
        <v-img contain class="border-radius-10" :src="images.dashboard"></v-img>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'short-description-medium-size',
  data() {
    return {
      images: {
        dashboard: require('../../assets/images/public/dashboard.png')
      }
    };
  }
};
</script>
