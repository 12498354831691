<template>
  <v-card class="pt-4 text-center no-border" tile>
    <user-avatar
      :user="currentUser"
      :size="120"
      textSizeClass="text-h4"
    ></user-avatar>
    <v-card-text>
      <h3 class="text-h5 font-weight-light pb-1">{{ currentUser.name }}</h3>
      <div class="centered-content">
        <v-img width="24" height="24" :src="medalImg"></v-img>
        <span
          v-if="currentUser.stats.ranking"
          class="pl-1 text-subtitle-1 app-blue--text"
        >
          {{ $t(`rankings.${currentUser.stats.ranking}`) }}
          {{ $t('rankings.gamifinnator') }}
        </span>
      </div>
      <div class="mt-1">
        <v-chip color="app-green" text-color="white" class="pl-2">
          <gold-label
            :gold="currentUser.stats.gold"
            textColor="white---text"
          ></gold-label>
        </v-chip>
      </div>
      <div class="mt-2">
        <v-chip
          color="app-blue"
          text-color="white"
          class="pl-2 gems-label"
          @click="buyGems()"
        >
          <gems-label
            :gems="currentUser.stats.gems"
            textColor="white---text"
          ></gems-label>
        </v-chip>
      </div>
      <v-row class="mt-2">
        <v-col cols="6" class="text-left pb-0">
          <div class="centered-content">
            <v-img width="23" height="23" :src="trophyImg"></v-img>
            <span class="text-subtitle-1 pl-1">
              {{ $t('users.level') }} {{ currentUser.stats.level }}
            </span>
          </div>
        </v-col>
        <v-col cols="6" class="text-right pb-0">
          <span class="text-subtitle-1">
            {{ currentUser.stats.exp }} / {{ currentUser.stats.nextLevelExp }}
          </span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12">
          <v-progress-linear
            class="my-0"
            :value="percentages"
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_PROFILE } from '../../store/users/types';
import GoldLabel from '../shared/GoldLabel';
import GemsLabel from '../shared/GemsLabel';
import BuyGemsModal from '../store/BuyGemsModal';
import AvatarsModal from '../avatars/AvatarsModal';
import UserAvatar from '../shared/UserAvatar';

export default {
  name: 'user-profile-card',
  components: {
    GoldLabel,
    GemsLabel,
    UserAvatar
  },
  computed: {
    ...mapGetters(['currentUser']),
    percentages() {
      const { stats } = this.currentUser;

      return Math.floor((stats.exp / stats.nextLevelExp) * 100);
    }
  },
  data() {
    return {
      medalImg: require('../../assets/images/medal.svg'),
      trophyImg: require('../../assets/images/trophy.svg')
    };
  },
  async created() {
    if (!this.currentUser.id) {
      await this.$store.dispatch(GET_PROFILE);
    }
  },
  methods: {
    open() {
      this.$root.$modal.show(
        AvatarsModal,
        {
          title: this.$i18n.t('users.changeAvatar'),
          buyMore: true
        },
        {
          width: '800px'
        }
      );
    },
    buyGems() {
      this.$root.$modal.show(BuyGemsModal);
    }
  }
};
</script>
