import {
  GET_PROFILE,
  SET_PROFILE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  UPDATE_USER_STATS,
  UPDATE_USER_AVATAR,
  UPDATE_USER_BOARD,
  CHANGE_HOLIDAY_MODE,
  GENERATE_AVATAR,
  SET_GENERATED_AVATAR
} from './types';
import { SET_CURRENT_BOARD } from '../boards/types';
import usersService from '../../services/users.service';
import usersMapper from '../../mappers/users.mapper';
import dateTimeManager from '../../common/date.time.manager';

export default {
  async [GET_PROFILE]({ commit }) {
    const { result } = await usersService.getProfile();

    commit(SET_PROFILE, result);
  },
  async [UPDATE_PROFILE]({ commit }, payload) {
    await usersService.updateProfile(
      usersMapper.transformUpdateUserReqBody(payload)
    );

    commit(UPDATE_PROFILE, payload);
  },
  async [CHANGE_PASSWORD](actions, payload) {
    await usersService.changePassword(payload);
  },
  [UPDATE_USER_STATS]({ commit }, payload) {
    commit(UPDATE_USER_STATS, payload);
  },
  async [UPDATE_USER_AVATAR]({ commit }, avatar) {
    await usersService.changeAvatar(avatar.id);

    commit(UPDATE_PROFILE, {
      avatar: { id: avatar.id },
      imageUrl: avatar.imagePath,
      generatedAvatarUrl: null
    });
  },
  async [UPDATE_USER_BOARD]({ commit }, boardId) {
    const { result } = await usersService.changeBoard(boardId);

    commit(SET_CURRENT_BOARD, result);
    commit(UPDATE_PROFILE, { board: { id: boardId, position: 0 } });
  },
  async [CHANGE_HOLIDAY_MODE]({ commit }, mode) {
    await usersService.changeHolidayMode();

    const udpateData = { holidayMode: mode };

    if (!mode) {
      const currentDate = dateTimeManager.toICODateString(new Date());

      udpateData.lastDayStart = currentDate;
    }

    commit(UPDATE_PROFILE, udpateData);
  },
  async [GENERATE_AVATAR]({ commit }, payload) {
    const { result } = await usersService.generateAvatar(payload);

    commit(UPDATE_PROFILE, { stats: result.stats });

    return result.avatar;
  },
  async [SET_GENERATED_AVATAR]({ commit }, payload) {
    const { id, url } = payload;
    await usersService.setAvatar(id);

    commit(UPDATE_PROFILE, { generatedAvatarUrl: url });
  }
};
