import {
  GET_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_COUNT,
  MARK_ALL_NOTIFICATIONS_AS_SEEN,
  MARK_AS_SEEN,
  DELETE_NOTIFICATION,
  GET_OLD_NOTIFICATIONS,
  SET_OLD_NOTIFICATIONS,
  SET_OLD_NOTIFICATIONS_COUNT,
  DELETE_OLD_NOTIFICATION
} from './types';
import notificationsHttpService from '../../services/notifications.http.service';
import notificationsMapper from '../../mappers/notifications.mapper';

export default {
  async [GET_NOTIFICATIONS]({ commit }, params) {
    const { result } = await notificationsHttpService.index(params);

    commit(SET_NOTIFICATIONS, result);
  },
  async [GET_OLD_NOTIFICATIONS]({ commit }, params) {
    const { result } = await notificationsHttpService.getOldNotifications(
      params
    );

    commit(
      SET_OLD_NOTIFICATIONS,
      notificationsMapper.transformToMultipleNotificationsDisplayData(
        result.notifications
      )
    );
    commit(SET_OLD_NOTIFICATIONS_COUNT, result.totalCount);
  },
  async [SET_NOTIFICATIONS_COUNT]({ commit }, count) {
    commit(SET_NOTIFICATIONS_COUNT, count);
  },
  async [MARK_ALL_NOTIFICATIONS_AS_SEEN]({ commit }, notificationIds) {
    await notificationsHttpService.markAsSeen({ notificationIds });

    commit(MARK_ALL_NOTIFICATIONS_AS_SEEN);
    commit(SET_NOTIFICATIONS_COUNT, 0);
  },
  async [MARK_AS_SEEN]({ commit, state }, id) {
    await notificationsHttpService.markAsSeen({ notificationIds: [id] });

    commit(DELETE_NOTIFICATION, id);
    commit(
      SET_NOTIFICATIONS_COUNT,
      state.notificationsCount > 0 ? state.notificationsCount - 1 : 0
    );
  },
  async [DELETE_NOTIFICATION]({ commit }, payload) {
    await notificationsHttpService.del(payload);

    commit(DELETE_NOTIFICATION, payload);
  },
  async [DELETE_OLD_NOTIFICATION]({ commit, state }, payload) {
    await notificationsHttpService.del(payload);

    commit(DELETE_OLD_NOTIFICATION, payload);
    commit(
      SET_OLD_NOTIFICATIONS_COUNT,
      state.oldNotificationsCount > 0 ? state.oldNotificationsCount - 1 : 0
    );
  }
};
