<template>
  <div class="feedback">
    <h3 class="text-h6 font-weight-light">
      {{ $t('feedback.title') }}
    </h3>
    <v-btn
      class="mt-2"
      href="https://docs.google.com/forms/d/1M15Tmfc7V8ey9ByEes9YUXPdp-ApjHPdOQffFHHe7dI/viewform?edit_requested=true"
      target="_blank"
      dark
      color="app-blue"
    >
      {{ $t('feedback.btn') }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'feedback'
};
</script>
