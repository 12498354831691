import httpService from './http.service';

function index() {
  return httpService.get('/user/rewards');
}

function create(payload) {
  return httpService.post('/user/rewards', payload);
}

function generateRewards(payload) {
  return httpService.post('/user/rewards/generate-rewards', payload);
}

function createMultiple(payload) {
  return httpService.post(`/user/rewards/multiple-rewards`, payload);
}

function redeem(id) {
  return httpService.post(`/user/rewards/${id}/redeem`);
}

function update(id, payload) {
  return httpService.put(`/user/rewards/${id}`, payload);
}

function del(id) {
  return httpService.del(`/user/rewards/${id}`);
}

export default {
  index,
  create,
  generateRewards,
  createMultiple,
  update,
  redeem,
  del
};
