export const SET_REWARDS = 'SET_REWARDS';
export const GET_REWARDS = 'GET_REWARDS';
export const ADD_REWARD = 'ADD_REWARD';
export const GENERATE_REWARDS = 'GENERATE_REWARDS';
export const ADD_MULTIPLE_REWARDS = 'ADD_MULTIPLE_REWARDS';
export const UPDATE_REWARD = 'UPDATE_REWARD';
export const REDEEM_REWARD = 'REDEEM_REWARD';
export const DELETE_REWARD = 'DELETE_REWARD';

// Group rewards actions
export const GET_GROUP_REWARDS = 'GET_GROUP_REWARDS';

export const GET_APPROVAL_REWARDS = 'GET_APPROVAL_REWARDS';
export const SET_APPROVAL_REWARDS = 'SET_APPROVAL_REWARDS';
export const APPROVE_ALL_REWARDS = 'APPROVE_ALL_REWARDS';

export const ADD_GROUP_REWARD = 'ADD_GROUP_REWARD';
export const UPDATE_GROUP_REWARD = 'UPDATE_GROUP_REWARD';
export const REDEEM_GROUP_REWARD = 'REDEEM_GROUP_REWARD';
export const APPROVE_GROUP_REWARD = 'APPROVE_GROUP_REWARD';
export const ARCHIVE_GROUP_REWARD = 'ARCHIVE_GROUP_REWARD';
export const COPY_GROUP_REWARD = 'COPY_GROUP_REWARD';
export const DELETE_GROUP_REWARD = 'DELETE_GROUP_REWARD';
