import authSession from '../../services/auth.session.service';

export default {
  isAuthenticated(state) {
    return state.isAuthenticated && authSession.isAuthenticated();
  },
  isLogin(state) {
    return state.isLogin;
  }
};
