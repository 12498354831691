export default {
  purchases: {
    myPurchases: 'My purchases',
    avatar: {
      title: 'Avatar purchase',
      text: 'You bought an avatar for <strong>{value} {currency}</strong>'
    },
    board: {
      title: 'Board purchase',
      text: 'You bought a board for <strong>{value} {currency}</strong>'
    },
    gems: {
      title: 'Gems purchase',
      text: 'You bought <strong>{gems} Gems</strong> for <strong>€{amount}</strong>'
    },
    noPurchasesFound: 'No purchases found!'
  }
};
