<template>
  <div class="support-us">
    <h3 class="text-h6 font-weight-light mb-2">
      {{ $t('supportUs.text') }}
    </h3>
    <a href="https://www.buymeacoffee.com/gamifinnopoly" target="_blank">
      <img
        src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png"
        alt="Buy Me A Coffee"
        style="height: 40px !important; width: 156px !important"
    /></a>
  </div>
</template>

<script>
export default {
  name: 'support-us'
};
</script>
