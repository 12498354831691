import {
  GET_GROUP_MISSIONS,
  SET_MISSIONS,
  GET_APPROVAL_MISSIONS,
  SET_APPROVAL_MISSIONS,
  ADD_GROUP_MISSION,
  ADD_MISSION,
  GET_CURRENT_GROUP_MISSION,
  SET_CURRENT_MISSION,
  UPDATE_GROUP_MISSION,
  UPDATE_MISSION,
  UPDATE_CURRENT_MISSION,
  ARCHIVE_GROUP_MISSION,
  COPY_GROUP_MISSION,
  COMPLETE_GROUP_MISSION,
  APPROVE_GROUP_MISSION,
  APPROVE_ALL_MISSIONS,
  DELETE_GROUP_MISSION,
  DELETE_MISSION
} from '../types';
import { UPDATE_CURRENT_GROUP_MEMBER_STATS } from '../../group-members/types';
import groupMissionsHttpService from '../../../services/groups/group.missions.service';
import memberMissionsService from '../../../services/groups/members/group.member.missions.service';
import missionsMapper from '../../../mappers/missions.mapper';
import dateTimeManager from '../../../common/date.time.manager';
import missionStatuses from '../../../enums/mission.statuses';
import usersMapper from '../../../mappers/users.mapper';

function loadCurrentMission(groupId, userId, missionId) {
  if (userId) {
    return memberMissionsService.get(groupId, userId, missionId);
  }

  return groupMissionsHttpService.get(groupId, missionId);
}

export default {
  async [GET_GROUP_MISSIONS]({ commit }, payload) {
    const { groupId, params } = payload;
    const { result } = await groupMissionsHttpService.index(groupId, params);

    commit(SET_MISSIONS, result);
  },
  async [GET_CURRENT_GROUP_MISSION]({ commit }, payload) {
    const { groupId, userId, missionId } = payload;

    const { result } = await loadCurrentMission(groupId, userId, missionId);

    commit(SET_CURRENT_MISSION, result);
  },
  [SET_CURRENT_MISSION]({ commit }, mission) {
    commit(SET_CURRENT_MISSION, mission);
  },
  async [GET_APPROVAL_MISSIONS]({ commit }, payload) {
    const { groupId, groupMissionId } = payload;
    const { result } = await groupMissionsHttpService.getApprovalMissions(
      groupId,
      groupMissionId
    );

    commit(SET_APPROVAL_MISSIONS, result);
  },
  async [ADD_GROUP_MISSION]({ commit }, payload) {
    const { groupId, mission, members, setAsCurrent } = payload;
    const { result } = await groupMissionsHttpService.create(
      groupId,
      missionsMapper.getGroupMissionReqBody(mission)
    );

    if (members) {
      mission.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        mission.assignedUsers
      );
    }

    const newMission = {
      id: result.id,
      tasks: [],
      ...mission,
      status: result.status
    };

    commit(ADD_MISSION, newMission);

    if (setAsCurrent) {
      commit(SET_CURRENT_MISSION, newMission);
    }
  },
  async [UPDATE_GROUP_MISSION]({ commit }, payload) {
    const { groupId, mission, members } = payload;
    const { id, ...missionData } = mission;

    await groupMissionsHttpService.update(
      groupId,
      id,
      missionsMapper.getMissionUpdateReqBody(missionData)
    );

    if (dateTimeManager.isSame(new Date(mission.from), new Date())) {
      mission.status = missionStatuses.active;
    }

    if (members) {
      mission.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        mission.assignedUsers
      );
    }

    commit(UPDATE_MISSION, mission);
    commit(UPDATE_CURRENT_MISSION, mission);
  },
  async [ARCHIVE_GROUP_MISSION]({ commit, rootState }, payload) {
    const { groupId, missionId } = payload;

    await groupMissionsHttpService.archive(groupId, missionId);

    commit(DELETE_MISSION, missionId);

    const missionToLoad = rootState.missions.missions[0];

    if (missionToLoad) {
      const { result } = await loadCurrentMission(
        groupId,
        null,
        missionToLoad.id
      );
      commit(SET_CURRENT_MISSION, result);
    }
  },
  async [COPY_GROUP_MISSION]({ commit }, payload) {
    const { groupId, mission } = payload;
    const { id, ...missionData } = mission;

    await groupMissionsHttpService.copy(
      groupId,
      id,
      missionsMapper.getGroupMissionReqBody(missionData)
    );

    commit(DELETE_MISSION, id);
  },
  async [COMPLETE_GROUP_MISSION]({ commit }, mission) {
    const { result } = await memberMissionsService.complete(
      mission.group.id,
      mission.id
    );

    if (mission.requireApprove) {
      const updateData = {
        status: missionStatuses.waitingApproval,
        approveRequested: true
      };

      commit(UPDATE_CURRENT_MISSION, updateData);
      commit(UPDATE_MISSION, {
        id: mission.id,
        ...updateData
      });
    } else {
      commit(UPDATE_CURRENT_MISSION, {
        status: missionStatuses.completed
      });
    }

    if (result.memberStats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.memberStats);
    }

    return result;
  },
  async [APPROVE_GROUP_MISSION]({ commit }, payload) {
    const { groupId, userId, missionId, groupMissionId, approved } = payload;

    const { result } = await memberMissionsService.approve(
      groupId,
      userId,
      missionId,
      {
        approved
      }
    );

    if (groupMissionId) {
      commit(APPROVE_GROUP_MISSION, {
        missionId,
        groupMissionId
      });
    } else {
      const missionUpdateData = {
        id: missionId,
        approveRequested: false,
        approved,
        status: result
      };
      commit(UPDATE_MISSION, missionUpdateData);
      commit(UPDATE_CURRENT_MISSION, missionUpdateData);
    }
  },
  async [APPROVE_ALL_MISSIONS]({ commit }, payload) {
    const { groupId, groupMissionId, approved } = payload;
    await groupMissionsHttpService.approveAllMissions(groupId, groupMissionId, {
      approved
    });

    commit(UPDATE_MISSION, { id: groupMissionId, waitingApproval: 0 });
    commit(UPDATE_CURRENT_MISSION, { waitingApproval: 0 });
  },
  async [DELETE_GROUP_MISSION]({ commit, rootState }, payload) {
    const { groupId, missionId } = payload;

    await groupMissionsHttpService.del(groupId, missionId);

    commit(DELETE_MISSION, missionId);

    const missionToLoad = rootState.missions.missions[0];

    if (missionToLoad) {
      const { result } = await loadCurrentMission(
        groupId,
        null,
        missionToLoad.id
      );
      commit(SET_CURRENT_MISSION, result);
    }
  }
};
