import httpService from './http.service';

const BASE_GROUPS_URL = '/groups';

function getMembersUrl(groupId, userId) {
  let url = `${BASE_GROUPS_URL}/${groupId}/members`;

  if (userId) {
    url = `${url}/${userId}`;
  }

  return url;
}

function index(groupId, params = {}) {
  return httpService.get(getMembersUrl(groupId), params);
}

function invite(groupId, payload) {
  return httpService.post(`${BASE_GROUPS_URL}/${groupId}/invite`, payload);
}

function acceptInvitation(id) {
  return httpService.post(`${BASE_GROUPS_URL}/accept/${id}`);
}

function acceptGroupEmailInvitation(payload) {
  return httpService.post(
    `${BASE_GROUPS_URL}/accept-email-invitation/`,
    payload
  );
}

function promoteToLeader(groupId, userId) {
  return httpService.patch(`${getMembersUrl(groupId, userId)}/promote-leader`);
}

function lowerToMember(groupId, userId) {
  return httpService.patch(`${getMembersUrl(groupId, userId)}/lower-member`);
}

function sendGems(groupId, userId, payload) {
  return httpService.patch(
    `${getMembersUrl(groupId, userId)}/send-gems`,
    payload
  );
}

function del(groupId, userId) {
  return httpService.del(getMembersUrl(groupId, userId));
}

export default {
  index,
  invite,
  acceptInvitation,
  acceptGroupEmailInvitation,
  promoteToLeader,
  lowerToMember,
  sendGems,
  del
};
