export const GET_MEMBERS = 'GET_MEMBERS';
export const SET_MEMBERS = 'SET_MEMBERS';
export const SET_CURRENT_MEMBER = 'SET_CURRENT_MEMBER';
export const ACCEPT_GROUP_INVITATION = 'ACCEPT_GROUP_INVITATION';
export const ACCEPT_GROUP_EMAIL_INVITATION = 'ACCEPT_GROUP_EMAIL_INVITATION';
export const UPDATE_GROUP_MEMBER = 'UPDATE_GROUP_MEMBER';
export const UPDATE_CURRENT_GROUP_MEMBER_STATS =
  'UPDATE_CURRENT_GROUP_MEMBER_STATS';
export const PROMOTE_TO_LEADER = 'PROMOTE_TO_LEADER';
export const LOWER_TO_MEMBER = 'LOWER_TO_MEMBER';
export const SEND_GEMS = 'SEND_GEMS';
export const DELETE_GROUP_MEMBER = 'DELETE_GROUP_MEMBER';
