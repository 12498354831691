export default {
  avatars: {
    avatars: 'Аватари',
    areYouSureToBuyThisAvatar:
      'Сигурни ли сте, че искате да купите този аватар?',
    notEnoughGemsToBuyAvatar: `Нямате достатъчно Gems, за да купите този аватар!`,
    notEnoughGoldToBuyAvatar: `Нямате достатъчно злато, за да купите този аватар!`,
    avatarBoughtSuccessfully: 'Успешно закупихте този аватар!',
    noAvatarsFound: 'Няма намерени аватари!',
    doYouWantToSetAvatarAsProfile:
      'Искате ли да използвате новият си аватар като прифилна снимка?',
    useAvatar: 'Използвай',
    successAvatarChange: 'Успешно сменихте аватара си!',
    myAvatars: 'Моите аватари',
    buyMoreAvatars: 'Купи още аватари'
  }
};
