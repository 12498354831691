import localStorageService from './local.storage.service';
import langages from '../enums/languages';

function getUser() {
  return JSON.parse(localStorageService.getItem('cu'));
}

function getToken() {
  return localStorageService.getItem('ut');
}

function getLang() {
  return localStorageService.getItem('lang');
}

function setUser(user) {
  localStorageService.setItem('cu', JSON.stringify(user));
}

function setToken(token) {
  localStorageService.setItem('ut', token);
}

function setLang(lang) {
  localStorageService.setItem('lang', lang || langages.en);
}

function removeUser() {
  localStorageService.removeItem('cu');
}

function removeToken() {
  localStorageService.removeItem('ut');
}

function isAuthenticated() {
  return !!this.getToken();
}

export default {
  getUser,
  getToken,
  getLang,
  setUser,
  setToken,
  setLang,
  removeUser,
  removeToken,
  isAuthenticated
};
