<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path fill="#ff6464" d="M150.069 97.103h211.862v317.793H150.069z" />
    <path fill="#ffb1b1" d="M150.069 0h211.862v97.103H150.069z" />
    <path fill="#d2555a" d="M150.069 414.897h211.862V512H150.069z" />
    <path fill="#ffb1b1" d="M361.931 97.103h97.103v317.793h-97.103z" />
    <path fill="#ffd8d8" d="M361.931 0l97.103 97.103h-97.103z" />
    <path fill="#ff8b8b" d="M459.034 414.897L361.931 512v-97.103z" />
    <path fill="#d2555a" d="M52.966 97.103h97.103v317.793H52.966z" />
    <path fill="#ff6464" d="M150.069 512l-97.103-97.103h97.103z" />
    <path fill="#ff8b8b" d="M52.966 97.103L150.069 0v97.103z" />
    <g fill="#fff">
      <path
        d="M390.817 99.329c-17.301-3.945-30.92-17.564-34.863-34.865-.297-1.303-2.022-1.303-2.319 0-3.944 17.301-17.564 30.92-34.863 34.865-1.303.297-1.303 2.022 0 2.319 17.301 3.944 30.92 17.562 34.863 34.863.297 1.303 2.022 1.303 2.319 0 3.944-17.301 17.564-30.92 34.863-34.863a1.177 1.177 0 000-2.319zM341.557 154.094c-13.58-3.518-24.273-14.209-27.789-27.789-.353-1.364-2.119-1.364-2.472 0-3.517 13.58-14.209 24.273-27.789 27.789-1.364.353-1.364 2.119 0 2.472 13.58 3.517 24.273 14.209 27.789 27.789.353 1.364 2.119 1.364 2.472 0 3.518-13.581 14.209-24.273 27.789-27.789 1.365-.353 1.365-2.119 0-2.472z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ruby-coin-icon'
};
</script>
