import httpService from './http.service';

const BASE_URL = '/users/profile';

function getProfile() {
  return httpService.get(BASE_URL);
}

function updateProfile(payload) {
  return httpService.put(BASE_URL, payload);
}

function changePassword(payload) {
  return httpService.put(`${BASE_URL}/change-password`, payload);
}

function changeAvatar(avatarId) {
  return httpService.patch(`${BASE_URL}/avatar/${avatarId}`);
}

function generateAvatar(payload) {
  return httpService.post(`${BASE_URL}/generate-avatar`, payload);
}

function setAvatar(id) {
  return httpService.patch(`${BASE_URL}/set-generated-avatar/${id}`, {});
}

function changeBoard(boardId) {
  return httpService.patch(`${BASE_URL}/board/${boardId}`);
}

function changeHolidayMode() {
  return httpService.patch(`${BASE_URL}/holiday-mode`);
}

export default {
  getProfile,
  updateProfile,
  changePassword,
  changeAvatar,
  generateAvatar,
  setAvatar,
  changeBoard,
  changeHolidayMode
};
