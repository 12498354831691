export default {
  groupInvitation: 'groupInvitation',
  acceptedGroupInvitaion: 'acceptedGroupInvitaion',
  newTaskAssigned: 'newTaskAssigned',
  askTaskApprove: 'askTaskApprove',
  taskApprove: 'taskApprove',
  newRewardAdded: 'newRewardAdded',
  newMissionAssigned: 'newMissionAssigned',
  askMissionApprove: 'askMissionApprove',
  missionApprove: 'missionApprove',
  disapprovedExpiredMission: 'disapprovedExpiredMission',
  removeMember: 'removeMember',
  promoteToLeader: 'promoteToLeader',
  lowerToMember: 'lowerToMember',
  requestRewardApproval: 'requestRewardApproval',
  rewardApprove: 'rewardApprove',
  sendGems: 'sendGems'
};
