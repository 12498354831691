export default {
  users: {
    editProfile: 'Промяна на профила',
    changePassword: 'Промяна на паролата',
    changeAvatar: 'Промени аватар',
    level: 'Ниво',
    stats: {
      success: {
        gold: 'Спечелихте {value} злато!',
        exp: 'Спечелихте {value} опит!',
        level: 'Вдигнахте ниво!',
        levelReached: 'Достигнахте {value} ниво!',
        ranking: 'Достихте нов ранг!',
        missionBonusGold:
          'Спечелихте {value} бонус злато за завършената мисия!',
        missionBonusRuby:
          'Спечелихте {value} бонус рубина за завършената мисия!',
        ruby: 'Спечелихте {value} рубина!'
      },
      failure: {
        gold: 'Загубихте {value} злато!',
        exp: 'Загубихте {value} опит!',
        level: 'Ниво надолу!',
        ranking: 'Понижихте своя ранг!',
        ruby: 'Загубихте {value} рубина!',
        tasksAndMissions: {
          gold: 'Затова, че не сте завършили своите Задачи и Мисии вие загубихте {value} злато!'
        }
      }
    },
    changeProfileImage: 'Промени аватар',
    urlToProfileImage: 'Адрес на аватар',
    holidayMode: 'Режим на почивка',
    holidayModeInfo:
      'Когато  включите режимът на почивка, вие няма да можете да завършвате своите задачи и мисии, както и да губите злато или рубита ако не сте ги завършили.',
    successfullyEnabledHolidayMode: 'Успешно включихте режимън на почивка!',
    successfullyDisabledHolidayMode: 'Успешно изключихте режимън на почивка!',
    generateProfileImage: 'Генерирай аватар',
    generateProfileImageCostText:
      'Само въведете, какъв аватар искате да генерираме за вас. Всяко ново генериране ще ви струва ',
    generateProfileImagePrompt:
      'Какъв аватар искате? Пример: Сладко кученце, което се плези'
  }
};
