export default {
  notifications(state) {
    return state.notifications;
  },
  oldNotifications(state) {
    return state.oldNotifications;
  },
  notificationsCount(state) {
    return state.notificationsCount;
  },
  oldNotificationsCount(state) {
    return state.oldNotificationsCount;
  }
};
