import {
  SET_MEMBERS,
  SET_CURRENT_MEMBER,
  UPDATE_CURRENT_GROUP_MEMBER_STATS,
  UPDATE_GROUP_MEMBER,
  DELETE_GROUP_MEMBER
} from './types';

export default {
  [SET_MEMBERS](state, members) {
    state.members = members;
  },
  [SET_CURRENT_MEMBER](state, member) {
    state.currentMember = member;
  },
  [UPDATE_CURRENT_GROUP_MEMBER_STATS](state, payload) {
    state.currentMember.stats = {
      ...state.currentMember.stats,
      ...payload
    };
  },
  [UPDATE_GROUP_MEMBER](state, payload) {
    const { memberId, ...memberData } = payload;
    const index = state.members.findIndex(m => m.id === memberId);

    if (index !== -1) {
      state.members.splice(index, 1, {
        ...state.members[index],
        ...memberData
      });
    }
  },
  [DELETE_GROUP_MEMBER](state, id) {
    const index = state.members.findIndex(m => m.id === id);

    if (index !== -1) {
      state.members.splice(index, 1);
    }
  }
};
