import httpService from './http.service';

function index(params = {}) {
  return httpService.get('/user/tasks', params);
}

function create(payload) {
  return httpService.post('/user/tasks', payload);
}

function completeTask(id, payload) {
  return httpService.post(`/user/tasks/${id}/complete`, payload);
}

function reopenTask(id) {
  return httpService.post(`/user/tasks/${id}/reopen`);
}

function addResult(id, payload) {
  return httpService.post(`/user/tasks/${id}/result`, payload);
}

function update(id, payload) {
  return httpService.put(`/user/tasks/${id}`, payload);
}

function archiveTask(id) {
  return httpService.patch(`/user/tasks/${id}/archive`);
}

function copyTask(id) {
  return httpService.post(`/user/tasks/${id}/copy`);
}

function del(id) {
  return httpService.del(`/user/tasks/${id}`);
}

export default {
  index,
  create,
  update,
  completeTask,
  reopenTask,
  addResult,
  archiveTask,
  copyTask,
  del
};
