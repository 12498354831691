import { TOGGLE_SIDE_NAV, SELECT_BOTTOM_NAV_ITEM } from './types';

export default {
  [TOGGLE_SIDE_NAV](state) {
    state.hasSideNav = !state.hasSideNav;
  },
  [SELECT_BOTTOM_NAV_ITEM](state, index) {
    state.bottomNavItemsVisibility = [false, false, false];
    state.bottomNavItemsVisibility[index] = true;
  }
};
