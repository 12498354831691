<template>
  <v-container fluid class="pa-1">
    <v-row wrap justify="center" align="center">
      <v-col cols="3" class="text-center">
        <user-avatar
          :user="baseNotification"
          :size="50"
          textSizeClass="subheading"
        ></user-avatar>
      </v-col>
      <v-col cols="9">
        <v-row>
          <v-col cols="10">
            <h3 class="one-line">
              {{ baseNotification.title }}
            </h3>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              text
              icon
              color="app-red"
              class="ma-0 ml-3 x-small-icon-btn"
              @click.stop="markAsSeen"
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <p class="mb-0" v-html="baseNotification.text"></p>
        <slot name="right-column"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserAvatar from '../../shared/UserAvatar';
import { MARK_AS_SEEN } from '../../../store/notifications/types';

export default {
  name: 'base-invitation',
  components: {
    UserAvatar
  },
  props: {
    baseNotification: {
      type: Object,
      required: true
    }
  },
  methods: {
    markAsSeen() {
      this.$store.dispatch(MARK_AS_SEEN, this.baseNotification.id);
    }
  }
};
</script>
