<template>
  <div class="how-does-it-works">
    <div id="board-game-style" class="bg-white box-shadow">
      <v-container class="pa-6">
        <h1
          class="grey--text text--darken-3 text-h3 text-center font-weight-bold mb-4 mt-1"
        >
          {{ $t('about.howDoesItWork.title') }}
        </h1>
        <p class="text-h6 text-center">
          {{ $t('about.howDoesItWork.intro') }}
        </p>
        <p class="text-h6 text-center">
          {{ $t('about.howDoesItWork.gamificationDesc') }}
        </p>
      </v-container>
      <v-container fluid class="pa-6">
        <v-row align="center" justify="center">
          <v-col md="6" sm="6" cols="12" class="px-4">
            <h1
              class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-1"
            >
              {{ $t('about.howDoesItWork.boardGameStyle.title') }}
            </h1>
            <p class="text-h6 text-center pa-3">
              {{ $t('about.howDoesItWork.boardGameStyle.text') }}
            </p>
          </v-col>
          <v-col md="6" sm="6" cols="12" class="px-5">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.board"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="missions-and-tasks">
      <v-container fluid class="pa-6">
        <v-row align="center" justify="center">
          <v-col md="6" sm="6" cols="12" class="px-4">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.missionsAndTasks"
            ></v-img>
          </v-col>
          <v-col md="6" sm="6" cols="12" class="px-5">
            <h1
              class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-1"
            >
              {{ $t('about.howDoesItWork.missionsAndTasks.title') }}
            </h1>
            <p class="text-h6 text-center pa-3">
              {{ $t('about.howDoesItWork.missionsAndTasks.text') }}
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="rewards" class="bg-white box-shadow">
      <v-container fluid class="pa-6">
        <v-row align="center" justify="center">
          <v-col md="6" sm="6" cols="12" class="px-4">
            <h1
              class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-1"
            >
              {{ $t('about.howDoesItWork.rewards.title') }}
            </h1>
            <p class="text-h6 text-center pa-3">
              {{ $t('about.howDoesItWork.rewards.text') }}
            </p>
          </v-col>
          <v-col md="5" sm="6" cols="12" class="px-5 d-flex justify-center">
            <v-img
              max-height="480"
              max-width="420"
              class="img-shadow border-radius-10"
              :src="images.rewards"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="groups">
      <v-container class="pa-6">
        <h1
          class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-3"
        >
          {{ $t('about.howDoesItWork.groups.title') }}
        </h1>
        <p class="text-h6 text-center mb-5">
          {{ $t('about.howDoesItWork.groups.text') }}
        </p>
        <v-row>
          <v-col cols="12" class="px-2">
            <v-img
              class="img-shadow border-radius-10"
              :src="images.groupDetails"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div id="achieve-more" class="bg-white box-shadow mt-3 py-3">
      <v-container class="pa-6">
        <h1
          class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-4"
        >
          {{ $t('about.howDoesItWork.achieveMore.title') }}
        </h1>
        <p class="text-h6 text-center mb-5">
          {{ $t('about.howDoesItWork.achieveMore.text') }}
        </p>
        <v-row>
          <v-col sm="12" class="text-center">
            <v-btn
              color="app-blue"
              dark
              class="text-h6 get-started-btn"
              to="/sign-up"
            >
              {{ $t('common.getStarted') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: 'how-does-it-work-medium-size',
  data() {
    return {
      images: {
        board: require('../../assets/images/public/board.png'),
        missionsAndTasks: require('../../assets/images/public/missions-and-tasks.png'),
        rewards: require('../../assets/images/public/rewards.png'),
        groupDetails: require('../../assets/images/public/group-details.png')
      }
    };
  }
};
</script>
