import notificationTypes from '../enums/notification.types';
import BaseNotificationModal from '../components/notifications/modals/BaseNotificationModal';
import GroupInvitationNotificationModal from '../components/notifications/modals/GroupInvitationNotificationModal';

const NOTIFICATION_MODAL_CONFIG = {
  width: '360px'
};

export default {
  methods: {
    openModal(component, payload) {
      this.$root.$modal.show(
        component,
        { ...payload },
        NOTIFICATION_MODAL_CONFIG
      );
    },
    async onNotificationClick(notification) {
      if (notification.type === notificationTypes.groupInvitation) {
        this.openModal(GroupInvitationNotificationModal, {
          notification: { ...notification }
        });
      } else if (
        notification.type === notificationTypes.askTaskApprove ||
        notification.type === notificationTypes.askMissionApprove ||
        notification.type === notificationTypes.requestRewardApproval
      ) {
        this.$router.push({
          path: `/groups/${notification.groupId}/dashboard?userId=${notification.userId}`
        });
      } else {
        this.openModal(BaseNotificationModal, {
          baseNotification: { ...notification }
        });
      }
    }
  }
};
