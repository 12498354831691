export default {
  members: {
    members: 'Участници',
    roles: {
      creator: 'Създател',
      leader: 'Лидер',
      member: 'Участник'
    },
    emailAlreadyAdded: 'Имейл адресът вече е добавен!',
    memberAlreadyInGroup: 'Участникът е вече в групата!',
    inviteToGroup: 'Покана за група',
    inviteGroupDescription:
      'Ако поканите участници, които нямат регистрация в Gamifinnopoly, ние ще им изпратим покана по имейл, иначе те ще получат известие за покана в Gamifinnopoly.',
    addMember: 'Добави участник',
    invitationSentSuccessfully: 'Поканата/те бяха изпратени успешно!',
    acceptInvitation: 'Приемам поканата',
    successfullyJoinedGroup: 'Успешно се присъединихте към групата!',
    stats: {
      failure: {
        ruby: 'Загубихте {ruby} ruby в "{groupName}"'
      }
    },
    deleteMemberConfirm:
      'Сигурни ли сте, че искате да изтриете този потребител от групата?',
    promoteToLeader: 'Повиши до Лидер',
    promoteToLeaderConfirm:
      'Сигурни ли сте, че искате да повишите този потребител?',
    lowerToMember: 'Понижи до Участник',
    lowerToMemberConfirm:
      'Сигурни ли сте, че искате да понижите този потребител?',
    verifyGroupEmailInvitation:
      'Проверяваме поканата за включване в групата! Моля изчакайте...',
    cannotAddMoreThan5MembersToGroup:
      'За текущата БЕТА версия на Gamifinnopoly, няма да можете да добавяте повече от 5 участника в група!',
    youCanAddMembers: 'Може да добавите още {count} участника към групата',
    cannotAddMoreMembers: 'Не можете да добавяте повече участника към групата!',
    sendGems: 'Изпрати Gems',
    youCanSend: 'Може да изпратите максимум',
    successfullySentGems: 'Успешно изпратихте Gems!',
    viewDashboard: 'Към борда',
    inviteMembers: 'Добави нови членове'
  }
};
