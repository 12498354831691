import {
  SET_TASKS,
  GET_GROUP_TASKS,
  GET_ARCHIVED_GROUP_TASKS,
  SET_ARCHIVED_TASKS,
  GET_APPROVAL_TASKS,
  SET_APPROVAL_TASKS,
  ADD_GROUP_TASK,
  ADD_TASK,
  UPDATE_GROUP_TASK,
  COMPLETE_GROUP_TASK,
  REOPEN_GROUP_TASK,
  ADD_RESULT_GROUP_TASK,
  ARCHIVE_GROUP_TASK,
  COPY_GROUP_TASK,
  APPROVE_ALL_TASKS,
  APPROVE_GROUP_TASK,
  DELETE_GROUP_TASK,
  DELETE_TASK
} from '../types';
import { UPDATE_TASK } from '../../tasks/types';
import { UPDATE_PROFILE } from '../../users/types';
import { UPDATE_CURRENT_GROUP_MEMBER_STATS } from '../../group-members/types';
import groupTasksService from '../../../services/groups/group.tasks.service';
import memberTasksService from '../../../services/groups/members/group.member.tasks.service';
import tasksMapper from '../../../mappers/tasks.mapper';
import usersMapper from '../../../mappers/users.mapper';
import tasksHelper from '../../../common/tasks.helper';

function loadTasks(payload) {
  const { groupId, userId, params } = payload;

  if (userId) {
    return memberTasksService.index(groupId, userId, params);
  }

  return groupTasksService.index(groupId, params);
}

export default {
  async [GET_GROUP_TASKS]({ commit }, payload) {
    const { result } = await loadTasks(payload);

    commit(SET_TASKS, result);
  },
  async [GET_ARCHIVED_GROUP_TASKS]({ commit }, payload) {
    const { result } = await loadTasks(payload);

    commit(SET_ARCHIVED_TASKS, result);
  },
  async [GET_APPROVAL_TASKS]({ commit }, payload) {
    const { groupId, groupTaskId } = payload;
    const { result } = await groupTasksService.getApprovalTasks(
      groupId,
      groupTaskId
    );

    commit(SET_APPROVAL_TASKS, result);
  },
  async [ADD_GROUP_TASK]({ commit, rootState }, payload) {
    const { groupId, task, members } = payload;

    const { result } = await groupTasksService.create(
      groupId,
      tasksMapper.transformToGroupTaskReqBody(task)
    );

    task.tags = tasksHelper.mapTaskTags(rootState.tags.tags, task.tags);

    if (members) {
      task.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        task.assignedUsers
      );
    }

    commit(ADD_TASK, { id: result, ...task });
  },
  async [UPDATE_GROUP_TASK]({ commit, rootState }, payload) {
    const { groupId, task, members } = payload;
    const { id, ...taskData } = task;

    await groupTasksService.update(
      groupId,
      id,
      tasksMapper.transformToGroupTaskReqBody(taskData)
    );

    if (members) {
      task.assignedUsers = usersMapper.transformAssignedUsers(
        members,
        task.assignedUsers
      );
    }

    commit(UPDATE_TASK, {
      ...task,
      tags: tasksHelper.mapTaskTags(rootState.tags.tags, task.tags)
    });
  },
  async [COMPLETE_GROUP_TASK]({ commit }, payload) {
    const { groupId, task } = payload;

    const body = {};

    if (task.requireResult && task.result) {
      body.result = task.result;
    }

    const { result } = await memberTasksService.complete(
      groupId,
      task.id,
      body
    );

    commit(UPDATE_TASK, {
      ...task,
      completed: true,
      approveRequested: task.requireApprove ? true : false,
      approved: null
    });

    commit(UPDATE_PROFILE, { stats: result.stats, board: result.board });

    if (result.memberStats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.memberStats);
    }

    return result;
  },
  async [REOPEN_GROUP_TASK]({ commit }, payload) {
    const { groupId, task } = payload;

    const { result } = await memberTasksService.reopen(groupId, task.id);

    commit(UPDATE_TASK, {
      ...task,
      completed: false,
      approveRequested: false,
      approved: null
    });

    commit(UPDATE_PROFILE, { stats: result.stats, board: result.board });

    if (result.memberStats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.memberStats);
    }

    return result;
  },
  async [ADD_RESULT_GROUP_TASK]({ commit }, task) {
    await memberTasksService.addResult(task.group.id, task.id, {
      result: task.result
    });

    commit(UPDATE_TASK, task);
  },
  async [ARCHIVE_GROUP_TASK]({ commit }, payload) {
    const { groupId, taskId } = payload;

    await groupTasksService.archive(groupId, taskId);

    commit(DELETE_TASK, taskId);
  },
  async [COPY_GROUP_TASK]({ commit }, payload) {
    const { groupId, task } = payload;
    const { id, ...taskData } = task;

    await groupTasksService.copy(
      groupId,
      id,
      tasksMapper.transformToGroupTaskReqBody(taskData)
    );

    commit(DELETE_TASK, id);
  },
  async [APPROVE_GROUP_TASK]({ commit }, payload) {
    const { groupId, userId, taskId, groupTaskId, approved } = payload;

    await memberTasksService.approve(groupId, userId, taskId, { approved });

    if (groupTaskId) {
      commit(APPROVE_GROUP_TASK, {
        taskId,
        groupTaskId
      });
    } else {
      commit(UPDATE_TASK, {
        id: taskId,
        approveRequested: false,
        approved,
        completed: approved
      });
    }
  },
  async [APPROVE_ALL_TASKS]({ commit }, payload) {
    const { groupId, groupTaskId, approved } = payload;
    await groupTasksService.approveAllTasks(groupId, groupTaskId, { approved });

    commit(UPDATE_TASK, { id: groupTaskId, waitingApproval: 0 });
  },
  async [DELETE_GROUP_TASK]({ commit }, payload) {
    const { groupId, task } = payload;

    await groupTasksService.del(groupId, task.id);

    commit(DELETE_TASK, task.id);
  }
};
