function mapTaskTags(allTags, selectedTags) {
  const result = [];

  for (const tagId of selectedTags) {
    result.push(allTags.find(tag => tag.id === tagId));
  }

  return result;
}

export default {
  mapTaskTags
};
