import common from './en/common';
import users from './en/users';
import groups from './en/groups';
import members from './en/group.members';
import termsAndPrivacy from './en/terms.and.privacy';
import avatars from './en/avatars';
import boards from './en/boards';
import store from './en/store';
import notifications from './en/notifications';
import purchases from './en/purchases';
import missions from './en/missions';
import tasks from './en/tasks';
import rewards from './en/rewards';
import auth from './en/auth';
import errors from './en/errors';

export default {
  ...common,
  languages: {
    en: 'English',
    bg: 'Български'
  },
  about: {
    moto: 'Every completed task is a step forward',
    poweredByAI: 'Powered by AI',
    shortDescription: {
      firstPart:
        'Are you living in a world where it has become tough to manage your daily tasks, achieve goals and build healthy and useful habits?',
      secondPart:
        'Do you want to change it and become a part of our brand new world full of superhumans called Gamifinnators?',
      thirdPart:
        'They are smart, creative, empathic, inspiring, explorers, and innovators who like to have fun while achieving their targets.',
      fourthPart: `Just sign up for our new tool, Gamifinnopoly, and enjoy the benefits of being a Gamifinnator. 
        Let us all together make the world a more exciting and
        creative place to live in.`,
      alreadyPossible: 'That is now already possible!'
    },
    howDoesItWork: {
      title: 'How does it work?',
      intro: `Gamifinnopoly is one of our magical tools used to upgrade smart people like you into superhumans called Gamifinnators. 
        All that is possible, thanks to our secret weapon called Gamification.`,
      gamificationDesc:
        'Gamification uses game elements, methodology and techniques in non-game and serious contexts to make them more fun and engaging.',
      boardGameStyle: {
        title: 'Board game style',
        text: 'Everything at Gamifinnopoly is made in a board game style, where you move through an endless board where you can expect surprises like random rewards or exciting information.'
      },
      missionsAndTasks: {
        title: 'Missions and tasks',
        text: 'To achieve your goals, you can create, execute and complete various interesting missions and tasks.'
      },
      rewards: {
        title: 'Redeem rewards',
        text: 'To make all that even more engaging, you can define and earn gold that can be exchanged for rewards.'
      },
      groups: {
        title: 'Groups',
        text: `Especially for your friends or children, you can create different groups with leaders and users. 
          The leader sets missions, tasks and rewards for the members, which earn them virtual rubies. 
          Then they can use that currency to claim and receive rewards, which are created and approved by the leaders.
          For example, if you want to teach you kid some essential organizational habits and how to keep track of their results, 
          while in the same time have fun and reward his achievements. It is the perfect tool for you.
        `
      },
      achieveMore: {
        title: 'Want to achieve more and have some fun?',
        text: `Sign up for free and begin to achieve more, have fun and at the end of the day
          become a Gamifinnator!`
      }
    }
  },
  partners: {
    title: 'Partners'
  },
  footer: {
    text: 'Gamifinnopoly is a product and property of',
    companyName: 'Gamifinno Ltd.'
  },
  ...auth,
  ...users,
  rankings: {
    gamifinnator: 'Gamifinnator',
    newbie: 'Newbie',
    kid: 'Kid',
    junior: 'Junior',
    experienced: 'Experienced',
    senior: 'Senior',
    expert: 'Expert',
    master: 'Master'
  },
  ...missions,
  ...tasks,
  ...rewards,
  tags: {
    tags: 'Tags',
    work: 'Work',
    health: 'Health',
    sport: 'Sport',
    holiday: 'Holiday',
    school: 'School',
    fun: 'Fun',
    travel: 'Travel'
  },
  supportUs: {
    text: 'We will highly appreciate your help to grow further'
  },
  feedback: {
    title: 'We appreciate your opinion!',
    btn: 'Give us a feedback'
  },
  facts: {
    pickFact: 'Pick a fact',
    didYouKnow: 'Did you know',
    learnMore: 'Learn more'
  },
  specialBonuses: {
    pickSpecialBonus: 'Pick a special bonus',
    youWon: 'You won!',
    useItHere: 'Use it here'
  },
  gamTasks: {
    pickTask: 'Pick a task',
    letsDoIt: 'Lets do it',
    notInterested: 'Not interested'
  },
  questions: {
    pickQuestion: 'Pick a question',
    submitAnswer: 'Submit answer',
    correctAnswer: 'Correct answer!',
    wrongAnswer: 'Wrong answer!'
  },
  quotes: {
    pickQuote: 'Pick a quote',
    unknown: 'Unknown'
  },
  ...groups,
  ...members,
  ...notifications,
  ...avatars,
  ...boards,
  ...store,
  ...termsAndPrivacy,
  ...purchases,
  ...errors
};
