import {
  SET_REWARDS,
  GET_REWARDS,
  ADD_REWARD,
  GENERATE_REWARDS,
  ADD_MULTIPLE_REWARDS,
  UPDATE_REWARD,
  REDEEM_REWARD,
  DELETE_REWARD
} from '../types';
import { UPDATE_PROFILE } from '../../users/types';
import rewardsService from '../../../services/rewards.service';
import rewardTypes from '../../../enums/reward.types';
import rewardsMapper from '../../../mappers/rewards.mapper';

export default {
  async [GET_REWARDS]({ commit }) {
    const { result } = await rewardsService.index();

    commit(SET_REWARDS, result);
  },
  async [ADD_REWARD]({ commit }, reward) {
    const { result } = await rewardsService.create(reward);

    commit(ADD_REWARD, { id: result, ...reward });
  },
  async [GENERATE_REWARDS]({ commit }, payload) {
    const { result } = await rewardsService.generateRewards(payload);

    commit(UPDATE_PROFILE, { stats: result.stats });

    return rewardsMapper.transformToGeneratedRewards(result.rewards);
  },
  async [ADD_MULTIPLE_REWARDS]({ commit }, payload) {
    const { rewards } = payload;
    const { result } = await rewardsService.createMultiple({
      rewards: rewardsMapper.transformToMultipeRewardsReqBody(rewards)
    });

    commit(ADD_MULTIPLE_REWARDS, result);
  },
  async [UPDATE_REWARD]({ commit }, reward) {
    const { id, ...rewardData } = reward;

    await rewardsService.update(id, rewardData);

    commit(UPDATE_REWARD, reward);
  },
  async [REDEEM_REWARD]({ commit }, reward) {
    const { result } = await rewardsService.redeem(reward.id);

    if (reward.type === rewardTypes.times) {
      reward.timesToUse -= 1;

      if (reward.timesToUse === 0) {
        commit(DELETE_REWARD, reward.id);
      } else {
        commit(UPDATE_REWARD, reward);
      }
    } else if (reward.type === rewardTypes.once) {
      commit(DELETE_REWARD, reward.id);
    }

    commit(UPDATE_PROFILE, { stats: result.stats });

    return result;
  },
  async [DELETE_REWARD]({ commit }, id) {
    await rewardsService.del(id);

    commit(DELETE_REWARD, id);
  }
};
