import store from '../store';
import authSessionService from './auth.session.service';
import apiErrors from '../enums/api.errors';
import { LOGOUT } from '../store/auth/types';

function handleApiErrors(error) {
  if (
    authSessionService.isAuthenticated() &&
    (error.message === apiErrors.authenticationFailed ||
      error.message === apiErrors.yourAccountIsLocked)
  ) {
    store.dispatch(LOGOUT, false);
  }
}

export default {
  handleApiErrors
};
