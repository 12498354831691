<template>
  <v-card>
    <v-card-title>
      <v-row align="center" justify="center" class="pa-1">
        <div class="pt-1 bg-white">
          <v-img width="100" height="100" :src="heroes"></v-img>
        </div>
      </v-row>
    </v-card-title>
    <v-card-text class="text-center text-h5 mb-5 pb-4 app-green--text">
      <span> {{ $t('common.newVersion') }}</span
      ><br />
      <span class="text-subtitle-1">{{ $t('common.clickRefresh') }}</span>
    </v-card-text>
    <v-card-actions>
      <v-btn
        color="app-green"
        :loading="loading"
        dark
        block
        @click="refreshApp"
      >
        {{ $t('common.refresh') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'new-version-modal',
  props: {
    registration: Object
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      heroes: require('../../assets/images/confetti.svg')
    };
  },
  created() {
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;

      this.refreshing = true;
      window.location.reload(true);
    });
  },
  beforeDestroy() {
    navigator.serviceWorker.removeEventListener('controllerchange');
  },
  methods: {
    refreshApp() {
      if (!this.registration || !this.registration.waiting) return;
      this.loading = true;
      this.registration.waiting.postMessage('skipWaiting');
    }
  }
};
</script>
