<template>
  <div class="home">
    <home-medium-screen-size
      v-if="$vuetify.breakpoint.mdAndUp"
    ></home-medium-screen-size>
    <home-small-screen-size v-else></home-small-screen-size>
    <v-footer class="text-center bg-light-grey" height="auto">
      <v-card flat tile class="flex bg-light-grey">
        <v-card-text>
          <v-btn
            href="https://www.facebook.com/MyGamifinnopoly"
            class="mr-2"
            target="_blank"
            icon
          >
            <v-icon size="24px">fab fa-facebook</v-icon>
          </v-btn>
          <v-btn
            href="https://www.linkedin.com/company/gamifinnopoly"
            class="mr-2"
            target="_blank"
            icon
          >
            <v-icon size="24px">fab fa-linkedin-in</v-icon>
          </v-btn>
          <v-btn
            href="https://www.instagram.com/gamifinnopoly"
            target="_blank"
            icon
          >
            <v-icon size="24px">fab fa-instagram</v-icon>
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <span class="text-subtitle-1">
            {{ $t('footer.text') }}
            <strong>
              <a target="_blank" href="https://gamifinno.com/">
                {{ $t('footer.companyName') }}
              </a>
            </strong>
          </span>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
import HomeMediumScreenSize from './HomeMediumScreenSize';
import HomeSmallScreenSize from './HomeSmallScreenSize';

export default {
  name: 'home',
  components: {
    HomeMediumScreenSize,
    HomeSmallScreenSize
  }
};
</script>
