import validator from 'validator';
import dateTimeManager from './date.time.manager';

export default {
  required(msg) {
    return value => !validator.isEmpty(value ? value.toString() : '') || msg;
  },
  isEmail(msg) {
    return value => validator.isEmail(value || '') || msg;
  },
  min(length, msg) {
    return value => value.length >= length || msg;
  },
  max(length, msg) {
    return value => value.length <= length || msg;
  },
  minValue(minValue, msg) {
    return value => value >= minValue || msg;
  },
  maxValue(maxValue, msg) {
    return value => maxValue > value || msg;
  },
  minMaxValue(min, max, msg) {
    return value => (value >= min && value <= max) || msg;
  },
  equal(prop, msg) {
    return value => validator.equals(value, prop) || msg;
  },
  minMaxArrayLength(min, max, msg) {
    return value => (value.length >= min && value.length <= max) || msg;
  },
  isSameOrBefore(date, msg) {
    return value => dateTimeManager.isSameOrBefore(value, date) || msg;
  },
  isSameOrAfter(date, msg) {
    return value => dateTimeManager.isSameOrAfter(value, date) || msg;
  },
  isInteger(msg) {
    return value => validator.isInt(value?.toString()) || msg;
  },
  isUrl(msg) {
    return value => {
      if (!value) {
        return true;
      }

      return validator.isURL(value) || msg;
    };
  },
  customValidator(func, msg) {
    return value => func(value) || msg;
  }
};
