import httpService from './http.service';

function index(params = {}) {
  return httpService.get('/user/missions', params);
}

function get(id, params = {}) {
  return httpService.get(`/user/missions/${id}`, params);
}

function create(payload) {
  return httpService.post('/user/missions', payload);
}

function copy(id, payload) {
  return httpService.post(`/user/missions/${id}/copy`, payload);
}

function complete(id) {
  return httpService.post(`/user/missions/${id}/complete`);
}

function update(id, payload) {
  return httpService.put(`/user/missions/${id}`, payload);
}

function setAsMain(id) {
  return httpService.patch(`/user/missions/${id}/main`);
}

function del(id) {
  return httpService.del(`/user/missions/${id}`);
}

export default {
  index,
  get,
  create,
  copy,
  update,
  complete,
  setAsMain,
  del
};
