import {
  SET_GROUPS,
  ADD_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  SET_CURRENT_GROUP,
  UPDATE_CURRENT_GROUP
} from './types';
import groupRoles from '../../enums/group.roles';

function updateMembers(members, leaderDisplayRole, memberDisplayRole) {
  const result = [];

  for (const member of members) {
    let displayRole = memberDisplayRole || groupRoles.member;

    if (member.groupRole === groupRoles.creator) {
      displayRole = groupRoles.creator;
    } else if (member.groupRole === groupRoles.leader) {
      displayRole = leaderDisplayRole || groupRoles.leader;
    }

    result.push({
      ...member,
      displayRole
    });
  }

  return result;
}

export default {
  [SET_GROUPS](state, groups) {
    state.groups = groups;
  },
  [SET_CURRENT_GROUP](state, group) {
    state.currentGroup = group;
  },
  [ADD_GROUP](state, group) {
    state.groups.push(group);
  },
  [UPDATE_GROUP](state, group) {
    const index = state.groups.findIndex(g => g.id === group.id);

    if (index !== -1) {
      state.groups.splice(index, 1, {
        ...state.groups[index],
        ...group
      });
    }
  },
  [UPDATE_CURRENT_GROUP](state, group) {
    state.currentGroup = {
      ...state.currentGroup,
      ...group,
      members: updateMembers(
        state.currentGroup.members,
        group.leaderRoleDisplayName,
        group.memberRoleDisplayName
      )
    };
  },
  [DELETE_GROUP](state, id) {
    const index = state.groups.findIndex(group => group.id === id);

    if (index !== -1) {
      state.groups.splice(index, 1);
    }
  }
};
