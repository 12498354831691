import Vue from 'vue';
import axios from 'axios';
import qs from 'qs';
import authSessionService from './auth.session.service';
import store from '../store';
import { SET_NOTIFICATIONS_COUNT } from '../store/notifications/types';
import dateTimeManager from '../common/date.time.manager';
import i18n from '../translations';
import errorsService from './errors.service';
import apiErrors from '../enums/api.errors';
import langHelper from '../common/lang.helper';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
  timeout: 30000,
  paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
});

http.interceptors.request.use(
  config => {
    config.headers['x-access-token'] = authSessionService.getToken();
    config.headers['x-timezone-offset'] = dateTimeManager.getTimezoneOffset();
    config.headers['Accept-Language'] = langHelper.getLanguage();

    return config;
  },
  err => Promise.reject(err)
);

http.interceptors.response.use(
  response => {
    const { data } = response;

    if (data.notificationsCount && data.notificationsCount > 0) {
      store.dispatch(SET_NOTIFICATIONS_COUNT, data.notificationsCount || 0);
    }

    return data;
  },
  err => {
    const error =
      err.response && err.response.data
        ? err.response.data.error
        : apiErrors.defaultServerError;

    Vue.notify({
      type: 'error',
      text: i18n.tc(`errors.${error.message}`)
    });

    errorsService.handleApiErrors(error);

    return Promise.reject(error);
  }
);

async function get(url, params) {
  const promise = http.get(url, { params });

  return promise;
}

async function getFile(url, params) {
  const promise = http
    .get(url, { params, responseType: 'blob' })
    .then(response => window.URL.createObjectURL(new Blob([response])));

  return promise;
}

async function post(url, data = {}) {
  const promise = http.post(url, data);

  return promise;
}

async function postFile(url, formData) {
  const promise = http
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      const fileUrl = window.URL.createObjectURL(new Blob([response]));
      return fileUrl;
    });

  return promise;
}

async function put(url, data = {}) {
  const promise = http.put(url, data);

  return promise;
}

async function patch(url, data = {}) {
  const promise = http.patch(url, data);

  return promise;
}

async function del(url) {
  const promise = http.delete(url);

  return promise;
}

export default {
  get,
  getFile,
  post,
  postFile,
  put,
  patch,
  del
};
