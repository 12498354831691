<template>
  <img class="d-inline" :src="require('../../assets/images/icons/coin.png')" />
  <!-- <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="m325.6 127 2.931 130.108-72.531 127.293-73.479-130.799 3.879-126.602 69.126-30z"
        fill="#c1f261"
      />
      <path
        d="m256 97.203v287.198l72.532-127.294-2.932-130.107z"
        fill="#a0e65c"
      />
      <g>
        <g id="Gem_18_">
          <g>
            <path
              d="m186.4 127-38.644 143.7-116.756 113.701v-257.401l77.7-30z"
              fill="#a0e65c"
            />
          </g>
        </g>
        <path
          d="m325.6 127h-139.2l4.8-64.35 64.8-62.65 64.8 62.65z"
          fill="#a0e65c"
        />
        <path
          d="m481 127v257.401l-114.355-109.723-41.045-147.678 77.7-30z"
          fill="#79cc52"
        />
        <path
          d="m256 384.401-112.5 30-112.5-30 155.4-257.401z"
          fill="#79cc52"
        />
        <path d="m481 384.401-112.5 30-112.5-30 69.6-257.401z" fill="#59b348" />
        <path
          d="m481 384.401-225 127.599-30-70.358 30-57.241z"
          fill="#79cc52"
        />
        <path d="m256 384.401v127.599l-225-127.599z" fill="#59b348" />
      </g>
      <path d="m325.6 127-4.799-64.351-64.801-62.649v127z" fill="#79cc52" />
      <g fill="#59b348">
        <path d="m256 0-69.6 127h-155.4z" />
        <path d="m481 127h-155.4l-69.6-127z" />
      </g>
    </g>
  </svg> -->
</template>

<script>
export default {
  name: 'gem-icon'
};
</script>
