export default {
  common: {
    requiredField: 'Полето е задължително!',
    invalidEmail: 'Невалиден имейл адрес!',
    name: 'Име',
    email: 'Електронна поща',
    password: 'Парола',
    confirmPassword: 'Потвърди паролата',
    passwordMismatch: 'Паролата не съвпада!',
    settings: 'Настройки',
    save: 'Запиши',
    close: 'Затвори',
    imageUrl: 'Адрес на снимката',
    oldPassword: 'Стара парола',
    newPassword: 'Нова парола',
    confirmNewPassword: 'Потвъди новата парола',
    successfullySavedChanges: 'Успешно запазихте промените!',
    minMaxValue: 'Мин. {min}, Макс. {max}',
    minLength: 'Минимално {value} символа',
    minValue: 'Мин. стойност {value}',
    maxValue: 'Макс. стойност {value}',
    invalidUrl: 'Невалиден адрес',
    edit: 'Промени',
    delete: 'Изтрий',
    no: 'Не',
    yes: 'Да',
    title: 'Заглавие',
    notes: 'Бележки',
    description: 'Описание',
    gold: 'Злато',
    minMaxGold: 'Мин. злато {minGold}, макс. злато {maxGold}',
    wonGold: 'Спечелихте {gold} злато!',
    lostGold: 'Загубихте {gold} злато!',
    or: 'Или',
    dashboard: 'Табло',
    from: 'От',
    to: 'До',
    clone: 'Копирай',
    archive: 'Архивирай',
    change: 'Промени',
    language: 'Език',
    true: 'Истина',
    false: 'Лъжа',
    congratulations: 'Поздравления',
    gainedBonusGold: 'Спечилихте бонус злато',
    continue: 'Продължи',
    startNewDay: 'Стартирахме вашия ден!',
    newVersion: 'Имаме нова версия!',
    clickRefresh: 'Моля, натиснете "Обнови"',
    refresh: 'Обнови',
    goldInteger: 'Златото е цяло число!',
    rubyInteger: 'Рубините са цяло число!',
    mustBeInteger: 'Трябва да е цяло число!',
    board: 'Борд',
    viewDetails: 'Детайли',
    add: 'Добави',
    invite: 'Покани',
    seeAll: 'Виж всички',
    ruby: 'Рубини',
    requireApprove: 'Изисква одобрение',
    approved: 'Одобрена',
    getStarted: 'Включете се сега',
    home: 'Начало',
    note: 'ВНИМАНИЕ',
    woohoo: 'Юхуу!',
    support: 'Подкрепи',
    gems: 'Gems',
    current: 'Текущ',
    quantity: 'Брой',
    finalPrice: 'Крайна цена',
    goToDashboard: 'Към таблото със задачи',
    goToStore: 'Към маркета',
    account: 'Акаунт',
    apply: 'Приложи',
    select: 'Избери',
    toward: 'към',
    finalGems: 'Брой Gems',
    profileImageHint:
      'Временно няма да можете да смените своята профилна снимка. Извиняваме се за неудобството!',
    refuse: 'Отказ',
    selectAll: 'Избери всички',
    assignTo: 'Добави към',
    approveAll: 'Одобри всички',
    refuseAll: 'Откажи всички',
    result: 'Резултат',
    reopen: 'Преотвори',
    enabled: 'Включен',
    disabled: 'Изключен'
  }
};
