<template>
  <div class="partners">
    <v-container class="pa-6">
      <h1
        class="grey--text text--darken-3 text-h4 text-center font-weight-bold mb-4"
      >
        {{ $t('partners.title') }}
      </h1>
      <v-row justify="center" class="mt-6">
        <v-col cols="10">
          <v-card>
            <a href="https://foody.to/" target="_blank">
              <v-img contain height="200" :src="images.foody"></v-img>
            </a>
          </v-card>
        </v-col>
        <v-col cols="10">
          <v-card>
            <a href="https://gamifinno.com/bg/kreatino/" target="_blank">
              <v-img contain height="200" :src="images.kreatino"></v-img>
            </a>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'partners-medium-size',
  data() {
    return {
      model: null,
      images: {
        foody: require('../../assets/images/partners/foody.png'),
        kreatino: require('../../assets/images/partners/kreatino.jpg')
      }
    };
  }
};
</script>
