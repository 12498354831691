<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular pr-3">
        {{ modalTitle }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container fluid grid-list-md>
      <v-row justify="center" v-if="buyMore && !isAvatarsStoreRoute">
        <v-btn @click="goToStore()" dark color="app-blue">
          {{ $t('avatars.buyMoreAvatars') }}
        </v-btn>
      </v-row>
      <v-row v-if="avatars.length > 0">
        <v-col
          md="3"
          sm="4"
          cols="6"
          v-for="avatar of avatars"
          :key="avatar.id"
          class="my-1"
        >
          <avatar
            :avatar="avatar"
            @changeProfileAvatar="changeProfileAvatar"
          ></avatar>
        </v-col>
      </v-row>
      <div v-else class="text-center py-4">
        <h1 class="heading app-dark-gray--text font-weight-light">
          {{ $t('avatars.noAvatarsFound') }}
        </h1>
      </div>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from './Avatar';
import httpAvatarsService from '../../services/avatars/avatars.http.service';
import { UPDATE_USER_AVATAR } from '../../store/users/types';

export default {
  name: 'my-avatars-modal',
  components: {
    Avatar
  },
  props: {
    title: {
      type: String
    },
    buyMore: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    modalTitle() {
      return this.title || this.$i18n.t('avatars.myAvatars');
    },
    isAvatarsStoreRoute() {
      return (
        this.$route.name === 'store' || this.$route.name === 'avatars-store'
      );
    }
  },
  data() {
    return {
      avatars: []
    };
  },
  created() {
    this.loadAvatars();
  },
  methods: {
    async loadAvatars() {
      const { result } = await httpAvatarsService.index();

      this.mapAvatars(result);
    },
    async mapAvatars(avatars) {
      let currentAvatarIndex = -1;
      const { avatar } = this.currentUser;

      if (avatar && avatar.id) {
        currentAvatarIndex = avatars.findIndex(a => a.id === avatar.id);
      }

      if (currentAvatarIndex > -1) {
        const currentAvatar = avatars[currentAvatarIndex];

        avatars.splice(currentAvatarIndex, 1);
        currentAvatar.isCurrent = true;
        avatars.unshift(currentAvatar);
      }

      this.avatars = avatars;
    },
    async changeProfileAvatar(avatar) {
      await this.$store.dispatch(UPDATE_USER_AVATAR, avatar);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('avatars.successAvatarChange')
      });

      this.$emit('close');
    },
    goToStore() {
      this.$emit('close');
      this.$router.push('/store/avatars');
    }
  }
};
</script>
