import Vue from 'vue';
import dateTimeManager from '../common/date.time.manager';
import amountFormatterService from '../services/shared/amount.formatter.service';

Vue.filter('dateFormat', value => {
  if (!value) {
    return '';
  }

  return dateTimeManager.formatDate(value);
});

Vue.filter('localDateTimeFormat', (value, format = 'DD.MM.YYYY HH:mm') => {
  if (!value) {
    return '';
  }

  return dateTimeManager.localDateTimeFormat(value, format);
});

Vue.filter('numberFormat', value => {
  if (!value) {
    return '0';
  }

  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
});

Vue.filter('amount', value => {
  if (!value) {
    return '';
  }

  return amountFormatterService.formatDisplayValue(value);
});
