export default {
  termsAndConditions: {
    lastUpdateDate: 'Last Updated on August 23, 2022',
    and: 'and',
    title: 'Terms and Conditions',
    formText:
      'By clicking the button below, you are indicating that you have read and agree to the',
    document: {
      title: 'Website Terms and Conditions of Use',
      termsSection: {
        title: '1. Terms',
        first: `By accessing this Website, accessible from gamifinnopoly.com (‘Site’ or ‘Gamifinnopoly’), 
          you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible 
          for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. 
          The materials contained in this Website are protected by copyright and trademark law.`,
        second: `Gamifinnopoly provides services through our software applications for various devices and platforms and the 
          ‘gamifinnopoly.com’ domain, and any sub domains thereto (the 'Sites'). The Gamifinnopoly domain and all relevant applications may be referred to as the 'Services'.`,
        third: `Please read the following terms and conditions carefully. These Terms of Service govern your access to and use of 
          the Services and set forth the legally binding terms for your use of the Services, whether you have registered as a User.        `,
        fourth: `YOU ACKNOWLEDGE AND AGREE THAT, BY CLICKING ON THE 'I AGREE' OR 'I ACCEPT' BUTTON, OR BY ACCESSING OR USING THE SERVICES OR BY DOWNLOADING OR POSTING ANY 
          CONTENT FROM OR ON THE SITES OR THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE READ, 
          UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS, WHETHER OR NOT YOU HAVE REGISTERED AS A MEMBER, 
          AND AGREE TO OUR PRIVACY POLICY AS DESCRIBED BELOW. IF YOU DO NOT AGREE TO THESE TERMS, 
          THEN YOU HAVE NO RIGHT TO ACCESS OR USE THE SERVICES OR ANY GAMIFINNOPOLY CONTENT.        `
      },
      licenseSection: {
        title: '2. Use License',
        first: {
          text: `Permission is granted to temporarily download one copy of the materials on Gamifinnopoly's Website for personal, non-commercial transitory viewing only. 
            This is the grant of a license, not a transfer of title, and under this license you may not:`,
          firstItem: `modify or copy the materials;`,
          secondItem: `use the materials for any commercial purpose or for any public display;`,
          thirdItem: `attempt to reverse engineer any software contained on Gamifinnopoly's Website;`,
          fourthItem: `remove any copyright or other proprietary notations from the materials; or`,
          fifthItem: `transferring the materials to another person or "mirror" the materials on any other server.`
        },
        second: `This will let Gamifinnopoly to terminate upon violations of any of these restrictions. Upon termination, 
          your viewing right will also be terminated, and you should destroy any downloaded materials in your possession whether it is printed or electronic format.`
      },
      disclaimerSection: {
        title: '3. Disclaimer',
        first: `All the materials on Gamifinnopoly’s Website are provided "as is". Gamifinnopoly makes no warranties, may it be expressed or implied, therefore negates all other warranties. 
          Furthermore, Gamifinnopoly does not make any representations concerning the accuracy or reliability of the use of the materials on its 
          Website or otherwise relating to such materials or any sites linked to this Website.`
      },
      limitationSection: {
        title: '4. Limitations',
        first: `Gamifinnopoly or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on 
          Gamifinnopoly’s Website, even if Gamifinnopoly or an authorize representative of this Website has been notified, orally or 
          written, of the possibility of such damage. Some jurisdictions do not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.`
      },
      revisionsSection: {
        title: '5. Revisions and Errata',
        first: `The materials appearing on Gamifinnopoly’s Website may include technical, typographical, or photographic errors. 
          Gamifinnopoly will not promise that any of the materials in this Website are accurate, complete, or current. 
          Gamifinnopoly may change the materials contained on its Website at any time without notice. Gamifinnopoly does not make 
          any commitment to update the materials.`
      },
      linksSection: {
        title: '6. Links',
        first: `Gamifinnopoly has not reviewed all the sites linked to its Website and is not responsible for the contents of any such linked site. 
          Links to such websites or resources do not imply any endorsement by Gamifinnopoly of such websites or resources or the content, products, 
          or services available from such websites or resources. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources.`
      },
      siteTermsSection: {
        title: '7. Site Terms of Use Modifications',
        first: `Gamifinnopoly may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.`
      },
      yourPrivacySection: {
        title: '8. Your Privacy',
        first: `Please read our Privacy Policy.`
      },
      eligibilitySection: {
        title: '9. Eligibility and Gamifinnopoly Account Registration',
        first: `To access certain features of the Site and its services, and to post any Public User Content (defined below) on the Sites or through its Services, you must register to create an account (‘Gamifinnopoly Account') and become a 'User'. 
          In compliance with privacy laws, <strong>we do not allow people below the age of 13</strong> to create accounts; 
          please see our Privacy Policy for further information. During the registration process, you will be required to provide certain information 
          and you will establish a username and a password. You agree to provide accurate, current, and complete information during the registration 
          process and to update such information to keep it accurate, current, and complete. Gamifinnopoly reserves the right to suspend or terminate your 
          Gamifinnopoly Account if any information provided during the registration process or thereafter proves to be inaccurate, not current, or incomplete. 
          You are responsible for safeguarding your password. You agree not to disclose your password to any third party and to take sole responsibility for any activities 
          or actions under your Gamifinnopoly Account, whether or not you have authorized such activities or actions. You agree to immediately notify Gamifinnopoly of any unauthorized use of your Gamifinnopoly Account. 
          We are not liable for any damages or losses caused by someone using your account without your permission.`
      },
      ownershipSection: {
        title: '10. Ownership',
        first: `The Gamifinnopoly Services and Content are protected by copyright, trademark, and other laws. Except as expressly provided in these Terms of Service, 
          Gamifinnopoly and its licensors exclusively own all right, title, and interest in and to the Gamifinnopoly Services and Content, 
          including all associated intellectual property rights. You will not remove, alter, or obscure any copyright, trademark, service mark or other proprietary 
          rights notices incorporated in or accompanying the Services or Gamifinnopoly Content.`
      },
      prohibitionsSection: {
        title: '11. General Prohibitions',
        first: {
          text: `You agree not to do any of the following while using the Gamifinnopoly Services or Content:`,
          firstItem: {
            title: `Post, upload, publish, submit, or transmit any text, graphics, images, software, music, audio, video, information, or other material that:`,
            firstItem: `infringes, misappropriates or violates a third party's patent, copyright, trademark, trade secret, moral rights or other intellectual property rights, or rights of publicity or privacy;`,
            secondItem: `violates, or encourages any conduct that would violate, any applicable law or regulation or would give rise to civil liability;`,
            thirdItem: `is fraudulent, false, misleading, or deceptive; (iv) is defamatory, obscene, pornographic, vulgar or offensive;`,
            fourthItem: `promotes discrimination, bigotry, racism, hatred, harassment or harm against any individual or group;`,
            fifthItem: `is violent or threatening or promotes violence or actions that are threatening to any other person; or`,
            sixthItem: `promotes illegal or harmful activities or substances (including but not limited to activities that promote or provide instructional information regarding the manufacture or purchase of illegal weapons or illegal substances).`
          },
          secondItem: `Use, display, mirror, frame or utilize framing techniques to enclose the Services, or any individual element or materials within the Services, Gamifinnopoly's name, any Gamifinnopoly trademark, logo or other proprietary information, 
            the content of any text or the layout and design of any page or form contained on a page, without Gamifinnopoly's express written consent.`,
          thirdItem: `Access, or use non-public areas of the Services, Gamifinnopoly's computer systems, or the technical delivery systems of Gamifinnopoly's providers.`,
          fourthItem: `Attempt to probe, scan, or test the vulnerability of any Gamifinnopoly system or network or breach any security or authentication measures.`,
          fifthItem: `Avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by Gamifinnopoly or any of Gamifinnopoly's providers or any other third party (including another user) to protect the Gamifinnopoly Services or Content.`,
          sixthItem: `Attempt to access or search the Gamifinnopoly Services or Content or download Gamifinnopoly Content from the Services using any engine, software, tool, 
            agent, device, or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the software and/or search agents provided by 
            Gamifinnopoly or other generally available third-party web browsers (such as Google Chrome, Microsoft Internet Explorer, Mozilla Firefox, Safari or Opera).`,
          seventhItem: `Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form of solicitation.`,
          eighthItem: `Use any meta tags or other hidden text or metadata utilizing a Gamifinnopoly trademark, logo URL or product name without Gamifinnopoly's express written consent.`,
          ninthItem: `Use the Services or Gamifinnopoly Content for any commercial purpose or the benefit of any third party or in any manner not permitted by these Terms of Service.`,
          tenthItem: `Forge any TCP/IP packet header or any part of the header information in any email or newsgroup posting, or in any way use the Gamifinnopoly Services or Content to send altered, deceptive, or false source-identifying information.`,
          eleventhItem: `Attempt to decipher, decompile, disassemble, or reverse engineer any of the software used to provide the Gamifinnopoly Services or Content.`,
          twelfthItem: `Interfere with, or attempt to interfere with, the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the Services.`,
          thirteenthItem: `Collect or store any personally identifiable information from the Services from other users of the Services without their express permission.`,
          fourteenthItem: `Impersonate or misrepresent your affiliation with any person or entity; `,
          fifteenthItem: `Violate any applicable law or regulation; or`,
          sixteenthItem: `Encourage or enable any other individual to do any of the foregoing.`
        },
        second: `Gamifinnopoly will have the right to investigate and prosecute violations of any of the above, including intellectual property rights infringement and Services 
          security issues, to the fullest extent of the law. Gamifinnopoly may involve and cooperate with law enforcement authorities in prosecuting users 
          who violate these Terms of Service. You acknowledge that Gamifinnopoly has no obligation to monitor your access to or use of the Services or Gamifinnopoly 
          Content or to review or edit any Public User Content but has the right to do so for the purpose of operating the Services, to ensure your compliance with these Terms of Service, 
          or to comply with applicable law or the order or requirement of a court, administrative agency, or other governmental body. Gamifinnopoly reserves the right, 
          at any time and without prior notice, to remove or disable access to any Gamifinnopoly Content, including, any Public User Content, that Gamifinnopoly, 
          in its sole discretion, considers to be in violation of these Terms of Service or otherwise harmful to the Services.`
      },
      interactionsSection: {
        title: '12. Interactions between Users',
        first: `You are solely responsible for your interactions (including any disputes) with other users. 
          You understand that Gamifinnopoly does not in any way screen Gamifinnopoly users, except to only allow people aged 13 
          and over to create accounts. You are solely responsible for, and will exercise caution, discretion, common sense, and 
          judgment in, using the Services and disclosing personal information to other Gamifinnopoly users. You agree to take 
          reasonable precautions in all interactions with other Gamifinnopoly users, particularly if you decide to meet a 
          Gamifinnopoly user offline, or in person. Your use of the Services, Gamifinnopoly Content and any other content made 
          available through the Services is at your sole risk and discretion and Gamifinnopoly hereby disclaims all liability to 
          you or any third party relating thereto. Gamifinnopoly reserves the right to contact Members, in compliance with applicable 
          law, to evaluate compliance with the rules and policies in these Terms of Service. You will cooperate fully with 
          Gamifinnopoly to investigate any suspected unlawful, fraudulent, or improper activity, including, without limitation, 
          granting authorized Gamifinnopoly representatives’ access to any password-protected portions of your Gamifinnopoly Account.`
      },
      liabilitySection: {
        title: '13. Limitation of Liability',
        first: `YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS 
          TO AND USE OF THE SERVICES AND CONTENT THEREIN REMAINS WITH YOU. NEITHER GAMIFINNOPOLY NOR ANY OTHER PARTY INVOLVED IN 
          CREATING, PRODUCING, OR DELIVERING THE SERVICES OR GAMIFINNOPOLY CONTENT WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, 
          EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA OR LOSS OF GOODWILL, SERVICE INTERRUPTION, 
          COMPUTER DAMAGE OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR SERVICES, ARISING OUT OF OR IN CONNECTION WITH 
          THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES OR CONTENT THEREIN, WHETHER BASED ON WARRANTY, CONTRACT, 
          TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT GAMIFINNOPOLY HAS BEEN 
          INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS 
          ESSENTIAL PURPOSE. YOU SPECIFICALLY ACKNOWLEDGE THAT GAMIFINNOPOLY IS NOT LIABLE FOR THE DEFAMATORY, OFFENSIVE, OR 
          ILLEGAL CONDUCT OF OTHER USERS OR THIRD PARTIES AND THAT THE RISK OF INJURY FROM THE FOREGOING RESTS ENTIRELY WITH YOU. 
          FURTHER, GAMIFINNOPOLY WILL HAVE NO LIABILITY TO YOU OR TO ANY THIRD PARTY FOR ANY PUBLIC USER CONTENT OR THIRD-PARTY 
          CONTENT UPLOADED ONTO OR DOWNLOADED FROM THE SITES OR THROUGH THE SERVICES.`,
        second: `IN NO EVENT WILL GAMIFINNOPOLY'S AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF SERVICE OR 
          FROM THE USE OF OR INABILITY TO USE THE SITE, SERVICES OR CONTENT THEREIN EXCEED ONE HUNDRED BULGARIAN LEVS (BGL 100). 
          THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN GAMIFINNOPOLY AND YOU. 
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE 
          ABOVE LIMITATION MAY NOT APPLY TO YOU.`
      },
      governingSection: {
        title: '14. Governing Law',
        first: `Any claim related to Gamifinnopoly's Website shall be governed by the laws of the Republic of Bulgaria without regards to its conflict of law provisions.`
      },
      contactingUsSection: {
        title: '15. Contacting Us',
        first: `If you have any questions about these Terms of Service, please contact us at <strong>info@gamifinno.com</strong>`
      }
    }
  },
  privacyPolicy: {
    lastUpdateDate: 'Last Updated on August 23, 2022',
    title: 'Privacy Policy',
    document: {
      title: 'Privacy Policy for GAMIFINNOPOLY.COM',
      first: `At gamifinnopoly.com (or ‘Gamifinnopoly’), accessible from gamifinnopoly.com, one of our main priorities is the privacy of our visitors. 
        This Privacy Policy document contains types of information that is collected and recorded by gamifinnopoly.com and how we use it.`,
      second: `If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at <strong>info@gamifinno.com</strong>`,
      // logFilesSection: {
      //   title: '1. Log Files',
      //   first: `Gamifinnopoly follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part
      //     of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type,
      //     Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked
      //     to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site,
      //     tracking users' movement on the website, and gathering demographic information.`
      // },
      // cookiesSection: {
      //   title: '2. Cookies and Web Beacons',
      //   first: `Like any other website, Gamifinnopoly uses 'cookies'. These cookies are used to store information including visitors' preferences,
      //     and the pages on the website that the visitor accessed or visited. The information is used to optimize the users'
      //     experience by customizing our web page content based on visitors' browser type and/or other information.`,
      //   second: `For more general information on cookies, please read "What Are Cookies" from <a href="https://www.cookieconsent.com/what-are-cookies/" target="_blank">Cookie Consent</a>.`
      // },
      // dartCookieSection: {
      //   title: '3. Google DoubleClick DART Cookie',
      //   first: `Google is one of a third-party vendors on our site. It also uses cookies, known as DART cookies, to serve ads to our site
      //     visitors based upon their visit to www.website.com and other sites on the internet. However, visitors may choose to decline
      //     the use of DART cookies by visiting the Google ad and content network Privacy Policy at the following URL – https://policies.google.com/technologies/ads`
      // },
      // advertisingSection: {
      //   title: '4. Our Advertising Partners',
      //   first: `Some of advertisers on our site may use cookies and web beacons. Our advertising partners are listed below. Each of our advertising partners has their own Privacy Policy for their policies on user data. For easier access, we hyperlinked to their Privacy Policies below.`
      // },
      // googleSection: {
      //   title: '5. Google',
      //   first: 'https://policies.google.com/technologies/ads'
      // },
      // policiesSection: {
      //   title: '6. Privacy Policies',
      //   first: `You may consult this list to find the Privacy Policy for each of the advertising partners of Gamifinnopoly.`,
      //   second: `Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used
      //     in their respective advertisements and links that appear on www.gamifinnopoly.com, which are sent directly to users' browser.
      //     They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of
      //     their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.`,
      //   third: `Note that Gamifinnopoly has no access to or control over these cookies that are used by third-party advertisers.`
      // },
      // thirdPartypoliciesSection: {
      //   title: '7. Third Party Privacy Policies',
      //   first: `Gamifinnopoly Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult
      //     the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their
      //     practices and instructions about how to opt-out of certain options.`,
      //   second: `You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management
      //     with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?`
      // },
      infoWeCollect: {
        title: '1. Information We Collect',
        accountRegistration: {
          title: 'Account registration.',
          text: 'When you register to Gamifinnopoly, we may ask for your contact information, including items such as name, email address, and password. We use this account information to create your account, including to verify your identity. If you choose to log into your account through Google, we capture and store the User ID, name and email address connected to the respective account, so we can verify your identity when you log in'
        },
        paymentInformation: {
          title: 'Payment information.',
          text: 'When you make, or attempt to make a purchase as a user in Gamifinnopoly, we do not collect any information from you. All the payment data you enter is stored to third-party payment processor. Currently we use Stripe as a payment service provider (<a href="https://stripe.com/en-bg">https://stripe.com/en-bg</a>)'
        },
        contactInformation: {
          title: 'Contact/Communication Information.',
          text: 'We collect contact information through our Service. If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.'
        },
        technicalInfomation: {
          title: 'Server Log, Device, Cookies and Other Technical Information.',
          text: {
            first:
              'As it is true for websites, we gather certain information automatically and store it in log files. When you use our Services, we may collect certain information from your device like internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, landing page, and referring URL. To collect this information, a cookie may be set on your computer or device when you visit our Services. Cookies contain a small amount of information that allows our web servers to recognize you. We store information that we collect through cookies, log files, and/or clear gifs to record your preferences.',
            second:
              'If you do not want us to place a cookie on your device, you may be able to turn that feature off on your device. You may refuse to accept cookies from the Service at any time by activating the setting on your browser which allows you to refuse cookies.'
          }
        },
        geolocationInformation: {
          title: 'Geolocation Information.',
          text: 'We may, if you allow us, to collect geolocation information from your device via your browser’s location Service. This consent may be provided by you on the device level or by accepting our request for geolocation access on the Service. Please consult your browser’s documentation regarding how to turn off location Service. If you disable location Service, you may not be able to use the full array of features and functionalities available through our Service.'
        }
      },
      howWeUseInformation: {
        title: '2. How We Use Information',
        first: 'Identify you when you use Gamifinnopoly;',
        second: 'Develop new products, services, features, and functionality;',
        third:
          'Communicate with you to provide you with updates and other information;',
        fourth: 'Send you text messages and push notifications;',
        fifth: 'Find and prevent fraud;'
      },
      howWeShareInformation: {
        title: '3. How We Share Information',
        aggregateInfo: {
          title: 'Aggregate Information.',
          text: 'When is legally allowed, we may share information about our users with our partners in aggregated form that can’t reasonably be used to identify you.'
        },
        advertising: {
          title: 'Advertising.',
          text: 'We may share information with third-party advertising partners to show you ads that we think may interest you'
        },
        consent: {
          title: 'With Your Consent.',
          text: 'With Your Consent. We may share information with your consent.'
        }
      },
      security: {
        title: '4. How we protect your information',
        text: 'Gamifinnopoly takes very seriously the security and privacy of the personal information that it collects pursuant to this Privacy Policy. We implement reasonable security measures designed to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction. Please note that no service is completely secure. While we strive to protect your data, we cannot guarantee that unauthorized access, hacking, data loss or a data breach will never occur.'
      },
      dataProtectionRights: {
        title: '5. Your Data Protection Rights',
        first:
          'If you wish to <strong>access, correct, update, or request deletion</strong> of your personal information, you can do so at any time by emailing <strong>info@gamifinno.com</strong>;',
        second:
          'If you contact us to <strong>delete</strong> all your account data the data will be deleted within 90 days after your request;',
        third:
          'In addition, you can object to the processing of your personal data, ask us to restrict the processing of your personal data, or request portability of your personal data. Again, you can exercise these rights by emailing <strong>info@gamifinno.com</strong>;',
        fourth:
          'You have the right to complain to a data protection authority about our collection and use of your personal data. For more information, please contact your local data protection authority;'
      },
      childrenInfoSection: {
        title: `6. Children's Information`,
        first: `Another part of our priority is adding protection for children while using the internet. 
          We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.`,
        second: `Gamifinnopoly <strong>does not allow people below the age of 13</strong> to create accounts and does not knowingly collect any 
          Personal Identifiable Information from them. If you think that your child provided this kind of information on our website, 
          we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information 
          from our records.`
      },
      onlinePoliciesSection: {
        title: '7. Online Privacy Policy Only',
        text: `This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in www.gamifinnopoly.com. 
          This policy is not applicable to any information collected offline or via channels other than this website.`
      },
      consentSection: {
        title: '8. Consent',
        text: `By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.`
      },
      contactingUsSection: {
        title: '9. Contacting Us',
        text: `If you have any questions about these Privacy Policy, please contact us at <strong>info@gamifinno.com</strong>`
      }
    }
  }
};
