export default {
  groups: {
    groups: 'Групи',
    info: 'Информация за групата',
    myGroups: 'Моите групи',
    noGroupsFound: 'Няма намерени групи!',
    createGroup: 'Създай на група',
    editGroup: 'Редактирай група',
    leaderRoleDisplayName: 'Персонализирана роля на Лидер',
    memberRoleDisplayName: 'Персонализирана роля на Участник',
    defaultLeaderRoleDisplayName: 'По подразбиране Лидер',
    defaultMemberRoleDisplayName: 'По подразбиране Участник',
    groupCreatedSuccessfully: 'Групата е създадена успешно!',
    groupUpdatedSuccessfully: 'Групата е редактирана успешно!',
    groupDeletedSuccessfully: 'Групата е изтрита успешно!',
    deleteGroupConfirm: 'Сигурни ли сте, че искате да изтриете тази група?',
    backToGroups: 'Обратно към групите',
    myGroupsStats: 'Статус на групите',
    cannotCreateMoreThan2Groups:
      'За текущата БЕТА версия на Gamifinnopoly, няма да можете да създавате повече от 2 групи!',
    youCanCreateGroups: 'Може да създадете още {count} група/и!',
    cannotCreateMoreGroups: 'Не може да създавате повече групи!',
    groupDashboard: 'Табло на групата',
    assignedUsers: 'Потребители'
  }
};
