import actions from './actions/index';
import mutations from './mutations';
import getters from './getters';

const state = {
  rewards: [],
  approvalRewards: []
};

export default {
  state,
  actions,
  mutations,
  getters
};
