export default {
  store: {
    store: 'Store',
    buyFor: 'Buy for',
    buyForFree: 'Get for free',
    buyGems: 'Buy Gems',
    whatCanBuy: 'What can you buy with Gems?',
    avatarsDesc: 'Awesome and fashionable Avatars for your profile',
    boardsDesc:
      'Amazing and shiny Boards with interesting facts, quotes, and etc.',
    payWith: 'Pay with',
    buyNow: 'Buy now',
    avatarsStore: 'Avatars Store',
    allAvatarCategories: 'All categories',
    boardsStore: 'Boards Store',
    creditCard: 'Credit/Debit Card',
    redirectingToStripe: 'Redirecting to Stripe...',
    errorWithStripe: 'An error occured with Stripe',
    thankYouForSupport: 'Thank you for your support!',
    gemsPurchased: `You successfully bought <strong class="app-green--text">{value}</strong> Gems!`
  }
};
