export default {
  errors: {
    somethingWentWrong:
      'We are experiencing technical issues. Our team is working on them. Thank you for your patience!',
    wrongCredentials: 'Invalid email address / password!',
    invalidData: 'Invalid data!',
    emailAlreadyUsed: 'Email address is already used!',
    passwordsMismatch: 'Passwords mismatch!',
    notSupportedSocialNetwork: 'Not supported social network!',
    userNotFound: 'User was not found!',
    incorrectOldPassword: 'The old password is wrong!',
    taskAlreadyCompleted: 'The task is already completed!',
    taskNotCompleted: 'The task is not completed!',
    cannotUpdateCompletedTask: 'Cannot update completed task!',
    cannotReopenToDoTask: 'Cannot reopen To Do task!',
    invalidTimezone: 'Invalid timezone!',
    invalidQueryParams: 'Invalid query params!',
    authenticationFailed: 'Authentication failed!',
    notAuthorized: 'Not authorized!',
    missionNotFound: 'Mission not found!',
    missionAlreadyCompletedOrFailed: 'Mission already completed or failed!',
    missionIsNotActive: 'Mission is not active!',
    missionTasksIncompleted: 'Mission tasks are incompleted!',
    cannotCompleteMissionWithoutTasks: 'Cannot complete mission without tasks!',
    taskNotFound: 'Task not found!',
    cannotCompleteArchivedTask: 'Cannot complete archived task!',
    cannotReopenArchivedTask: 'Cannot reopen archived task!',
    cannotArchiveIncompletedTask: 'Cannot archive incompleted task!',
    taskAlreadyArchived: 'Task already archived!',
    cannotCloneTask: 'Cannot clone task!',
    rewardNotFound: 'Reward not found!',
    notEnoughGoldToRedeemReward:
      'You don`t have enough gold to redeem this reward!',
    passwordRecoveryLinkExpired: 'Password recovery link expired!',
    cannotCloneActivePendingMission: 'Cannot clone active or pending mission!',
    yourAccountIsLocked:
      'Your account is locked! Please contact our support team for more information.',
    boardNotFound: 'Board not found!',
    notSpecialBoardPosition: 'The position is not special!',
    positionAlreadyUsed: 'Position is already used!',
    alreadyApproved: 'Task is approved and added to your tasks!',
    quoteNotFound: 'Quote not found!',
    factNotFound: 'Fact not found!',
    questionNotFound: 'Question not found!',
    groupNotFound: 'Group not found!',
    notAllowedToUpdateGroup: 'Not allowed to update this group!',
    notAllowedToManageGroup: 'Not allowed to manage this group or group items!',
    notPartOfThisGroup: 'Not part of this group!',
    youAreNotPartOfTheGroup: 'You are not part of the group!',
    notLeaderInThisGroup: 'Not creator or leader in this group!',
    notLeaderOrMemberRole: 'Not leader or member in this group!',
    notCreatorOfThisGroup: 'Not creator of this group!',
    cannotManageCreator: 'Cannot manage creator!',
    alreadyLeaderToGroup: 'Already leader in this group!',
    alreadyMemberRole: 'Already member role in this group!',
    userAlreadyInGroup: 'User is already in this group!',
    inviteAlreadyAccepted: 'Invitation has already been accepted!',
    thisIsNotYourInvitation: 'This in not your invitation!',
    notMemberRole: 'Not member role!',
    cannotAddTaskToYourself: 'Cannot add group task to yourself!',
    notGroupTask: 'Not group task!',
    taskNotInThisGroup: 'The task is not in this group!',
    memberNotFound: 'Member not found!',
    groupTaskApproveNotRequired: 'Group task approval not required!',
    groupTaskApproveAlreadyRequested:
      'Group task approval has already been requested!',
    groupTaskApproveNotRequested: 'Group task approval not requested!',
    cannotAskForApproveNotCompletedGroupTask:
      'Cannot ask for approval when group task is not completed!',
    cannotApproveNotCompletedGroupTask:
      'Cannot approve not completed group task!',
    notGroupReward: 'Not group reward!',
    cannotAddMissionToYourself: 'Cannot add group mission to yourself!',
    notGroupMission: 'Not group mission!',
    groupMissionAlreadyCompleted: 'Group mission has already been completed!',
    groupMissionApproveNotRequired: 'Group mission approval not required!',
    groupMissionApproveNotRequested: 'Group mission approval not requested!',
    groupMissionApproveAlreadyRequested:
      'Group mission approval already requested!',
    groupMissionWaitingForApprove: 'Group mission waiting for approval!',
    cannotApproveNotRequestedForApproveMission:
      'Cannot approve not requested for approval group mission!',
    groupInviteNotificationNotFound:
      'Notification for group invitation not found!',
    notEnoughRubyToRedeemReward:
      'You don`t have enough ruby to redeem this reward!',
    groupRewardApprovalAlreadyRequested:
      'Group reward approval already requested!',
    groupRewardApprovalNotRequired: 'Group reward approval not required!',
    groupRewardApprovalNotRequested: 'Group reward approval not requested!',
    tokenDoesNotExist: 'Token has expired or does not exist!',
    groupInvitationLinkExpired:
      'Group invitation link has expired or does not exist!',
    cannotCreateGroup: `For the BETA version of Gamifinnopoly you won't be able to create more than 2 groups!`,
    cannotInviteMembers: `For the BETA version of Gamifinnopoly you won't be able to add more than 5 members in a group!`,
    emailNotificationNotFound: 'Email notification does not exist!',
    notificationNotFound: 'Notification not found!',
    expiredToken: 'Token has expired!',
    accountAlreadyActivated: 'Account already activated!',
    accountActivationLinkExpired: 'Account activation link has expired!',
    yourAccountIsNotActivated: 'Account is not activated!',
    notEnoughGemsToBuyAvatar: `You don't have enough gems to buy this avatar!`,
    notEnoughGemsToBuyBoard: `You don't have enough gems to buy this board!`,
    avatarNotFound: 'Avatar not found!',
    notEnoughGoldToBuyAvatar: `You don't have enough gold to buy this avatar!`,
    notEnoughGoldToBuyBoard: `You don't have enough gold to buy this board!`,
    userDoesNotHaveThisAvatar: `You don't have this avatar! You should buy it!`,
    userDoesNotHaveThisBoard: `You don't have this board! You should buy it!`,
    avatarAlreadyBought: 'Avatar already bought!',
    boardAlreadyBought: 'Board already bought!',
    userDoesNotHaveEnoughGems: `You don't have enough Gems!`,
    cannotAddResult: 'Cannot add result to the task!',
    resultOfTheTaskRequired: 'Result of the task is required!',
    noTasksForApproval: 'There are no tasks waiting for approval!',
    waitingApprovalTasksExist:
      'Few users that have done the task are waiting for approval! Please, first approve them then update the task!',
    waitingApprovalRewardsExist:
      'Few users that have redeemed the reward are waiting for approval! Please, first approve them then update the reward!',
    cannotAddRewardToYourself: 'Cannot add group reward to yourself!',
    holidayModeEnabledError:
      'If you want to do this operation you must disable Holiday mode!',
    cannotGenerateTasks: 'We could not generate the tasks!',
    notEnoughGemsToGenerateTasks: 'Not enought Gems to generate tasks!',
    cannotGenerateRewards: 'We could not generate the rewards!',
    notEnoughGemsToGenerateRewards: 'Not enought Gems to generate rewards!',
    cannotGenerateAvatar: 'We could not generate an avatar!',
    notEnoughGemsToGenerateAvatar: 'Not enought Gems to generate avatar!'
  }
};
