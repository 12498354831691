export default {
  missions: {
    missions: 'Missions',
    myMissions: 'My missions',
    info: 'Mission info',
    createMission: 'Create mission',
    missionAdded: 'Mission added successfully!',
    editMission: 'Edit mission',
    deleteMissionConfirm: 'Are you sure you want to delete this mission?',
    fromDateBeforeCurrentDate: 'FROM date must be same or after current date!',
    fromDateAfterToDate: 'FROM date must be before TO date!',
    toDateBeforeFromDate: 'TO date must be after FROM date!',
    completeMissionBtn: 'Complete the mission',
    activatedMission: 'Mission "{name}" is activated!',
    failedMission: 'Mission "{name}" failed!',
    missionHasNoTasks: 'Mission has no tasks!',
    noMissionsFound: 'No missions found!',
    noActiveMissionsFound: 'No active missions!',
    noMainMissionFound: 'No main mission!',
    mainMission: 'Main mission',
    daysLeft: 'days',
    pending: 'Pending',
    completed: 'Completed',
    waitingApproval: 'Waiting approval',
    failed: 'Failed',
    archived: 'Archived',
    backToMissions: 'Back to missions',
    tasks: 'Mission tasks',
    missionClonedSuccessfully: 'Mission cloned successfully!',
    missionUpdatedSuccessfully: 'Mission updated successfully!',
    missionDeletedSuccessfully: 'Mission deleted successfully!',
    cloneMission: 'Clone mission',
    approvalMissions: 'Approval missions',
    noMissionsWaitingApprovalFound: 'No missions waiting for approval found!',
    аpproveAllMissions: 'All missions were approved!',
    refuseToApproveMissions: 'All missions were refused!',
    missionApproved: 'You approved the mission!',
    missionRefused: 'You did not approve the mission!',
    reopenMission: 'Reopen mission'
  }
};
