export default {
  rewards: {
    rewards: 'Награди',
    reward: 'Награда',
    redeem: 'Грабни',
    createReward: 'Добави награда',
    editReward: 'Редактирай награда',
    deleteRewardConfirm: 'Сигурни ли сте, че искате да изтриете тази награда?',
    usabilityTitle: 'Използваемост',
    constant: 'Постоянно',
    once: 'Еднократно',
    times: 'Няколко пъти',
    howManyTimes: 'Колко пъти?',
    redeemRewardConfirm: 'Сигурни ли сте, че искате да вземете тази награда?',
    noRewardsFound: 'Няма намерени награди!',
    redeemedReward: 'Успешно грабнахте награда си за {gold} злато!',
    redeemedGroupReward: 'Успешно грабнахте награда си за {ruby} рубина!',
    groupRewardWasSentForApproval: 'Наградата беше изпратена за одобрение!',
    waitingApproval: 'Изчаква одобрение',
    approvalRewards: 'Награди за одобрение',
    noRewardsWaitingApprovalFound:
      'Не са намерени награди, които чакат одобрение',
    аpproveAllRewards: 'Наградите бяха одобрени!',
    refuseToApproveRewards: 'Наградите не бяха одобрени!',
    rewardApproved: 'Одобрихте наградата!',
    rewardRefused: 'Наградата не беше одобрена!',
    reopenReward: 'Преотвори наградата',
    generateRewards: 'Генериране на награди',
    generateRewardsCostText:
      'Само въведете тема и бройка на наградите и ние ще ги генерираме за вас. Всяко ново генериране на награди ще ви струва ',
    topic: 'Тема (пример: Ваканция)',
    rewardsCountQuestion: 'Колко награди да генерираме?',
    addRewards: 'Добави наградите'
  }
};
