import { TOGGLE_SIDE_NAV, SELECT_BOTTOM_NAV_ITEM } from './types';

export default {
  async [TOGGLE_SIDE_NAV]({ commit }) {
    commit(TOGGLE_SIDE_NAV);
  },
  [SELECT_BOTTOM_NAV_ITEM]({ commit }, index) {
    commit(SELECT_BOTTOM_NAV_ITEM, index);
  }
};
