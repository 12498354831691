import groupRoles from '../../enums/group.roles';

export default {
  groups(state) {
    return state.groups;
  },
  currentGroup(state) {
    return state.currentGroup;
  },
  currentMember(state) {
    return state.currentGroup.currentMember;
  },
  isCreatorOrLeader(state) {
    return state.currentGroup.currentMember.groupRole !== groupRoles.member;
  },
  isGroupUserCreator(state) {
    return state.currentGroup.currentMember.groupRole === groupRoles.creator;
  }
};
