import store from '../store';

export default (to, from, next) => {
  const { isCreatorOrLeader } = store.getters;
  if (!isCreatorOrLeader) {
    next({
      path: '/dashboard'
    });
  }
  next();
};
