import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  members: [],
  currentMember: {}
};

export default {
  state,
  actions,
  getters,
  mutations
};
