export const SET_TASKS = 'SET_TASKS';
export const GET_TASKS = 'GET_TASKS';
export const GET_ARCHIVED_TASKS = 'GET_ARCHIVED_TASKS';
export const SET_ARCHIVED_TASKS = 'SET_ARCHIVED_TASKS';
export const ADD_TASK = 'ADD_TASK';
export const ADD_ARCHIVED_TASK = 'ADD_ARCHIVED_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const COMPLETE_TASK = 'COMPLETE_TASK';
export const REOPEN_TASK = 'REOPEN_TASK';
export const REOPEN_CRON_TASKS = 'REOPEN_CRON_TASKS';
export const ADD_RESULT = 'ADD_RESULT';
export const COPY_TASK = 'COPY_TASK';
export const ARCHIVE_TASK = 'ARCHIVE_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_ARCHIVED_TASK = 'DELETE_ARCHIVED_TASK';

export const CHANGE_TASKS_TYPES_FILTER = 'CHANGE_TASKS_TYPES_FILTER';
export const UPDATE_TASKS_TYPES_FILTER = 'UPDATE_TASKS_TYPES_FILTER';
export const RESET_TASKS_TYPES_FILTER = 'RESET_TASKS_TYPES_FILTER';

// Group tasks types
export const GET_GROUP_TASKS = 'GET_GROUP_TASKS';
export const GET_ARCHIVED_GROUP_TASKS = 'GET_ARCHIVED_GROUP_TASKS';

export const GET_APPROVAL_TASKS = 'GET_APPROVAL_TASKS';
export const SET_APPROVAL_TASKS = 'SET_APPROVAL_TASKS';
export const APPROVE_ALL_TASKS = 'APPROVE_ALL_TASKS';

export const ADD_GROUP_TASK = 'ADD_GROUP_TASK';
export const ADD_ARCHIVED_GROUP_TASK = 'ADD_ARCHIVED_GROUP_TASK';
export const UPDATE_GROUP_TASK = 'UPDATE_GROUP_TASK';
export const COMPLETE_GROUP_TASK = 'COMPLETE_GROUP_TASK';
export const ARCHIVE_GROUP_TASK = 'ARCHIVE_GROUP_TASK';
export const ADD_RESULT_GROUP_TASK = 'ADD_RESULT_GROUP_TASK';
export const COPY_GROUP_TASK = 'COPY_GROUP_TASK';
export const REOPEN_GROUP_TASK = 'REOPEN_GROUP_TASK';
export const APPROVE_GROUP_TASK = 'APPROVE_GROUP_TASK';
export const DELETE_GROUP_TASK = 'DELETE_GROUP_TASK';
export const DELETE_ARCHIVED_GROUP_TASK = 'DELETE_ARCHIVED_GROUP_TASK';
