export const GET_MISSION_ARCHIVED_TASKS = 'GET_MISSION_ARCHIVED_TASKS';
export const SET_MISSION_ARCHIVED_TASKS = 'SET_MISSION_ARCHIVED_TASKS';
export const ADD_MISSION_TASK = 'ADD_MISSION_TASK';
export const GENERATE_MISSION_TASKS = 'GENERATE_MISSION_TASKS';
export const ADD_MISSION_MULTIPLE_TASKS = 'ADD_MISSION_MULTIPLE_TASKS';
export const UPDATE_MISSION_TASK = 'UPDATE_MISSION_TASK';
export const COMPLETE_MISSION_TASK = 'COMPLETE_MISSION_TASK';
export const REOPEN_MISSION_TASK = 'REOPEN_MISSION_TASK';
export const ARCHIVE_MISSION_TASK = 'ARCHIVE_MISSION_TASK';
export const COPY_MISSION_TASK = 'COPY_MISSION_TASK';
export const DELETE_MISSION_TASK = 'DELETE_MISSION_TASK';

export const UPDATE_MISSION_TASKS_TYPES_FILTER =
  'UPDATE_MISSION_TASKS_TYPES_FILTER';
export const RESET_MISSION_TASKS_TYPES_FILTER =
  'RESET_MISSION_TASKS_TYPES_FILTER';

// Group mission tasks types
export const ADD_GROUP_MISSION_TASK = 'ADD_GROUP_MISSION_TASK';
export const UPDATE_GROUP_MISSION_TASK = 'UPDATE_GROUP_MISSION_TASK';
export const COMPLETE_GROUP_MISSION_TASK = 'COMPLETE_GROUP_MISSION_TASK';
export const REOPEN_GROUP_MISSION_TASK = 'REOPEN_GROUP_MISSION_TASK';
export const ARCHIVE_GROUP_MISSION_TASK = 'ARCHIVE_GROUP_MISSION_TASK';
export const COPY_GROUP_MISSION_TASK = 'COPY_GROUP_MISSION_TASK';
export const DELETE_GROUP_MISSION_TASK = 'DELETE_GROUP_MISSION_TASK';
