export default {
  common: {
    requiredField: 'Field is required!',
    invalidEmail: 'Invalid email address!',
    name: 'Name',
    email: 'Email address',
    password: 'Password',
    confirmPassword: 'Confirm password',
    passwordMismatch: 'Password mismatch!',
    settings: 'Settings',
    save: 'Save',
    close: 'Close',
    imageUrl: 'Image URL',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    successfullySavedChanges: 'The changes have been saved!',
    minMaxValue: 'Min {min}, Max {max}',
    minLength: 'Minimum {value} characters',
    minValue: 'Min value is {value}',
    maxValue: 'Max value is {value}',
    invalidUrl: 'Invalid Url',
    edit: 'Edit',
    delete: 'Delete',
    no: 'No',
    yes: 'Yes',
    title: 'Title',
    notes: 'Notes',
    description: 'Description',
    gold: 'Gold',
    minMaxGold: 'Min gold {minGold}, max gold {maxGold}',
    wonGold: 'You won {gold} gold!',
    lostGold: 'You lost {gold} gold!',
    or: 'Or',
    dashboard: 'Dashboard',
    from: 'From',
    to: 'To',
    clone: 'Clone',
    archive: 'Archive',
    change: 'Change',
    language: 'Language',
    true: 'True',
    false: 'False',
    congratulations: 'Congratulations',
    gainedBonusGold: 'You gained bonus gold!',
    continue: 'Continue',
    startNewDay: 'We have started your day!',
    newVersion: 'New version available!',
    clickRefresh: 'Please click refresh',
    refresh: 'Refresh',
    goldInteger: 'Gold must be integer!',
    rubyInteger: 'Ruby must be integer!',
    mustBeInteger: 'Must be integer!',
    board: 'Board',
    viewDetails: 'View details',
    add: 'Add',
    invite: 'Invite',
    seeAll: 'See all',
    ruby: 'Ruby',
    requireApprove: 'Require approve',
    approved: 'Approved',
    getStarted: "Get Started - It's free",
    home: 'Home',
    note: 'NOTE',
    woohoo: 'Woohoo!',
    support: 'Support',
    gems: 'Gems',
    current: 'Current',
    quantity: 'Quantity',
    finalPrice: 'Final price',
    goToDashboard: 'Go to dashboard',
    goToStore: 'Go to store',
    account: 'Account',
    apply: 'Apply',
    select: 'Select',
    toward: 'to',
    finalGems: 'Gems count',
    profileImageHint:
      'You will not be able to change your profile picture temporarily. We apologize for the inconvenience!',
    refuse: 'Refuse',
    selectAll: 'Select all',
    assignTo: 'Assign to',
    approveAll: 'Approve all',
    refuseAll: 'Refuse all',
    result: 'Result',
    reopen: 'Reopen',
    enabled: 'Enabled',
    disabled: 'Disabled'
  }
};
