import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import authSession from '../../services/auth.session.service';

const state = {
  isAuthenticated: authSession.isAuthenticated() || false,
  isLogin: false
};

export default {
  state,
  actions,
  mutations,
  getters
};
