export default {
  purchases: {
    myPurchases: 'Моите покупки',
    avatar: {
      title: 'Покупка на Аватар',
      text: 'Закупихте аватар за <strong>{value} {currency}</strong>'
    },
    board: {
      title: 'Покупка на Борд',
      text: 'Закупихте борд за <strong>{value} {currency}</strong>'
    },
    gems: {
      title: 'Закупихте Gems',
      text: 'Закупихте <strong>{gems} Gems</strong> за <strong>€{amount}</strong>'
    },
    noPurchasesFound: 'Нямате направени покупки!'
  }
};
