import httpService from './http.service';

const BASE_NOTIFICATIONS_URL = '/user/notifications';

function index(params = {}) {
  return httpService.get(BASE_NOTIFICATIONS_URL, params);
}

function getOldNotifications(params = {}) {
  return httpService.get(`${BASE_NOTIFICATIONS_URL}/old`, params);
}

function get(id, params = {}) {
  return httpService.get(`${BASE_NOTIFICATIONS_URL}/${id}`, params);
}

function markAsSeen(payload) {
  return httpService.put(`${BASE_NOTIFICATIONS_URL}/seen`, payload);
}

function del(id) {
  return httpService.del(`${BASE_NOTIFICATIONS_URL}/${id}`);
}

export default {
  index,
  getOldNotifications,
  get,
  markAsSeen,
  del
};
