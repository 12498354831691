export default {
  tasks: {
    tasks: 'Задачи',
    createTask: 'Добави задача',
    editTask: 'Редактирай задача',
    edit: {
      todo: 'Редактирай еднократна',
      daily: 'Редактирай дневна',
      weekly: 'Редактирай седмична'
    },
    reopen: {
      todo: 'Преотвори еднократна',
      daily: 'Преотвори дневна',
      weekly: 'Преотвори седмична'
    },
    info: {
      title: 'Информация за задача',
      todo: 'Информация еднократна',
      daily: 'Информация дневна',
      weekly: 'Информация седмична'
    },
    deleteTaskConfirm: 'Сигурни ли сте, че искате да изтриете тази задача?',
    maxTagsLength: 'Максимално {number} тага!',
    type: 'Тип',
    todo: 'Еднократна',
    daily: 'Дневна',
    weekly: 'Седмична',
    archived: 'Архив',
    noTasksFound: 'Няма намерени задачи!',
    taskArchived: 'Задачата беше архивирана!',
    taskCloned: 'Задачата беше копирана!',
    waitingForApprove: 'Изчаква се одобрение',
    reopenApprovedGroupTaskConfirm:
      'Сигурни ли сте, че искате да преотворите вече одобрена задача?',
    reopenApproveRequestedGroupTaskConfirm:
      'Сигурни ли сте, че искате да преотворите задача, която очаква одобрение?',
    requireResultWhenComplete: 'Добави резултат, когато завършиш задачата',
    addResult: 'Добави резултат',
    resultOfTheTask: 'Резултат',
    resultOfTheTaskSaved: 'Успешно запазихте резултата от задачата!',
    editResult: 'Промени резултата',
    approvalTasks: 'Задачи за одобрение',
    noTasksWaitingApprovalFound: 'Не са намерени задачи, които чакат одобрение',
    аpproveAllTasks: 'Задачите бяха одобрени!',
    refuseToApproveTasks: 'Задачите не бяха одобрени!',
    taskApproved: 'Одобрихте задачата!',
    taskRefused: 'Задачата не беше одобрена!',
    generateTasks: 'Генериране на задачи',
    generateTasksCostText:
      'Само въведете бройката на задачите и ние ще ги генерираме за вас. Всяко ново генериране на задачи ще ви струва ',
    tasksCountQuestion: 'Колко задачи да генерираме?',
    addTasksToMission: 'Добави задачите към мисията'
  }
};
