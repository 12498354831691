export default {
  missions: {
    missions: 'Мисии',
    myMissions: 'Моите мисии',
    info: 'Мисия',
    createMission: 'Създай мисия',
    missionAdded: 'Мисията беше създадена успешно!',
    editMission: 'Редактирай мисия',
    deleteMissionConfirm: 'Сигурни ли сте, че искате да изтриете тази мисия?',
    fromDateBeforeCurrentDate:
      'Началната дата трябва да бъде след или равна на текущата!',
    fromDateAfterToDate:
      'Началната дата трябва да бъде равна или преди крайната дата!',
    toDateBeforeFromDate:
      'Крайната дата трябва да бъде след или равна на началната!',
    completeMissionBtn: 'Приключи мисията!',
    activatedMission: 'Мисията "{name}" е активирана!',
    failedMission: 'Мисията "{name}" беше неуспешно изпълнена!',
    missionHasNoTasks: 'Мисията няма задачи!',
    noMissionsFound: 'Няма намерени мисии!',
    noActiveMissionsFound: 'Нямате активни мисии!',
    noMainMissionFound: 'Нямате главна мисия!',
    mainMission: 'Главна мисия',
    daysLeft: 'дни',
    pending: 'Чакаща',
    completed: 'Успешна',
    waitingApproval: 'Изчаква одобрение',
    failed: 'Провалена',
    archived: 'Архивирана',
    backToMissions: 'Обратно към мисиите',
    tasks: 'Задачи към мисията',
    missionClonedSuccessfully: 'Мисията е копирана успешно!',
    missionUpdatedSuccessfully: 'Мисията е редактирана успешно!',
    missionDeletedSuccessfully: 'Мисията е изтрита успешно!',
    cloneMission: 'Копиране на мисия',
    approvalMissions: 'Мисии за одобрение',
    noMissionsWaitingApprovalFound:
      'Не са намерени мисии, които чакат одобрение',
    аpproveAllMissions: 'Мисиите бяха одобрени!',
    refuseToApproveMissions: 'Мисиите не бяха одобрени!',
    missionApproved: 'Одобрихте мисията!',
    missionRefused: 'Мисията не беше одобрена!',
    reopenMission: 'Преотвори мисията'
  }
};
