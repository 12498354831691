import {
  ADD_GROUP_MISSION_TASK,
  ADD_MISSION_TASK,
  UPDATE_GROUP_MISSION_TASK,
  UPDATE_MISSION_TASK,
  UPDATE_MISSION_TASKS_TYPES_FILTER,
  COMPLETE_GROUP_MISSION_TASK,
  REOPEN_GROUP_MISSION_TASK,
  ARCHIVE_GROUP_MISSION_TASK,
  COPY_GROUP_MISSION_TASK,
  DELETE_GROUP_MISSION_TASK,
  DELETE_MISSION_TASK
} from '../types';
import { UPDATE_PROFILE } from '../../../users/types';
import { UPDATE_CURRENT_GROUP_MEMBER_STATS } from '../../../group-members/types';
import groupMissionTasksHttpService from '../../../../services/groups/group.mission.tasks.service';
import tasksMapper from '../../../../mappers/tasks.mapper';
import tasksHelper from '../../../../common/tasks.helper';

function getSelectedFilterTypes(filter) {
  return filter.filter(f => f.selected).map(f => f.type);
}

function isFilterTypeNotSelected(selectedFilters, type) {
  return selectedFilters.length > 0 && selectedFilters.indexOf(type) === -1;
}

export default {
  async [ADD_GROUP_MISSION_TASK]({ commit, rootState }, payload) {
    const { groupId, missionId, task } = payload;

    const { result } = await groupMissionTasksHttpService.create(
      groupId,
      missionId,
      task
    );

    task.tags = tasksHelper.mapTaskTags(rootState.tags.tags, task.tags);

    commit(ADD_MISSION_TASK, { id: result, ...task });

    const selectedFilterTypes = getSelectedFilterTypes(
      rootState.missions.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, result.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, result.type);
    }
  },
  async [UPDATE_GROUP_MISSION_TASK]({ commit, rootState }, payload) {
    const { groupId, missionId, task } = payload;

    await groupMissionTasksHttpService.update(
      groupId,
      missionId,
      task.id,
      tasksMapper.transformToGroupMissionTaskReqBody(task)
    );

    task.tags = tasksHelper.mapTaskTags(rootState.tags.tags, task.tags);

    const selectedFilterTypes = getSelectedFilterTypes(
      rootState.missions.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, task.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, task.type);
    }

    commit(UPDATE_MISSION_TASK, task);
  },
  async [COMPLETE_GROUP_MISSION_TASK]({ commit }, payload) {
    const { groupId, missionId, task } = payload;

    const { result } = await groupMissionTasksHttpService.complete(
      groupId,
      missionId,
      task.id
    );

    commit(UPDATE_MISSION_TASK, {
      id: task.id,
      completed: true
    });
    commit(UPDATE_PROFILE, { board: result.board });

    if (result.stats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.stats);
    }

    return result;
  },
  async [REOPEN_GROUP_MISSION_TASK]({ commit }, payload) {
    const { groupId, missionId, taskId } = payload;

    const { result } = await groupMissionTasksHttpService.reopen(
      groupId,
      missionId,
      taskId
    );

    commit(UPDATE_MISSION_TASK, { id: taskId, completed: false });

    if (result.stats) {
      commit(UPDATE_CURRENT_GROUP_MEMBER_STATS, result.stats);
    }

    return result;
  },
  async [ARCHIVE_GROUP_MISSION_TASK]({ commit }, payload) {
    const { groupId, userId, missionId, taskId } = payload;

    await groupMissionTasksHttpService.archive(
      groupId,
      userId,
      missionId,
      taskId
    );
    commit(UPDATE_GROUP_MISSION_TASK, { id: taskId, archived: true });
  },
  async [COPY_GROUP_MISSION_TASK]({ commit, rootState }, payload) {
    const { groupId, userId, missionId, taskId } = payload;

    const { result } = await groupMissionTasksHttpService.copy(
      groupId,
      userId,
      missionId,
      taskId
    );

    const selectedFilterTypes = getSelectedFilterTypes(
      rootState.missions.missionTasksTypesFilter
    );

    if (isFilterTypeNotSelected(selectedFilterTypes, result.type)) {
      commit(UPDATE_MISSION_TASKS_TYPES_FILTER, result.type);
    }

    commit(ADD_GROUP_MISSION_TASK, result);

    return result;
  },
  async [DELETE_GROUP_MISSION_TASK]({ commit }, payload) {
    const { groupId, missionId, taskId } = payload;

    await groupMissionTasksHttpService.del(groupId, missionId, taskId);

    commit(DELETE_MISSION_TASK, taskId);
  }
};
